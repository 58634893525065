// DO NOT EDIT THIS FILE DIRECTLY. Autogenerated by "yarn ctful:codegen"
import { GraphQLClient } from 'graphql-request'
import { print } from 'graphql'
import { DocumentNode } from 'graphql'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z,
   *     compliant with the 'date-time' format outlined in section 5.6 of
   *     the RFC 3339 profile of the ISO 8601 standard for representation
   *     of dates and times using the Gregorian calendar.
   */
  DateTime: any
  /** The 'Dimension' type represents dimensions as whole numeric values between `1` and `4000`. */
  Dimension: any
  /** The 'HexColor' type represents color in `rgb:ffffff` string format. */
  HexColor: any
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any
  /** The 'Quality' type represents quality as whole numeric values between `1` and `100`. */
  Quality: any
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/aboutUsPage) */
export type AboutUsPage = Entry & {
  __typename?: 'AboutUsPage'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<AboutUsPageLinkingCollections>
  id?: Maybe<Scalars['String']>
  teamMembersCollection?: Maybe<AboutUsPageTeamMembersCollection>
  investorsPeopleCollection?: Maybe<AboutUsPageInvestorsPeopleCollection>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/aboutUsPage) */
export type AboutUsPageLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/aboutUsPage) */
export type AboutUsPageIdArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/aboutUsPage) */
export type AboutUsPageTeamMembersCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<PersonFilter>
  order?: Maybe<Array<Maybe<AboutUsPageTeamMembersCollectionOrder>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/aboutUsPage) */
export type AboutUsPageInvestorsPeopleCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<PersonFilter>
  order?: Maybe<Array<Maybe<AboutUsPageInvestorsPeopleCollectionOrder>>>
}

export type AboutUsPageCollection = {
  __typename?: 'AboutUsPageCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<AboutUsPage>>
}

export type AboutUsPageFilter = {
  teamMembers?: Maybe<CfPersonNestedFilter>
  investorsPeople?: Maybe<CfPersonNestedFilter>
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  id_exists?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['String']>
  id_not?: Maybe<Scalars['String']>
  id_in?: Maybe<Array<Maybe<Scalars['String']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  id_contains?: Maybe<Scalars['String']>
  id_not_contains?: Maybe<Scalars['String']>
  teamMembersCollection_exists?: Maybe<Scalars['Boolean']>
  investorsPeopleCollection_exists?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<AboutUsPageFilter>>>
  AND?: Maybe<Array<Maybe<AboutUsPageFilter>>>
}

export type AboutUsPageInvestorsPeopleCollection = {
  __typename?: 'AboutUsPageInvestorsPeopleCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<Person>>
}

export enum AboutUsPageInvestorsPeopleCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type AboutUsPageLinkingCollections = {
  __typename?: 'AboutUsPageLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type AboutUsPageLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export enum AboutUsPageOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type AboutUsPageTeamMembersCollection = {
  __typename?: 'AboutUsPageTeamMembersCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<Person>>
}

export enum AboutUsPageTeamMembersCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/actionCard) */
export type ActionCard = Entry & {
  __typename?: 'ActionCard'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<ActionCardLinkingCollections>
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  buttonText?: Maybe<Scalars['String']>
  cornerstone?: Maybe<Scalars['String']>
  icon?: Maybe<Scalars['String']>
  customIcon?: Maybe<Asset>
  tag?: Maybe<Scalars['String']>
  duration?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/actionCard) */
export type ActionCardLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/actionCard) */
export type ActionCardTitleArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/actionCard) */
export type ActionCardDescriptionArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/actionCard) */
export type ActionCardButtonTextArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/actionCard) */
export type ActionCardCornerstoneArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/actionCard) */
export type ActionCardIconArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/actionCard) */
export type ActionCardCustomIconArgs = {
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/actionCard) */
export type ActionCardTagArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/actionCard) */
export type ActionCardDurationArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/actionCard) */
export type ActionCardUrlArgs = {
  locale?: Maybe<Scalars['String']>
}

export type ActionCardCollection = {
  __typename?: 'ActionCardCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<ActionCard>>
}

export type ActionCardFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  title_exists?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  title_not?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_contains?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  description_exists?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  description_not?: Maybe<Scalars['String']>
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  buttonText_exists?: Maybe<Scalars['Boolean']>
  buttonText?: Maybe<Scalars['String']>
  buttonText_not?: Maybe<Scalars['String']>
  buttonText_in?: Maybe<Array<Maybe<Scalars['String']>>>
  buttonText_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  buttonText_contains?: Maybe<Scalars['String']>
  buttonText_not_contains?: Maybe<Scalars['String']>
  cornerstone_exists?: Maybe<Scalars['Boolean']>
  cornerstone?: Maybe<Scalars['String']>
  cornerstone_not?: Maybe<Scalars['String']>
  cornerstone_in?: Maybe<Array<Maybe<Scalars['String']>>>
  cornerstone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  cornerstone_contains?: Maybe<Scalars['String']>
  cornerstone_not_contains?: Maybe<Scalars['String']>
  icon_exists?: Maybe<Scalars['Boolean']>
  icon?: Maybe<Scalars['String']>
  icon_not?: Maybe<Scalars['String']>
  icon_in?: Maybe<Array<Maybe<Scalars['String']>>>
  icon_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  icon_contains?: Maybe<Scalars['String']>
  icon_not_contains?: Maybe<Scalars['String']>
  customIcon_exists?: Maybe<Scalars['Boolean']>
  tag_exists?: Maybe<Scalars['Boolean']>
  tag?: Maybe<Scalars['String']>
  tag_not?: Maybe<Scalars['String']>
  tag_in?: Maybe<Array<Maybe<Scalars['String']>>>
  tag_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  tag_contains?: Maybe<Scalars['String']>
  tag_not_contains?: Maybe<Scalars['String']>
  duration_exists?: Maybe<Scalars['Boolean']>
  duration?: Maybe<Scalars['String']>
  duration_not?: Maybe<Scalars['String']>
  duration_in?: Maybe<Array<Maybe<Scalars['String']>>>
  duration_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  duration_contains?: Maybe<Scalars['String']>
  duration_not_contains?: Maybe<Scalars['String']>
  url_exists?: Maybe<Scalars['Boolean']>
  url?: Maybe<Scalars['String']>
  url_not?: Maybe<Scalars['String']>
  url_in?: Maybe<Array<Maybe<Scalars['String']>>>
  url_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  url_contains?: Maybe<Scalars['String']>
  url_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<ActionCardFilter>>>
  AND?: Maybe<Array<Maybe<ActionCardFilter>>>
}

export type ActionCardLinkingCollections = {
  __typename?: 'ActionCardLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type ActionCardLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export enum ActionCardOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  CornerstoneAsc = 'cornerstone_ASC',
  CornerstoneDesc = 'cornerstone_DESC',
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  TagAsc = 'tag_ASC',
  TagDesc = 'tag_DESC',
  DurationAsc = 'duration_ASC',
  DurationDesc = 'duration_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/animatedPhoto) */
export type AnimatedPhoto = Entry & {
  __typename?: 'AnimatedPhoto'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<AnimatedPhotoLinkingCollections>
  photo?: Maybe<Asset>
  video?: Maybe<Asset>
  name?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/animatedPhoto) */
export type AnimatedPhotoLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/animatedPhoto) */
export type AnimatedPhotoPhotoArgs = {
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/animatedPhoto) */
export type AnimatedPhotoVideoArgs = {
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/animatedPhoto) */
export type AnimatedPhotoNameArgs = {
  locale?: Maybe<Scalars['String']>
}

export type AnimatedPhotoCollection = {
  __typename?: 'AnimatedPhotoCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<AnimatedPhoto>>
}

export type AnimatedPhotoFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  photo_exists?: Maybe<Scalars['Boolean']>
  video_exists?: Maybe<Scalars['Boolean']>
  name_exists?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<AnimatedPhotoFilter>>>
  AND?: Maybe<Array<Maybe<AnimatedPhotoFilter>>>
}

export type AnimatedPhotoLinkingCollections = {
  __typename?: 'AnimatedPhotoLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  memorySetupCollection?: Maybe<MemorySetupCollection>
}

export type AnimatedPhotoLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type AnimatedPhotoLinkingCollectionsMemorySetupCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  order?: Maybe<Array<Maybe<AnimatedPhotoLinkingCollectionsMemorySetupCollectionOrder>>>
}

export enum AnimatedPhotoLinkingCollectionsMemorySetupCollectionOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum AnimatedPhotoOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/answeredQuestion) */
export type AnsweredQuestion = Entry & {
  __typename?: 'AnsweredQuestion'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<AnsweredQuestionLinkingCollections>
  question?: Maybe<Scalars['String']>
  answer?: Maybe<AnsweredQuestionAnswer>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/answeredQuestion) */
export type AnsweredQuestionLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/answeredQuestion) */
export type AnsweredQuestionQuestionArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/answeredQuestion) */
export type AnsweredQuestionAnswerArgs = {
  locale?: Maybe<Scalars['String']>
}

export type AnsweredQuestionAnswer = {
  __typename?: 'AnsweredQuestionAnswer'
  json: Scalars['JSON']
  links: AnsweredQuestionAnswerLinks
}

export type AnsweredQuestionAnswerAssets = {
  __typename?: 'AnsweredQuestionAnswerAssets'
  hyperlink: Array<Maybe<Asset>>
  block: Array<Maybe<Asset>>
}

export type AnsweredQuestionAnswerEntries = {
  __typename?: 'AnsweredQuestionAnswerEntries'
  inline: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  block: Array<Maybe<Entry>>
}

export type AnsweredQuestionAnswerLinks = {
  __typename?: 'AnsweredQuestionAnswerLinks'
  entries: AnsweredQuestionAnswerEntries
  assets: AnsweredQuestionAnswerAssets
  resources: AnsweredQuestionAnswerResources
}

export type AnsweredQuestionAnswerResources = {
  __typename?: 'AnsweredQuestionAnswerResources'
  block: Array<AnsweredQuestionAnswerResourcesBlock>
  inline: Array<AnsweredQuestionAnswerResourcesInline>
  hyperlink: Array<AnsweredQuestionAnswerResourcesHyperlink>
}

export type AnsweredQuestionAnswerResourcesBlock = ResourceLink & {
  __typename?: 'AnsweredQuestionAnswerResourcesBlock'
  sys: ResourceSys
}

export type AnsweredQuestionAnswerResourcesHyperlink = ResourceLink & {
  __typename?: 'AnsweredQuestionAnswerResourcesHyperlink'
  sys: ResourceSys
}

export type AnsweredQuestionAnswerResourcesInline = ResourceLink & {
  __typename?: 'AnsweredQuestionAnswerResourcesInline'
  sys: ResourceSys
}

export type AnsweredQuestionCollection = {
  __typename?: 'AnsweredQuestionCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<AnsweredQuestion>>
}

export type AnsweredQuestionFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  question_exists?: Maybe<Scalars['Boolean']>
  question?: Maybe<Scalars['String']>
  question_not?: Maybe<Scalars['String']>
  question_in?: Maybe<Array<Maybe<Scalars['String']>>>
  question_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  question_contains?: Maybe<Scalars['String']>
  question_not_contains?: Maybe<Scalars['String']>
  answer_exists?: Maybe<Scalars['Boolean']>
  answer_contains?: Maybe<Scalars['String']>
  answer_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<AnsweredQuestionFilter>>>
  AND?: Maybe<Array<Maybe<AnsweredQuestionFilter>>>
}

export type AnsweredQuestionLinkingCollections = {
  __typename?: 'AnsweredQuestionLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pageFaqCollection?: Maybe<PageFaqCollection>
}

export type AnsweredQuestionLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type AnsweredQuestionLinkingCollectionsPageFaqCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  order?: Maybe<Array<Maybe<AnsweredQuestionLinkingCollectionsPageFaqCollectionOrder>>>
}

export enum AnsweredQuestionLinkingCollectionsPageFaqCollectionOrder {
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum AnsweredQuestionOrder {
  QuestionAsc = 'question_ASC',
  QuestionDesc = 'question_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/apiDocumentation) */
export type ApiDocumentation = Entry & {
  __typename?: 'ApiDocumentation'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<ApiDocumentationLinkingCollections>
  overview?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/apiDocumentation) */
export type ApiDocumentationLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/apiDocumentation) */
export type ApiDocumentationOverviewArgs = {
  locale?: Maybe<Scalars['String']>
}

export type ApiDocumentationCollection = {
  __typename?: 'ApiDocumentationCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<ApiDocumentation>>
}

export type ApiDocumentationFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  overview_exists?: Maybe<Scalars['Boolean']>
  overview?: Maybe<Scalars['String']>
  overview_not?: Maybe<Scalars['String']>
  overview_in?: Maybe<Array<Maybe<Scalars['String']>>>
  overview_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  overview_contains?: Maybe<Scalars['String']>
  overview_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<ApiDocumentationFilter>>>
  AND?: Maybe<Array<Maybe<ApiDocumentationFilter>>>
}

export type ApiDocumentationLinkingCollections = {
  __typename?: 'ApiDocumentationLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type ApiDocumentationLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export enum ApiDocumentationOrder {
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/article) */
export type Article = Entry & {
  __typename?: 'Article'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<ArticleLinkingCollections>
  title?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  featured?: Maybe<Scalars['Boolean']>
  coverImage?: Maybe<Asset>
  content?: Maybe<ArticleContent>
  siteId?: Maybe<Scalars['Int']>
  category?: Maybe<Category>
  categoriesCollection?: Maybe<ArticleCategoriesCollection>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/article) */
export type ArticleLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/article) */
export type ArticleTitleArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/article) */
export type ArticleSlugArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/article) */
export type ArticleDescriptionArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/article) */
export type ArticleFeaturedArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/article) */
export type ArticleCoverImageArgs = {
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/article) */
export type ArticleContentArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/article) */
export type ArticleSiteIdArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/article) */
export type ArticleCategoryArgs = {
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<CategoryFilter>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/article) */
export type ArticleCategoriesCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<CategoryFilter>
  order?: Maybe<Array<Maybe<ArticleCategoriesCollectionOrder>>>
}

export type ArticleCategoriesCollection = {
  __typename?: 'ArticleCategoriesCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<Category>>
}

export enum ArticleCategoriesCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ArticleCollection = {
  __typename?: 'ArticleCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<Article>>
}

export type ArticleContent = {
  __typename?: 'ArticleContent'
  json: Scalars['JSON']
  links: ArticleContentLinks
}

export type ArticleContentAssets = {
  __typename?: 'ArticleContentAssets'
  hyperlink: Array<Maybe<Asset>>
  block: Array<Maybe<Asset>>
}

export type ArticleContentEntries = {
  __typename?: 'ArticleContentEntries'
  inline: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  block: Array<Maybe<Entry>>
}

export type ArticleContentLinks = {
  __typename?: 'ArticleContentLinks'
  entries: ArticleContentEntries
  assets: ArticleContentAssets
  resources: ArticleContentResources
}

export type ArticleContentResources = {
  __typename?: 'ArticleContentResources'
  block: Array<ArticleContentResourcesBlock>
  inline: Array<ArticleContentResourcesInline>
  hyperlink: Array<ArticleContentResourcesHyperlink>
}

export type ArticleContentResourcesBlock = ResourceLink & {
  __typename?: 'ArticleContentResourcesBlock'
  sys: ResourceSys
}

export type ArticleContentResourcesHyperlink = ResourceLink & {
  __typename?: 'ArticleContentResourcesHyperlink'
  sys: ResourceSys
}

export type ArticleContentResourcesInline = ResourceLink & {
  __typename?: 'ArticleContentResourcesInline'
  sys: ResourceSys
}

export type ArticleFilter = {
  category?: Maybe<CfCategoryNestedFilter>
  categories?: Maybe<CfCategoryNestedFilter>
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  title_exists?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  title_not?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_contains?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  slug_exists?: Maybe<Scalars['Boolean']>
  slug?: Maybe<Scalars['String']>
  slug_not?: Maybe<Scalars['String']>
  slug_in?: Maybe<Array<Maybe<Scalars['String']>>>
  slug_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  slug_contains?: Maybe<Scalars['String']>
  slug_not_contains?: Maybe<Scalars['String']>
  description_exists?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  description_not?: Maybe<Scalars['String']>
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  featured_exists?: Maybe<Scalars['Boolean']>
  featured?: Maybe<Scalars['Boolean']>
  featured_not?: Maybe<Scalars['Boolean']>
  coverImage_exists?: Maybe<Scalars['Boolean']>
  content_exists?: Maybe<Scalars['Boolean']>
  content_contains?: Maybe<Scalars['String']>
  content_not_contains?: Maybe<Scalars['String']>
  siteId_exists?: Maybe<Scalars['Boolean']>
  siteId?: Maybe<Scalars['Int']>
  siteId_not?: Maybe<Scalars['Int']>
  siteId_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  siteId_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  siteId_gt?: Maybe<Scalars['Int']>
  siteId_gte?: Maybe<Scalars['Int']>
  siteId_lt?: Maybe<Scalars['Int']>
  siteId_lte?: Maybe<Scalars['Int']>
  category_exists?: Maybe<Scalars['Boolean']>
  categoriesCollection_exists?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<ArticleFilter>>>
  AND?: Maybe<Array<Maybe<ArticleFilter>>>
}

export type ArticleLinkingCollections = {
  __typename?: 'ArticleLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pageArticlesCollection?: Maybe<PageArticlesCollection>
  pageArticleCollection?: Maybe<PageArticleCollection>
  articleMenuItemCollection?: Maybe<ArticleMenuItemCollection>
}

export type ArticleLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type ArticleLinkingCollectionsPageArticlesCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  order?: Maybe<Array<Maybe<ArticleLinkingCollectionsPageArticlesCollectionOrder>>>
}

export type ArticleLinkingCollectionsPageArticleCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  order?: Maybe<Array<Maybe<ArticleLinkingCollectionsPageArticleCollectionOrder>>>
}

export type ArticleLinkingCollectionsArticleMenuItemCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  order?: Maybe<Array<Maybe<ArticleLinkingCollectionsArticleMenuItemCollectionOrder>>>
}

export enum ArticleLinkingCollectionsArticleMenuItemCollectionOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  CustomLinkAsc = 'customLink_ASC',
  CustomLinkDesc = 'customLink_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ArticleLinkingCollectionsPageArticleCollectionOrder {
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ArticleLinkingCollectionsPageArticlesCollectionOrder {
  PageAsc = 'page_ASC',
  PageDesc = 'page_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/articleMenuItem) */
export type ArticleMenuItem = Entry & {
  __typename?: 'ArticleMenuItem'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<ArticleMenuItemLinkingCollections>
  title?: Maybe<Scalars['String']>
  customLink?: Maybe<Scalars['String']>
  article?: Maybe<Article>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/articleMenuItem) */
export type ArticleMenuItemLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/articleMenuItem) */
export type ArticleMenuItemTitleArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/articleMenuItem) */
export type ArticleMenuItemCustomLinkArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/articleMenuItem) */
export type ArticleMenuItemArticleArgs = {
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<ArticleFilter>
}

export type ArticleMenuItemCollection = {
  __typename?: 'ArticleMenuItemCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<ArticleMenuItem>>
}

export type ArticleMenuItemFilter = {
  article?: Maybe<CfArticleNestedFilter>
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  title_exists?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  title_not?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_contains?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  customLink_exists?: Maybe<Scalars['Boolean']>
  customLink?: Maybe<Scalars['String']>
  customLink_not?: Maybe<Scalars['String']>
  customLink_in?: Maybe<Array<Maybe<Scalars['String']>>>
  customLink_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  customLink_contains?: Maybe<Scalars['String']>
  customLink_not_contains?: Maybe<Scalars['String']>
  article_exists?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<ArticleMenuItemFilter>>>
  AND?: Maybe<Array<Maybe<ArticleMenuItemFilter>>>
}

export type ArticleMenuItemLinkingCollections = {
  __typename?: 'ArticleMenuItemLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  articleMenuNodeCollection?: Maybe<ArticleMenuNodeCollection>
}

export type ArticleMenuItemLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type ArticleMenuItemLinkingCollectionsArticleMenuNodeCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  order?: Maybe<Array<Maybe<ArticleMenuItemLinkingCollectionsArticleMenuNodeCollectionOrder>>>
}

export enum ArticleMenuItemLinkingCollectionsArticleMenuNodeCollectionOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ArticleMenuItemOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  CustomLinkAsc = 'customLink_ASC',
  CustomLinkDesc = 'customLink_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/articleMenuNode) */
export type ArticleMenuNode = Entry & {
  __typename?: 'ArticleMenuNode'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<ArticleMenuNodeLinkingCollections>
  title?: Maybe<Scalars['String']>
  nodesCollection?: Maybe<ArticleMenuNodeNodesCollection>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/articleMenuNode) */
export type ArticleMenuNodeLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/articleMenuNode) */
export type ArticleMenuNodeTitleArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/articleMenuNode) */
export type ArticleMenuNodeNodesCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<ArticleMenuItemFilter>
  order?: Maybe<Array<Maybe<ArticleMenuNodeNodesCollectionOrder>>>
}

export type ArticleMenuNodeCollection = {
  __typename?: 'ArticleMenuNodeCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<ArticleMenuNode>>
}

export type ArticleMenuNodeFilter = {
  nodes?: Maybe<CfArticleMenuItemNestedFilter>
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  title_exists?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  title_not?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_contains?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  nodesCollection_exists?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<ArticleMenuNodeFilter>>>
  AND?: Maybe<Array<Maybe<ArticleMenuNodeFilter>>>
}

export type ArticleMenuNodeLinkingCollections = {
  __typename?: 'ArticleMenuNodeLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  articlesSetupCollection?: Maybe<ArticlesSetupCollection>
}

export type ArticleMenuNodeLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type ArticleMenuNodeLinkingCollectionsArticlesSetupCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  order?: Maybe<Array<Maybe<ArticleMenuNodeLinkingCollectionsArticlesSetupCollectionOrder>>>
}

export enum ArticleMenuNodeLinkingCollectionsArticlesSetupCollectionOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ArticleMenuNodeNodesCollection = {
  __typename?: 'ArticleMenuNodeNodesCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<ArticleMenuItem>>
}

export enum ArticleMenuNodeNodesCollectionOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  CustomLinkAsc = 'customLink_ASC',
  CustomLinkDesc = 'customLink_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ArticleMenuNodeOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ArticleOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  FeaturedAsc = 'featured_ASC',
  FeaturedDesc = 'featured_DESC',
  SiteIdAsc = 'siteId_ASC',
  SiteIdDesc = 'siteId_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/articlesSetup) */
export type ArticlesSetup = Entry & {
  __typename?: 'ArticlesSetup'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<ArticlesSetupLinkingCollections>
  title?: Maybe<Scalars['String']>
  endOfTheLivePlanningMenuCollection?: Maybe<ArticlesSetupEndOfTheLivePlanningMenuCollection>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/articlesSetup) */
export type ArticlesSetupLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/articlesSetup) */
export type ArticlesSetupTitleArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/articlesSetup) */
export type ArticlesSetupEndOfTheLivePlanningMenuCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<ArticleMenuNodeFilter>
  order?: Maybe<Array<Maybe<ArticlesSetupEndOfTheLivePlanningMenuCollectionOrder>>>
}

export type ArticlesSetupCollection = {
  __typename?: 'ArticlesSetupCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<ArticlesSetup>>
}

export type ArticlesSetupEndOfTheLivePlanningMenuCollection = {
  __typename?: 'ArticlesSetupEndOfTheLivePlanningMenuCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<ArticleMenuNode>>
}

export enum ArticlesSetupEndOfTheLivePlanningMenuCollectionOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ArticlesSetupFilter = {
  endOfTheLivePlanningMenu?: Maybe<CfArticleMenuNodeNestedFilter>
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  title_exists?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  title_not?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_contains?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  endOfTheLivePlanningMenuCollection_exists?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<ArticlesSetupFilter>>>
  AND?: Maybe<Array<Maybe<ArticlesSetupFilter>>>
}

export type ArticlesSetupLinkingCollections = {
  __typename?: 'ArticlesSetupLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type ArticlesSetupLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export enum ArticlesSetupOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Represents a binary file in a space. An asset can be any file type. */
export type Asset = {
  __typename?: 'Asset'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  contentType?: Maybe<Scalars['String']>
  fileName?: Maybe<Scalars['String']>
  size?: Maybe<Scalars['Int']>
  url?: Maybe<Scalars['String']>
  width?: Maybe<Scalars['Int']>
  height?: Maybe<Scalars['Int']>
  linkedFrom?: Maybe<AssetLinkingCollections>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetTitleArgs = {
  locale?: Maybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetDescriptionArgs = {
  locale?: Maybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetContentTypeArgs = {
  locale?: Maybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetFileNameArgs = {
  locale?: Maybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetSizeArgs = {
  locale?: Maybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetUrlArgs = {
  transform?: Maybe<ImageTransformOptions>
  locale?: Maybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetWidthArgs = {
  locale?: Maybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetHeightArgs = {
  locale?: Maybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type AssetCollection = {
  __typename?: 'AssetCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<Asset>>
}

export type AssetFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  title_exists?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  title_not?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_contains?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  description_exists?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  description_not?: Maybe<Scalars['String']>
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  url_exists?: Maybe<Scalars['Boolean']>
  url?: Maybe<Scalars['String']>
  url_not?: Maybe<Scalars['String']>
  url_in?: Maybe<Array<Maybe<Scalars['String']>>>
  url_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  url_contains?: Maybe<Scalars['String']>
  url_not_contains?: Maybe<Scalars['String']>
  size_exists?: Maybe<Scalars['Boolean']>
  size?: Maybe<Scalars['Int']>
  size_not?: Maybe<Scalars['Int']>
  size_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  size_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  size_gt?: Maybe<Scalars['Int']>
  size_gte?: Maybe<Scalars['Int']>
  size_lt?: Maybe<Scalars['Int']>
  size_lte?: Maybe<Scalars['Int']>
  contentType_exists?: Maybe<Scalars['Boolean']>
  contentType?: Maybe<Scalars['String']>
  contentType_not?: Maybe<Scalars['String']>
  contentType_in?: Maybe<Array<Maybe<Scalars['String']>>>
  contentType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  contentType_contains?: Maybe<Scalars['String']>
  contentType_not_contains?: Maybe<Scalars['String']>
  fileName_exists?: Maybe<Scalars['Boolean']>
  fileName?: Maybe<Scalars['String']>
  fileName_not?: Maybe<Scalars['String']>
  fileName_in?: Maybe<Array<Maybe<Scalars['String']>>>
  fileName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  fileName_contains?: Maybe<Scalars['String']>
  fileName_not_contains?: Maybe<Scalars['String']>
  width_exists?: Maybe<Scalars['Boolean']>
  width?: Maybe<Scalars['Int']>
  width_not?: Maybe<Scalars['Int']>
  width_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  width_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  width_gt?: Maybe<Scalars['Int']>
  width_gte?: Maybe<Scalars['Int']>
  width_lt?: Maybe<Scalars['Int']>
  width_lte?: Maybe<Scalars['Int']>
  height_exists?: Maybe<Scalars['Boolean']>
  height?: Maybe<Scalars['Int']>
  height_not?: Maybe<Scalars['Int']>
  height_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  height_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  height_gt?: Maybe<Scalars['Int']>
  height_gte?: Maybe<Scalars['Int']>
  height_lt?: Maybe<Scalars['Int']>
  height_lte?: Maybe<Scalars['Int']>
  OR?: Maybe<Array<Maybe<AssetFilter>>>
  AND?: Maybe<Array<Maybe<AssetFilter>>>
}

export type AssetLinkingCollections = {
  __typename?: 'AssetLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pageFileCollection?: Maybe<PageFileCollection>
  memorySetupCollection?: Maybe<MemorySetupCollection>
  tipCollection?: Maybe<TipCollection>
  storyPortraitCategoryCollection?: Maybe<StoryPortraitCategoryCollection>
  storyPortraitPhraseCollection?: Maybe<StoryPortraitPhraseCollection>
  pageImageCollection?: Maybe<PageImageCollection>
  helpCarouselConfigCollection?: Maybe<HelpCarouselConfigCollection>
  imageWithBulletsSectionCollection?: Maybe<ImageWithBulletsSectionCollection>
  mentionItemCollection?: Maybe<MentionItemCollection>
  actionCardCollection?: Maybe<ActionCardCollection>
  landingPageSectionCollection?: Maybe<LandingPageSectionCollection>
  steppedTutorialCollection?: Maybe<SteppedTutorialCollection>
  articleCollection?: Maybe<ArticleCollection>
  partnerLandingCollection?: Maybe<PartnerLandingCollection>
  digitalLegacyAuthorCollection?: Maybe<DigitalLegacyAuthorCollection>
  todoCollection?: Maybe<TodoCollection>
  animatedPhotoCollection?: Maybe<AnimatedPhotoCollection>
  futureMessageCollection?: Maybe<FutureMessageCollection>
  singingPortraitCollection?: Maybe<SingingPortraitCollection>
  singingPortraitSongCollection?: Maybe<SingingPortraitSongCollection>
  personCollection?: Maybe<PersonCollection>
}

export type AssetLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type AssetLinkingCollectionsPageFileCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type AssetLinkingCollectionsMemorySetupCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type AssetLinkingCollectionsTipCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type AssetLinkingCollectionsStoryPortraitCategoryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type AssetLinkingCollectionsStoryPortraitPhraseCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type AssetLinkingCollectionsPageImageCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type AssetLinkingCollectionsHelpCarouselConfigCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type AssetLinkingCollectionsImageWithBulletsSectionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type AssetLinkingCollectionsMentionItemCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type AssetLinkingCollectionsActionCardCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type AssetLinkingCollectionsLandingPageSectionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type AssetLinkingCollectionsSteppedTutorialCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type AssetLinkingCollectionsArticleCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type AssetLinkingCollectionsPartnerLandingCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type AssetLinkingCollectionsDigitalLegacyAuthorCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type AssetLinkingCollectionsTodoCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type AssetLinkingCollectionsAnimatedPhotoCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type AssetLinkingCollectionsFutureMessageCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type AssetLinkingCollectionsSingingPortraitCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type AssetLinkingCollectionsSingingPortraitSongCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type AssetLinkingCollectionsPersonCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export enum AssetOrder {
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  ContentTypeAsc = 'contentType_ASC',
  ContentTypeDesc = 'contentType_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/category) */
export type Category = Entry & {
  __typename?: 'Category'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<CategoryLinkingCollections>
  name?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/category) */
export type CategoryLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/category) */
export type CategoryNameArgs = {
  locale?: Maybe<Scalars['String']>
}

export type CategoryCollection = {
  __typename?: 'CategoryCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<Category>>
}

export type CategoryFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  name_exists?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<CategoryFilter>>>
  AND?: Maybe<Array<Maybe<CategoryFilter>>>
}

export type CategoryLinkingCollections = {
  __typename?: 'CategoryLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  articleCollection?: Maybe<ArticleCollection>
}

export type CategoryLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type CategoryLinkingCollectionsArticleCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  order?: Maybe<Array<Maybe<CategoryLinkingCollectionsArticleCollectionOrder>>>
}

export enum CategoryLinkingCollectionsArticleCollectionOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  FeaturedAsc = 'featured_ASC',
  FeaturedDesc = 'featured_DESC',
  SiteIdAsc = 'siteId_ASC',
  SiteIdDesc = 'siteId_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CategoryOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ContentfulMetadata = {
  __typename?: 'ContentfulMetadata'
  tags: Array<Maybe<ContentfulTag>>
}

export type ContentfulMetadataFilter = {
  tags_exists?: Maybe<Scalars['Boolean']>
  tags?: Maybe<ContentfulMetadataTagsFilter>
}

export type ContentfulMetadataTagsFilter = {
  id_contains_all?: Maybe<Array<Maybe<Scalars['String']>>>
  id_contains_some?: Maybe<Array<Maybe<Scalars['String']>>>
  id_contains_none?: Maybe<Array<Maybe<Scalars['String']>>>
}

/**
 * Represents a tag entity for finding and organizing content easily.
 *     Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-tags
 */
export type ContentfulTag = {
  __typename?: 'ContentfulTag'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacy) */
export type DigitalLegacy = Entry & {
  __typename?: 'DigitalLegacy'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<DigitalLegacyLinkingCollections>
  headingTitle?: Maybe<Scalars['String']>
  headingDescription?: Maybe<Scalars['String']>
  purchaseUrl?: Maybe<Scalars['String']>
  viewReviewUrl?: Maybe<Scalars['String']>
  aboutTitle?: Maybe<Scalars['String']>
  topicListCollection?: Maybe<DigitalLegacyTopicListCollection>
  authorTitle?: Maybe<Scalars['String']>
  aboutDescription?: Maybe<Scalars['String']>
  authorListCollection?: Maybe<DigitalLegacyAuthorListCollection>
  reviewTitle?: Maybe<Scalars['String']>
  reviewListCollection?: Maybe<DigitalLegacyReviewListCollection>
  facebookUrl?: Maybe<Scalars['String']>
  twitterUrl?: Maybe<Scalars['String']>
  linkedinUrl?: Maybe<Scalars['String']>
  instagramUrl?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacy) */
export type DigitalLegacyLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacy) */
export type DigitalLegacyHeadingTitleArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacy) */
export type DigitalLegacyHeadingDescriptionArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacy) */
export type DigitalLegacyPurchaseUrlArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacy) */
export type DigitalLegacyViewReviewUrlArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacy) */
export type DigitalLegacyAboutTitleArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacy) */
export type DigitalLegacyTopicListCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<DigitalLegacyTopicFilter>
  order?: Maybe<Array<Maybe<DigitalLegacyTopicListCollectionOrder>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacy) */
export type DigitalLegacyAuthorTitleArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacy) */
export type DigitalLegacyAboutDescriptionArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacy) */
export type DigitalLegacyAuthorListCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<DigitalLegacyAuthorFilter>
  order?: Maybe<Array<Maybe<DigitalLegacyAuthorListCollectionOrder>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacy) */
export type DigitalLegacyReviewTitleArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacy) */
export type DigitalLegacyReviewListCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<DigitalLegacyAuthorFilter>
  order?: Maybe<Array<Maybe<DigitalLegacyReviewListCollectionOrder>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacy) */
export type DigitalLegacyFacebookUrlArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacy) */
export type DigitalLegacyTwitterUrlArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacy) */
export type DigitalLegacyLinkedinUrlArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacy) */
export type DigitalLegacyInstagramUrlArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacyAuthor) */
export type DigitalLegacyAuthor = Entry & {
  __typename?: 'DigitalLegacyAuthor'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<DigitalLegacyAuthorLinkingCollections>
  name?: Maybe<Scalars['String']>
  jobTitle?: Maybe<Scalars['String']>
  photo?: Maybe<Asset>
  facebookUrl?: Maybe<Scalars['String']>
  linkedinUrl?: Maybe<Scalars['String']>
  twitterUrl?: Maybe<Scalars['String']>
  instagramUrl?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacyAuthor) */
export type DigitalLegacyAuthorLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacyAuthor) */
export type DigitalLegacyAuthorNameArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacyAuthor) */
export type DigitalLegacyAuthorJobTitleArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacyAuthor) */
export type DigitalLegacyAuthorPhotoArgs = {
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacyAuthor) */
export type DigitalLegacyAuthorFacebookUrlArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacyAuthor) */
export type DigitalLegacyAuthorLinkedinUrlArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacyAuthor) */
export type DigitalLegacyAuthorTwitterUrlArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacyAuthor) */
export type DigitalLegacyAuthorInstagramUrlArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacyAuthor) */
export type DigitalLegacyAuthorDescriptionArgs = {
  locale?: Maybe<Scalars['String']>
}

export type DigitalLegacyAuthorCollection = {
  __typename?: 'DigitalLegacyAuthorCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<DigitalLegacyAuthor>>
}

export type DigitalLegacyAuthorFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  name_exists?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  jobTitle_exists?: Maybe<Scalars['Boolean']>
  jobTitle?: Maybe<Scalars['String']>
  jobTitle_not?: Maybe<Scalars['String']>
  jobTitle_in?: Maybe<Array<Maybe<Scalars['String']>>>
  jobTitle_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  jobTitle_contains?: Maybe<Scalars['String']>
  jobTitle_not_contains?: Maybe<Scalars['String']>
  photo_exists?: Maybe<Scalars['Boolean']>
  facebookUrl_exists?: Maybe<Scalars['Boolean']>
  facebookUrl?: Maybe<Scalars['String']>
  facebookUrl_not?: Maybe<Scalars['String']>
  facebookUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>
  facebookUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  facebookUrl_contains?: Maybe<Scalars['String']>
  facebookUrl_not_contains?: Maybe<Scalars['String']>
  linkedinUrl_exists?: Maybe<Scalars['Boolean']>
  linkedinUrl?: Maybe<Scalars['String']>
  linkedinUrl_not?: Maybe<Scalars['String']>
  linkedinUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>
  linkedinUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  linkedinUrl_contains?: Maybe<Scalars['String']>
  linkedinUrl_not_contains?: Maybe<Scalars['String']>
  twitterUrl_exists?: Maybe<Scalars['Boolean']>
  twitterUrl?: Maybe<Scalars['String']>
  twitterUrl_not?: Maybe<Scalars['String']>
  twitterUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>
  twitterUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  twitterUrl_contains?: Maybe<Scalars['String']>
  twitterUrl_not_contains?: Maybe<Scalars['String']>
  instagramUrl_exists?: Maybe<Scalars['Boolean']>
  instagramUrl?: Maybe<Scalars['String']>
  instagramUrl_not?: Maybe<Scalars['String']>
  instagramUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>
  instagramUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  instagramUrl_contains?: Maybe<Scalars['String']>
  instagramUrl_not_contains?: Maybe<Scalars['String']>
  description_exists?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  description_not?: Maybe<Scalars['String']>
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<DigitalLegacyAuthorFilter>>>
  AND?: Maybe<Array<Maybe<DigitalLegacyAuthorFilter>>>
}

export type DigitalLegacyAuthorLinkingCollections = {
  __typename?: 'DigitalLegacyAuthorLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  digitalLegacyCollection?: Maybe<DigitalLegacyCollection>
}

export type DigitalLegacyAuthorLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type DigitalLegacyAuthorLinkingCollectionsDigitalLegacyCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  order?: Maybe<Array<Maybe<DigitalLegacyAuthorLinkingCollectionsDigitalLegacyCollectionOrder>>>
}

export enum DigitalLegacyAuthorLinkingCollectionsDigitalLegacyCollectionOrder {
  HeadingTitleAsc = 'headingTitle_ASC',
  HeadingTitleDesc = 'headingTitle_DESC',
  PurchaseUrlAsc = 'purchaseUrl_ASC',
  PurchaseUrlDesc = 'purchaseUrl_DESC',
  ViewReviewUrlAsc = 'viewReviewUrl_ASC',
  ViewReviewUrlDesc = 'viewReviewUrl_DESC',
  AboutTitleAsc = 'aboutTitle_ASC',
  AboutTitleDesc = 'aboutTitle_DESC',
  AuthorTitleAsc = 'authorTitle_ASC',
  AuthorTitleDesc = 'authorTitle_DESC',
  ReviewTitleAsc = 'reviewTitle_ASC',
  ReviewTitleDesc = 'reviewTitle_DESC',
  FacebookUrlAsc = 'facebookUrl_ASC',
  FacebookUrlDesc = 'facebookUrl_DESC',
  TwitterUrlAsc = 'twitterUrl_ASC',
  TwitterUrlDesc = 'twitterUrl_DESC',
  LinkedinUrlAsc = 'linkedinUrl_ASC',
  LinkedinUrlDesc = 'linkedinUrl_DESC',
  InstagramUrlAsc = 'instagramUrl_ASC',
  InstagramUrlDesc = 'instagramUrl_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type DigitalLegacyAuthorListCollection = {
  __typename?: 'DigitalLegacyAuthorListCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<DigitalLegacyAuthor>>
}

export enum DigitalLegacyAuthorListCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  JobTitleAsc = 'jobTitle_ASC',
  JobTitleDesc = 'jobTitle_DESC',
  FacebookUrlAsc = 'facebookUrl_ASC',
  FacebookUrlDesc = 'facebookUrl_DESC',
  LinkedinUrlAsc = 'linkedinUrl_ASC',
  LinkedinUrlDesc = 'linkedinUrl_DESC',
  TwitterUrlAsc = 'twitterUrl_ASC',
  TwitterUrlDesc = 'twitterUrl_DESC',
  InstagramUrlAsc = 'instagramUrl_ASC',
  InstagramUrlDesc = 'instagramUrl_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum DigitalLegacyAuthorOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  JobTitleAsc = 'jobTitle_ASC',
  JobTitleDesc = 'jobTitle_DESC',
  FacebookUrlAsc = 'facebookUrl_ASC',
  FacebookUrlDesc = 'facebookUrl_DESC',
  LinkedinUrlAsc = 'linkedinUrl_ASC',
  LinkedinUrlDesc = 'linkedinUrl_DESC',
  TwitterUrlAsc = 'twitterUrl_ASC',
  TwitterUrlDesc = 'twitterUrl_DESC',
  InstagramUrlAsc = 'instagramUrl_ASC',
  InstagramUrlDesc = 'instagramUrl_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type DigitalLegacyCollection = {
  __typename?: 'DigitalLegacyCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<DigitalLegacy>>
}

export type DigitalLegacyFilter = {
  topicList?: Maybe<CfDigitalLegacyTopicNestedFilter>
  authorList?: Maybe<CfDigitalLegacyAuthorNestedFilter>
  reviewList?: Maybe<CfDigitalLegacyAuthorNestedFilter>
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  headingTitle_exists?: Maybe<Scalars['Boolean']>
  headingTitle?: Maybe<Scalars['String']>
  headingTitle_not?: Maybe<Scalars['String']>
  headingTitle_in?: Maybe<Array<Maybe<Scalars['String']>>>
  headingTitle_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  headingTitle_contains?: Maybe<Scalars['String']>
  headingTitle_not_contains?: Maybe<Scalars['String']>
  headingDescription_exists?: Maybe<Scalars['Boolean']>
  headingDescription?: Maybe<Scalars['String']>
  headingDescription_not?: Maybe<Scalars['String']>
  headingDescription_in?: Maybe<Array<Maybe<Scalars['String']>>>
  headingDescription_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  headingDescription_contains?: Maybe<Scalars['String']>
  headingDescription_not_contains?: Maybe<Scalars['String']>
  purchaseUrl_exists?: Maybe<Scalars['Boolean']>
  purchaseUrl?: Maybe<Scalars['String']>
  purchaseUrl_not?: Maybe<Scalars['String']>
  purchaseUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>
  purchaseUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  purchaseUrl_contains?: Maybe<Scalars['String']>
  purchaseUrl_not_contains?: Maybe<Scalars['String']>
  viewReviewUrl_exists?: Maybe<Scalars['Boolean']>
  viewReviewUrl?: Maybe<Scalars['String']>
  viewReviewUrl_not?: Maybe<Scalars['String']>
  viewReviewUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>
  viewReviewUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  viewReviewUrl_contains?: Maybe<Scalars['String']>
  viewReviewUrl_not_contains?: Maybe<Scalars['String']>
  aboutTitle_exists?: Maybe<Scalars['Boolean']>
  aboutTitle?: Maybe<Scalars['String']>
  aboutTitle_not?: Maybe<Scalars['String']>
  aboutTitle_in?: Maybe<Array<Maybe<Scalars['String']>>>
  aboutTitle_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  aboutTitle_contains?: Maybe<Scalars['String']>
  aboutTitle_not_contains?: Maybe<Scalars['String']>
  topicListCollection_exists?: Maybe<Scalars['Boolean']>
  authorTitle_exists?: Maybe<Scalars['Boolean']>
  authorTitle?: Maybe<Scalars['String']>
  authorTitle_not?: Maybe<Scalars['String']>
  authorTitle_in?: Maybe<Array<Maybe<Scalars['String']>>>
  authorTitle_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  authorTitle_contains?: Maybe<Scalars['String']>
  authorTitle_not_contains?: Maybe<Scalars['String']>
  aboutDescription_exists?: Maybe<Scalars['Boolean']>
  aboutDescription?: Maybe<Scalars['String']>
  aboutDescription_not?: Maybe<Scalars['String']>
  aboutDescription_in?: Maybe<Array<Maybe<Scalars['String']>>>
  aboutDescription_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  aboutDescription_contains?: Maybe<Scalars['String']>
  aboutDescription_not_contains?: Maybe<Scalars['String']>
  authorListCollection_exists?: Maybe<Scalars['Boolean']>
  reviewTitle_exists?: Maybe<Scalars['Boolean']>
  reviewTitle?: Maybe<Scalars['String']>
  reviewTitle_not?: Maybe<Scalars['String']>
  reviewTitle_in?: Maybe<Array<Maybe<Scalars['String']>>>
  reviewTitle_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  reviewTitle_contains?: Maybe<Scalars['String']>
  reviewTitle_not_contains?: Maybe<Scalars['String']>
  reviewListCollection_exists?: Maybe<Scalars['Boolean']>
  facebookUrl_exists?: Maybe<Scalars['Boolean']>
  facebookUrl?: Maybe<Scalars['String']>
  facebookUrl_not?: Maybe<Scalars['String']>
  facebookUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>
  facebookUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  facebookUrl_contains?: Maybe<Scalars['String']>
  facebookUrl_not_contains?: Maybe<Scalars['String']>
  twitterUrl_exists?: Maybe<Scalars['Boolean']>
  twitterUrl?: Maybe<Scalars['String']>
  twitterUrl_not?: Maybe<Scalars['String']>
  twitterUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>
  twitterUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  twitterUrl_contains?: Maybe<Scalars['String']>
  twitterUrl_not_contains?: Maybe<Scalars['String']>
  linkedinUrl_exists?: Maybe<Scalars['Boolean']>
  linkedinUrl?: Maybe<Scalars['String']>
  linkedinUrl_not?: Maybe<Scalars['String']>
  linkedinUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>
  linkedinUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  linkedinUrl_contains?: Maybe<Scalars['String']>
  linkedinUrl_not_contains?: Maybe<Scalars['String']>
  instagramUrl_exists?: Maybe<Scalars['Boolean']>
  instagramUrl?: Maybe<Scalars['String']>
  instagramUrl_not?: Maybe<Scalars['String']>
  instagramUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>
  instagramUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  instagramUrl_contains?: Maybe<Scalars['String']>
  instagramUrl_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<DigitalLegacyFilter>>>
  AND?: Maybe<Array<Maybe<DigitalLegacyFilter>>>
}

export type DigitalLegacyLinkingCollections = {
  __typename?: 'DigitalLegacyLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type DigitalLegacyLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export enum DigitalLegacyOrder {
  HeadingTitleAsc = 'headingTitle_ASC',
  HeadingTitleDesc = 'headingTitle_DESC',
  PurchaseUrlAsc = 'purchaseUrl_ASC',
  PurchaseUrlDesc = 'purchaseUrl_DESC',
  ViewReviewUrlAsc = 'viewReviewUrl_ASC',
  ViewReviewUrlDesc = 'viewReviewUrl_DESC',
  AboutTitleAsc = 'aboutTitle_ASC',
  AboutTitleDesc = 'aboutTitle_DESC',
  AuthorTitleAsc = 'authorTitle_ASC',
  AuthorTitleDesc = 'authorTitle_DESC',
  ReviewTitleAsc = 'reviewTitle_ASC',
  ReviewTitleDesc = 'reviewTitle_DESC',
  FacebookUrlAsc = 'facebookUrl_ASC',
  FacebookUrlDesc = 'facebookUrl_DESC',
  TwitterUrlAsc = 'twitterUrl_ASC',
  TwitterUrlDesc = 'twitterUrl_DESC',
  LinkedinUrlAsc = 'linkedinUrl_ASC',
  LinkedinUrlDesc = 'linkedinUrl_DESC',
  InstagramUrlAsc = 'instagramUrl_ASC',
  InstagramUrlDesc = 'instagramUrl_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type DigitalLegacyReviewListCollection = {
  __typename?: 'DigitalLegacyReviewListCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<DigitalLegacyAuthor>>
}

export enum DigitalLegacyReviewListCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  JobTitleAsc = 'jobTitle_ASC',
  JobTitleDesc = 'jobTitle_DESC',
  FacebookUrlAsc = 'facebookUrl_ASC',
  FacebookUrlDesc = 'facebookUrl_DESC',
  LinkedinUrlAsc = 'linkedinUrl_ASC',
  LinkedinUrlDesc = 'linkedinUrl_DESC',
  TwitterUrlAsc = 'twitterUrl_ASC',
  TwitterUrlDesc = 'twitterUrl_DESC',
  InstagramUrlAsc = 'instagramUrl_ASC',
  InstagramUrlDesc = 'instagramUrl_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacyTopic) */
export type DigitalLegacyTopic = Entry & {
  __typename?: 'DigitalLegacyTopic'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<DigitalLegacyTopicLinkingCollections>
  icon?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacyTopic) */
export type DigitalLegacyTopicLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacyTopic) */
export type DigitalLegacyTopicIconArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/digitalLegacyTopic) */
export type DigitalLegacyTopicContentArgs = {
  locale?: Maybe<Scalars['String']>
}

export type DigitalLegacyTopicCollection = {
  __typename?: 'DigitalLegacyTopicCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<DigitalLegacyTopic>>
}

export type DigitalLegacyTopicFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  icon_exists?: Maybe<Scalars['Boolean']>
  icon?: Maybe<Scalars['String']>
  icon_not?: Maybe<Scalars['String']>
  icon_in?: Maybe<Array<Maybe<Scalars['String']>>>
  icon_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  icon_contains?: Maybe<Scalars['String']>
  icon_not_contains?: Maybe<Scalars['String']>
  content_exists?: Maybe<Scalars['Boolean']>
  content?: Maybe<Scalars['String']>
  content_not?: Maybe<Scalars['String']>
  content_in?: Maybe<Array<Maybe<Scalars['String']>>>
  content_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  content_contains?: Maybe<Scalars['String']>
  content_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<DigitalLegacyTopicFilter>>>
  AND?: Maybe<Array<Maybe<DigitalLegacyTopicFilter>>>
}

export type DigitalLegacyTopicLinkingCollections = {
  __typename?: 'DigitalLegacyTopicLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  digitalLegacyCollection?: Maybe<DigitalLegacyCollection>
}

export type DigitalLegacyTopicLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type DigitalLegacyTopicLinkingCollectionsDigitalLegacyCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  order?: Maybe<Array<Maybe<DigitalLegacyTopicLinkingCollectionsDigitalLegacyCollectionOrder>>>
}

export enum DigitalLegacyTopicLinkingCollectionsDigitalLegacyCollectionOrder {
  HeadingTitleAsc = 'headingTitle_ASC',
  HeadingTitleDesc = 'headingTitle_DESC',
  PurchaseUrlAsc = 'purchaseUrl_ASC',
  PurchaseUrlDesc = 'purchaseUrl_DESC',
  ViewReviewUrlAsc = 'viewReviewUrl_ASC',
  ViewReviewUrlDesc = 'viewReviewUrl_DESC',
  AboutTitleAsc = 'aboutTitle_ASC',
  AboutTitleDesc = 'aboutTitle_DESC',
  AuthorTitleAsc = 'authorTitle_ASC',
  AuthorTitleDesc = 'authorTitle_DESC',
  ReviewTitleAsc = 'reviewTitle_ASC',
  ReviewTitleDesc = 'reviewTitle_DESC',
  FacebookUrlAsc = 'facebookUrl_ASC',
  FacebookUrlDesc = 'facebookUrl_DESC',
  TwitterUrlAsc = 'twitterUrl_ASC',
  TwitterUrlDesc = 'twitterUrl_DESC',
  LinkedinUrlAsc = 'linkedinUrl_ASC',
  LinkedinUrlDesc = 'linkedinUrl_DESC',
  InstagramUrlAsc = 'instagramUrl_ASC',
  InstagramUrlDesc = 'instagramUrl_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type DigitalLegacyTopicListCollection = {
  __typename?: 'DigitalLegacyTopicListCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<DigitalLegacyTopic>>
}

export enum DigitalLegacyTopicListCollectionOrder {
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum DigitalLegacyTopicOrder {
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/documents) */
export type Documents = Entry & {
  __typename?: 'Documents'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<DocumentsLinkingCollections>
  type?: Maybe<Scalars['String']>
  content?: Maybe<DocumentsContent>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/documents) */
export type DocumentsLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/documents) */
export type DocumentsTypeArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/documents) */
export type DocumentsContentArgs = {
  locale?: Maybe<Scalars['String']>
}

export type DocumentsCollection = {
  __typename?: 'DocumentsCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<Documents>>
}

export type DocumentsContent = {
  __typename?: 'DocumentsContent'
  json: Scalars['JSON']
  links: DocumentsContentLinks
}

export type DocumentsContentAssets = {
  __typename?: 'DocumentsContentAssets'
  hyperlink: Array<Maybe<Asset>>
  block: Array<Maybe<Asset>>
}

export type DocumentsContentEntries = {
  __typename?: 'DocumentsContentEntries'
  inline: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  block: Array<Maybe<Entry>>
}

export type DocumentsContentLinks = {
  __typename?: 'DocumentsContentLinks'
  entries: DocumentsContentEntries
  assets: DocumentsContentAssets
  resources: DocumentsContentResources
}

export type DocumentsContentResources = {
  __typename?: 'DocumentsContentResources'
  block: Array<DocumentsContentResourcesBlock>
  inline: Array<DocumentsContentResourcesInline>
  hyperlink: Array<DocumentsContentResourcesHyperlink>
}

export type DocumentsContentResourcesBlock = ResourceLink & {
  __typename?: 'DocumentsContentResourcesBlock'
  sys: ResourceSys
}

export type DocumentsContentResourcesHyperlink = ResourceLink & {
  __typename?: 'DocumentsContentResourcesHyperlink'
  sys: ResourceSys
}

export type DocumentsContentResourcesInline = ResourceLink & {
  __typename?: 'DocumentsContentResourcesInline'
  sys: ResourceSys
}

export type DocumentsFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  type_exists?: Maybe<Scalars['Boolean']>
  type?: Maybe<Scalars['String']>
  type_not?: Maybe<Scalars['String']>
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  type_contains?: Maybe<Scalars['String']>
  type_not_contains?: Maybe<Scalars['String']>
  content_exists?: Maybe<Scalars['Boolean']>
  content_contains?: Maybe<Scalars['String']>
  content_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<DocumentsFilter>>>
  AND?: Maybe<Array<Maybe<DocumentsFilter>>>
}

export type DocumentsLinkingCollections = {
  __typename?: 'DocumentsLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type DocumentsLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export enum DocumentsOrder {
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type Entry = {
  sys: Sys
  contentfulMetadata: ContentfulMetadata
}

export type EntryCollection = {
  __typename?: 'EntryCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<Entry>>
}

export type EntryFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  OR?: Maybe<Array<Maybe<EntryFilter>>>
  AND?: Maybe<Array<Maybe<EntryFilter>>>
}

export enum EntryOrder {
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/futureMessage) */
export type FutureMessage = Entry & {
  __typename?: 'FutureMessage'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<FutureMessageLinkingCollections>
  inspiredList?: Maybe<Array<Maybe<Scalars['String']>>>
  landingVideosPreviewCollection?: Maybe<AssetCollection>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/futureMessage) */
export type FutureMessageLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/futureMessage) */
export type FutureMessageInspiredListArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/futureMessage) */
export type FutureMessageLandingVideosPreviewCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type FutureMessageCollection = {
  __typename?: 'FutureMessageCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<FutureMessage>>
}

export type FutureMessageFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  inspiredList_exists?: Maybe<Scalars['Boolean']>
  inspiredList_contains_all?: Maybe<Array<Maybe<Scalars['String']>>>
  inspiredList_contains_some?: Maybe<Array<Maybe<Scalars['String']>>>
  inspiredList_contains_none?: Maybe<Array<Maybe<Scalars['String']>>>
  landingVideosPreviewCollection_exists?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<FutureMessageFilter>>>
  AND?: Maybe<Array<Maybe<FutureMessageFilter>>>
}

export type FutureMessageLinkingCollections = {
  __typename?: 'FutureMessageLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type FutureMessageLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export enum FutureMessageOrder {
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** For title & description of the each item, image file's title & description will be used [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/helpCarouselConfig) */
export type HelpCarouselConfig = Entry & {
  __typename?: 'HelpCarouselConfig'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<HelpCarouselConfigLinkingCollections>
  title?: Maybe<Scalars['String']>
  imagesCollection?: Maybe<AssetCollection>
}

/** For title & description of the each item, image file's title & description will be used [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/helpCarouselConfig) */
export type HelpCarouselConfigLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** For title & description of the each item, image file's title & description will be used [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/helpCarouselConfig) */
export type HelpCarouselConfigTitleArgs = {
  locale?: Maybe<Scalars['String']>
}

/** For title & description of the each item, image file's title & description will be used [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/helpCarouselConfig) */
export type HelpCarouselConfigImagesCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type HelpCarouselConfigCollection = {
  __typename?: 'HelpCarouselConfigCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<HelpCarouselConfig>>
}

export type HelpCarouselConfigFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  title_exists?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  title_not?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_contains?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  imagesCollection_exists?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<HelpCarouselConfigFilter>>>
  AND?: Maybe<Array<Maybe<HelpCarouselConfigFilter>>>
}

export type HelpCarouselConfigLinkingCollections = {
  __typename?: 'HelpCarouselConfigLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type HelpCarouselConfigLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export enum HelpCarouselConfigOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/homePageSetup) */
export type HomePageSetup = Entry & {
  __typename?: 'HomePageSetup'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<HomePageSetupLinkingCollections>
  type?: Maybe<Scalars['String']>
  reviewsListCollection?: Maybe<HomePageSetupReviewsListCollection>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/homePageSetup) */
export type HomePageSetupLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/homePageSetup) */
export type HomePageSetupTypeArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/homePageSetup) */
export type HomePageSetupReviewsListCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<MentionItemFilter>
  order?: Maybe<Array<Maybe<HomePageSetupReviewsListCollectionOrder>>>
}

export type HomePageSetupCollection = {
  __typename?: 'HomePageSetupCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<HomePageSetup>>
}

export type HomePageSetupFilter = {
  reviewsList?: Maybe<CfMentionItemNestedFilter>
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  type_exists?: Maybe<Scalars['Boolean']>
  type?: Maybe<Scalars['String']>
  type_not?: Maybe<Scalars['String']>
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  type_contains?: Maybe<Scalars['String']>
  type_not_contains?: Maybe<Scalars['String']>
  reviewsListCollection_exists?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<HomePageSetupFilter>>>
  AND?: Maybe<Array<Maybe<HomePageSetupFilter>>>
}

export type HomePageSetupLinkingCollections = {
  __typename?: 'HomePageSetupLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type HomePageSetupLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export enum HomePageSetupOrder {
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type HomePageSetupReviewsListCollection = {
  __typename?: 'HomePageSetupReviewsListCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<MentionItem>>
}

export enum HomePageSetupReviewsListCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CitationAsc = 'citation_ASC',
  CitationDesc = 'citation_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  BriefAsc = 'brief_ASC',
  BriefDesc = 'brief_DESC',
  StarsAsc = 'stars_ASC',
  StarsDesc = 'stars_DESC',
  PersonNameAsc = 'personName_ASC',
  PersonNameDesc = 'personName_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ImageFormat {
  /** JPG image format. */
  Jpg = 'JPG',
  /**
   * Progressive JPG format stores multiple passes of an image in progressively higher detail.
   *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
   *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
   *         early as possible to make the layout look as designed.
   */
  JpgProgressive = 'JPG_PROGRESSIVE',
  /** PNG image format */
  Png = 'PNG',
  /**
   * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
   *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
   */
  Png8 = 'PNG8',
  /** WebP image format. */
  Webp = 'WEBP',
  Avif = 'AVIF',
}

export enum ImageResizeFocus {
  /** Focus the resizing on the center. */
  Center = 'CENTER',
  /** Focus the resizing on the top. */
  Top = 'TOP',
  /** Focus the resizing on the top right. */
  TopRight = 'TOP_RIGHT',
  /** Focus the resizing on the right. */
  Right = 'RIGHT',
  /** Focus the resizing on the bottom right. */
  BottomRight = 'BOTTOM_RIGHT',
  /** Focus the resizing on the bottom. */
  Bottom = 'BOTTOM',
  /** Focus the resizing on the bottom left. */
  BottomLeft = 'BOTTOM_LEFT',
  /** Focus the resizing on the left. */
  Left = 'LEFT',
  /** Focus the resizing on the top left. */
  TopLeft = 'TOP_LEFT',
  /** Focus the resizing on the largest face. */
  Face = 'FACE',
  /** Focus the resizing on the area containing all the faces. */
  Faces = 'FACES',
}

export enum ImageResizeStrategy {
  /** Resizes the image to fit into the specified dimensions. */
  Fit = 'FIT',
  /**
   * Resizes the image to the specified dimensions, padding the image if needed.
   *         Uses desired background color as padding color.
   */
  Pad = 'PAD',
  /** Resizes the image to the specified dimensions, cropping the image if needed. */
  Fill = 'FILL',
  /** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
  Scale = 'SCALE',
  /** Crops a part of the original image to fit into the specified dimensions. */
  Crop = 'CROP',
  /** Creates a thumbnail from the image. */
  Thumb = 'THUMB',
}

export type ImageTransformOptions = {
  /** Desired width in pixels. Defaults to the original image width. */
  width?: Maybe<Scalars['Dimension']>
  /** Desired height in pixels. Defaults to the original image height. */
  height?: Maybe<Scalars['Dimension']>
  /**
   * Desired quality of the image in percents.
   *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
   */
  quality?: Maybe<Scalars['Quality']>
  /**
   * Desired corner radius in pixels.
   *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
   *         Defaults to `0`. Uses desired background color as padding color,
   *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
   */
  cornerRadius?: Maybe<Scalars['Int']>
  /** Desired resize strategy. Defaults to `FIT`. */
  resizeStrategy?: Maybe<ImageResizeStrategy>
  /** Desired resize focus area. Defaults to `CENTER`. */
  resizeFocus?: Maybe<ImageResizeFocus>
  /**
   * Desired background color, used with corner radius or `PAD` resize strategy.
   *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
   */
  backgroundColor?: Maybe<Scalars['HexColor']>
  /** Desired image format. Defaults to the original image format. */
  format?: Maybe<ImageFormat>
}

/** Picture, title, bullets [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/imageWithBulletsSection) */
export type ImageWithBulletsSection = Entry & {
  __typename?: 'ImageWithBulletsSection'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<ImageWithBulletsSectionLinkingCollections>
  title?: Maybe<Scalars['String']>
  image?: Maybe<Asset>
  bulletsCollection?: Maybe<ImageWithBulletsSectionBulletsCollection>
}

/** Picture, title, bullets [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/imageWithBulletsSection) */
export type ImageWithBulletsSectionLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** Picture, title, bullets [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/imageWithBulletsSection) */
export type ImageWithBulletsSectionTitleArgs = {
  locale?: Maybe<Scalars['String']>
}

/** Picture, title, bullets [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/imageWithBulletsSection) */
export type ImageWithBulletsSectionImageArgs = {
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

/** Picture, title, bullets [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/imageWithBulletsSection) */
export type ImageWithBulletsSectionBulletsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<ParagraphWithCaptionFilter>
  order?: Maybe<Array<Maybe<ImageWithBulletsSectionBulletsCollectionOrder>>>
}

export type ImageWithBulletsSectionBulletsCollection = {
  __typename?: 'ImageWithBulletsSectionBulletsCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<ParagraphWithCaption>>
}

export enum ImageWithBulletsSectionBulletsCollectionOrder {
  CaptionAsc = 'caption_ASC',
  CaptionDesc = 'caption_DESC',
  ParagraphAsc = 'paragraph_ASC',
  ParagraphDesc = 'paragraph_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ImageWithBulletsSectionCollection = {
  __typename?: 'ImageWithBulletsSectionCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<ImageWithBulletsSection>>
}

export type ImageWithBulletsSectionFilter = {
  bullets?: Maybe<CfParagraphWithCaptionNestedFilter>
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  title_exists?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  title_not?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_contains?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  image_exists?: Maybe<Scalars['Boolean']>
  bulletsCollection_exists?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<ImageWithBulletsSectionFilter>>>
  AND?: Maybe<Array<Maybe<ImageWithBulletsSectionFilter>>>
}

export type ImageWithBulletsSectionLinkingCollections = {
  __typename?: 'ImageWithBulletsSectionLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type ImageWithBulletsSectionLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export enum ImageWithBulletsSectionOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** An informing section that contains a title, image, description and button(s) to be used on landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/landingPageSection) */
export type LandingPageSection = Entry & {
  __typename?: 'LandingPageSection'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<LandingPageSectionLinkingCollections>
  title?: Maybe<Scalars['String']>
  image?: Maybe<Asset>
  description?: Maybe<LandingPageSectionDescription>
  primaryButtonLabel?: Maybe<Scalars['String']>
  secondaryButtonLabel?: Maybe<Scalars['String']>
  tag?: Maybe<Scalars['String']>
  extraParagraphsCollection?: Maybe<LandingPageSectionExtraParagraphsCollection>
  imagePlacement?: Maybe<Scalars['String']>
  hideButton?: Maybe<Scalars['Boolean']>
  descriptionBulletPointsCollection?: Maybe<LandingPageSectionDescriptionBulletPointsCollection>
  imageMobile?: Maybe<Asset>
}

/** An informing section that contains a title, image, description and button(s) to be used on landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/landingPageSection) */
export type LandingPageSectionLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** An informing section that contains a title, image, description and button(s) to be used on landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/landingPageSection) */
export type LandingPageSectionTitleArgs = {
  locale?: Maybe<Scalars['String']>
}

/** An informing section that contains a title, image, description and button(s) to be used on landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/landingPageSection) */
export type LandingPageSectionImageArgs = {
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

/** An informing section that contains a title, image, description and button(s) to be used on landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/landingPageSection) */
export type LandingPageSectionDescriptionArgs = {
  locale?: Maybe<Scalars['String']>
}

/** An informing section that contains a title, image, description and button(s) to be used on landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/landingPageSection) */
export type LandingPageSectionPrimaryButtonLabelArgs = {
  locale?: Maybe<Scalars['String']>
}

/** An informing section that contains a title, image, description and button(s) to be used on landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/landingPageSection) */
export type LandingPageSectionSecondaryButtonLabelArgs = {
  locale?: Maybe<Scalars['String']>
}

/** An informing section that contains a title, image, description and button(s) to be used on landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/landingPageSection) */
export type LandingPageSectionTagArgs = {
  locale?: Maybe<Scalars['String']>
}

/** An informing section that contains a title, image, description and button(s) to be used on landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/landingPageSection) */
export type LandingPageSectionExtraParagraphsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<LandingPageSectionExtraParagraphFilter>
  order?: Maybe<Array<Maybe<LandingPageSectionExtraParagraphsCollectionOrder>>>
}

/** An informing section that contains a title, image, description and button(s) to be used on landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/landingPageSection) */
export type LandingPageSectionImagePlacementArgs = {
  locale?: Maybe<Scalars['String']>
}

/** An informing section that contains a title, image, description and button(s) to be used on landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/landingPageSection) */
export type LandingPageSectionHideButtonArgs = {
  locale?: Maybe<Scalars['String']>
}

/** An informing section that contains a title, image, description and button(s) to be used on landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/landingPageSection) */
export type LandingPageSectionDescriptionBulletPointsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<OfferPerkFilter>
  order?: Maybe<Array<Maybe<LandingPageSectionDescriptionBulletPointsCollectionOrder>>>
}

/** An informing section that contains a title, image, description and button(s) to be used on landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/landingPageSection) */
export type LandingPageSectionImageMobileArgs = {
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type LandingPageSectionCollection = {
  __typename?: 'LandingPageSectionCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<LandingPageSection>>
}

export type LandingPageSectionDescription = {
  __typename?: 'LandingPageSectionDescription'
  json: Scalars['JSON']
  links: LandingPageSectionDescriptionLinks
}

export type LandingPageSectionDescriptionAssets = {
  __typename?: 'LandingPageSectionDescriptionAssets'
  hyperlink: Array<Maybe<Asset>>
  block: Array<Maybe<Asset>>
}

export type LandingPageSectionDescriptionBulletPointsCollection = {
  __typename?: 'LandingPageSectionDescriptionBulletPointsCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<OfferPerk>>
}

export enum LandingPageSectionDescriptionBulletPointsCollectionOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type LandingPageSectionDescriptionEntries = {
  __typename?: 'LandingPageSectionDescriptionEntries'
  inline: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  block: Array<Maybe<Entry>>
}

export type LandingPageSectionDescriptionLinks = {
  __typename?: 'LandingPageSectionDescriptionLinks'
  entries: LandingPageSectionDescriptionEntries
  assets: LandingPageSectionDescriptionAssets
  resources: LandingPageSectionDescriptionResources
}

export type LandingPageSectionDescriptionResources = {
  __typename?: 'LandingPageSectionDescriptionResources'
  block: Array<LandingPageSectionDescriptionResourcesBlock>
  inline: Array<LandingPageSectionDescriptionResourcesInline>
  hyperlink: Array<LandingPageSectionDescriptionResourcesHyperlink>
}

export type LandingPageSectionDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'LandingPageSectionDescriptionResourcesBlock'
  sys: ResourceSys
}

export type LandingPageSectionDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'LandingPageSectionDescriptionResourcesHyperlink'
  sys: ResourceSys
}

export type LandingPageSectionDescriptionResourcesInline = ResourceLink & {
  __typename?: 'LandingPageSectionDescriptionResourcesInline'
  sys: ResourceSys
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/landingPageSectionExtraParagraph) */
export type LandingPageSectionExtraParagraph = Entry & {
  __typename?: 'LandingPageSectionExtraParagraph'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<LandingPageSectionExtraParagraphLinkingCollections>
  title?: Maybe<Scalars['String']>
  description?: Maybe<LandingPageSectionExtraParagraphDescription>
  iconName?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/landingPageSectionExtraParagraph) */
export type LandingPageSectionExtraParagraphLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/landingPageSectionExtraParagraph) */
export type LandingPageSectionExtraParagraphTitleArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/landingPageSectionExtraParagraph) */
export type LandingPageSectionExtraParagraphDescriptionArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/landingPageSectionExtraParagraph) */
export type LandingPageSectionExtraParagraphIconNameArgs = {
  locale?: Maybe<Scalars['String']>
}

export type LandingPageSectionExtraParagraphCollection = {
  __typename?: 'LandingPageSectionExtraParagraphCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<LandingPageSectionExtraParagraph>>
}

export type LandingPageSectionExtraParagraphDescription = {
  __typename?: 'LandingPageSectionExtraParagraphDescription'
  json: Scalars['JSON']
  links: LandingPageSectionExtraParagraphDescriptionLinks
}

export type LandingPageSectionExtraParagraphDescriptionAssets = {
  __typename?: 'LandingPageSectionExtraParagraphDescriptionAssets'
  hyperlink: Array<Maybe<Asset>>
  block: Array<Maybe<Asset>>
}

export type LandingPageSectionExtraParagraphDescriptionEntries = {
  __typename?: 'LandingPageSectionExtraParagraphDescriptionEntries'
  inline: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  block: Array<Maybe<Entry>>
}

export type LandingPageSectionExtraParagraphDescriptionLinks = {
  __typename?: 'LandingPageSectionExtraParagraphDescriptionLinks'
  entries: LandingPageSectionExtraParagraphDescriptionEntries
  assets: LandingPageSectionExtraParagraphDescriptionAssets
  resources: LandingPageSectionExtraParagraphDescriptionResources
}

export type LandingPageSectionExtraParagraphDescriptionResources = {
  __typename?: 'LandingPageSectionExtraParagraphDescriptionResources'
  block: Array<LandingPageSectionExtraParagraphDescriptionResourcesBlock>
  inline: Array<LandingPageSectionExtraParagraphDescriptionResourcesInline>
  hyperlink: Array<LandingPageSectionExtraParagraphDescriptionResourcesHyperlink>
}

export type LandingPageSectionExtraParagraphDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'LandingPageSectionExtraParagraphDescriptionResourcesBlock'
  sys: ResourceSys
}

export type LandingPageSectionExtraParagraphDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'LandingPageSectionExtraParagraphDescriptionResourcesHyperlink'
  sys: ResourceSys
}

export type LandingPageSectionExtraParagraphDescriptionResourcesInline = ResourceLink & {
  __typename?: 'LandingPageSectionExtraParagraphDescriptionResourcesInline'
  sys: ResourceSys
}

export type LandingPageSectionExtraParagraphFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  title_exists?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  title_not?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_contains?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  description_exists?: Maybe<Scalars['Boolean']>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  iconName_exists?: Maybe<Scalars['Boolean']>
  iconName?: Maybe<Scalars['String']>
  iconName_not?: Maybe<Scalars['String']>
  iconName_in?: Maybe<Array<Maybe<Scalars['String']>>>
  iconName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  iconName_contains?: Maybe<Scalars['String']>
  iconName_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<LandingPageSectionExtraParagraphFilter>>>
  AND?: Maybe<Array<Maybe<LandingPageSectionExtraParagraphFilter>>>
}

export type LandingPageSectionExtraParagraphLinkingCollections = {
  __typename?: 'LandingPageSectionExtraParagraphLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  landingPageSectionCollection?: Maybe<LandingPageSectionCollection>
}

export type LandingPageSectionExtraParagraphLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type LandingPageSectionExtraParagraphLinkingCollectionsLandingPageSectionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  order?: Maybe<
    Array<
      Maybe<LandingPageSectionExtraParagraphLinkingCollectionsLandingPageSectionCollectionOrder>
    >
  >
}

export enum LandingPageSectionExtraParagraphLinkingCollectionsLandingPageSectionCollectionOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  PrimaryButtonLabelAsc = 'primaryButtonLabel_ASC',
  PrimaryButtonLabelDesc = 'primaryButtonLabel_DESC',
  SecondaryButtonLabelAsc = 'secondaryButtonLabel_ASC',
  SecondaryButtonLabelDesc = 'secondaryButtonLabel_DESC',
  TagAsc = 'tag_ASC',
  TagDesc = 'tag_DESC',
  ImagePlacementAsc = 'imagePlacement_ASC',
  ImagePlacementDesc = 'imagePlacement_DESC',
  HideButtonAsc = 'hideButton_ASC',
  HideButtonDesc = 'hideButton_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LandingPageSectionExtraParagraphOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  IconNameAsc = 'iconName_ASC',
  IconNameDesc = 'iconName_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type LandingPageSectionExtraParagraphsCollection = {
  __typename?: 'LandingPageSectionExtraParagraphsCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<LandingPageSectionExtraParagraph>>
}

export enum LandingPageSectionExtraParagraphsCollectionOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  IconNameAsc = 'iconName_ASC',
  IconNameDesc = 'iconName_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type LandingPageSectionFilter = {
  extraParagraphs?: Maybe<CfLandingPageSectionExtraParagraphNestedFilter>
  descriptionBulletPoints?: Maybe<CfOfferPerkNestedFilter>
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  title_exists?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  title_not?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_contains?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  image_exists?: Maybe<Scalars['Boolean']>
  description_exists?: Maybe<Scalars['Boolean']>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  primaryButtonLabel_exists?: Maybe<Scalars['Boolean']>
  primaryButtonLabel?: Maybe<Scalars['String']>
  primaryButtonLabel_not?: Maybe<Scalars['String']>
  primaryButtonLabel_in?: Maybe<Array<Maybe<Scalars['String']>>>
  primaryButtonLabel_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  primaryButtonLabel_contains?: Maybe<Scalars['String']>
  primaryButtonLabel_not_contains?: Maybe<Scalars['String']>
  secondaryButtonLabel_exists?: Maybe<Scalars['Boolean']>
  secondaryButtonLabel?: Maybe<Scalars['String']>
  secondaryButtonLabel_not?: Maybe<Scalars['String']>
  secondaryButtonLabel_in?: Maybe<Array<Maybe<Scalars['String']>>>
  secondaryButtonLabel_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  secondaryButtonLabel_contains?: Maybe<Scalars['String']>
  secondaryButtonLabel_not_contains?: Maybe<Scalars['String']>
  tag_exists?: Maybe<Scalars['Boolean']>
  tag?: Maybe<Scalars['String']>
  tag_not?: Maybe<Scalars['String']>
  tag_in?: Maybe<Array<Maybe<Scalars['String']>>>
  tag_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  tag_contains?: Maybe<Scalars['String']>
  tag_not_contains?: Maybe<Scalars['String']>
  extraParagraphsCollection_exists?: Maybe<Scalars['Boolean']>
  imagePlacement_exists?: Maybe<Scalars['Boolean']>
  imagePlacement?: Maybe<Scalars['String']>
  imagePlacement_not?: Maybe<Scalars['String']>
  imagePlacement_in?: Maybe<Array<Maybe<Scalars['String']>>>
  imagePlacement_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  imagePlacement_contains?: Maybe<Scalars['String']>
  imagePlacement_not_contains?: Maybe<Scalars['String']>
  hideButton_exists?: Maybe<Scalars['Boolean']>
  hideButton?: Maybe<Scalars['Boolean']>
  hideButton_not?: Maybe<Scalars['Boolean']>
  descriptionBulletPointsCollection_exists?: Maybe<Scalars['Boolean']>
  imageMobile_exists?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<LandingPageSectionFilter>>>
  AND?: Maybe<Array<Maybe<LandingPageSectionFilter>>>
}

export type LandingPageSectionLinkingCollections = {
  __typename?: 'LandingPageSectionLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type LandingPageSectionLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export enum LandingPageSectionOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  PrimaryButtonLabelAsc = 'primaryButtonLabel_ASC',
  PrimaryButtonLabelDesc = 'primaryButtonLabel_DESC',
  SecondaryButtonLabelAsc = 'secondaryButtonLabel_ASC',
  SecondaryButtonLabelDesc = 'secondaryButtonLabel_DESC',
  TagAsc = 'tag_ASC',
  TagDesc = 'tag_DESC',
  ImagePlacementAsc = 'imagePlacement_ASC',
  ImagePlacementDesc = 'imagePlacement_DESC',
  HideButtonAsc = 'hideButton_ASC',
  HideButtonDesc = 'hideButton_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/mediaMentionList) */
export type MediaMentionList = Entry & {
  __typename?: 'MediaMentionList'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<MediaMentionListLinkingCollections>
  slug?: Maybe<Scalars['String']>
  mediaMentionsCollection?: Maybe<MediaMentionListMediaMentionsCollection>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/mediaMentionList) */
export type MediaMentionListLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/mediaMentionList) */
export type MediaMentionListSlugArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/mediaMentionList) */
export type MediaMentionListMediaMentionsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<MentionItemFilter>
  order?: Maybe<Array<Maybe<MediaMentionListMediaMentionsCollectionOrder>>>
}

export type MediaMentionListCollection = {
  __typename?: 'MediaMentionListCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<MediaMentionList>>
}

export type MediaMentionListFilter = {
  mediaMentions?: Maybe<CfMentionItemNestedFilter>
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  slug_exists?: Maybe<Scalars['Boolean']>
  slug?: Maybe<Scalars['String']>
  slug_not?: Maybe<Scalars['String']>
  slug_in?: Maybe<Array<Maybe<Scalars['String']>>>
  slug_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  slug_contains?: Maybe<Scalars['String']>
  slug_not_contains?: Maybe<Scalars['String']>
  mediaMentionsCollection_exists?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<MediaMentionListFilter>>>
  AND?: Maybe<Array<Maybe<MediaMentionListFilter>>>
}

export type MediaMentionListLinkingCollections = {
  __typename?: 'MediaMentionListLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type MediaMentionListLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type MediaMentionListMediaMentionsCollection = {
  __typename?: 'MediaMentionListMediaMentionsCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<MentionItem>>
}

export enum MediaMentionListMediaMentionsCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CitationAsc = 'citation_ASC',
  CitationDesc = 'citation_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  BriefAsc = 'brief_ASC',
  BriefDesc = 'brief_DESC',
  StarsAsc = 'stars_ASC',
  StarsDesc = 'stars_DESC',
  PersonNameAsc = 'personName_ASC',
  PersonNameDesc = 'personName_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum MediaMentionListOrder {
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/mediaMentions) */
export type MediaMentions = Entry & {
  __typename?: 'MediaMentions'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<MediaMentionsLinkingCollections>
  type?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  mentionsListCollection?: Maybe<MediaMentionsMentionsListCollection>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/mediaMentions) */
export type MediaMentionsLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/mediaMentions) */
export type MediaMentionsTypeArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/mediaMentions) */
export type MediaMentionsTitleArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/mediaMentions) */
export type MediaMentionsMentionsListCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<MentionItemFilter>
  order?: Maybe<Array<Maybe<MediaMentionsMentionsListCollectionOrder>>>
}

export type MediaMentionsCollection = {
  __typename?: 'MediaMentionsCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<MediaMentions>>
}

export type MediaMentionsFilter = {
  mentionsList?: Maybe<CfMentionItemNestedFilter>
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  type_exists?: Maybe<Scalars['Boolean']>
  type?: Maybe<Scalars['String']>
  type_not?: Maybe<Scalars['String']>
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  type_contains?: Maybe<Scalars['String']>
  type_not_contains?: Maybe<Scalars['String']>
  title_exists?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  title_not?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_contains?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  mentionsListCollection_exists?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<MediaMentionsFilter>>>
  AND?: Maybe<Array<Maybe<MediaMentionsFilter>>>
}

export type MediaMentionsLinkingCollections = {
  __typename?: 'MediaMentionsLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type MediaMentionsLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type MediaMentionsMentionsListCollection = {
  __typename?: 'MediaMentionsMentionsListCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<MentionItem>>
}

export enum MediaMentionsMentionsListCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CitationAsc = 'citation_ASC',
  CitationDesc = 'citation_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  BriefAsc = 'brief_ASC',
  BriefDesc = 'brief_DESC',
  StarsAsc = 'stars_ASC',
  StarsDesc = 'stars_DESC',
  PersonNameAsc = 'personName_ASC',
  PersonNameDesc = 'personName_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum MediaMentionsOrder {
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Structure used to order live portraits, singing portraits and singing portraits songs. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/memorySetup) */
export type MemorySetup = Entry & {
  __typename?: 'MemorySetup'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<MemorySetupLinkingCollections>
  title?: Maybe<Scalars['String']>
  singingPortraitSongsCollection?: Maybe<MemorySetupSingingPortraitSongsCollection>
  livePortraitsCollection?: Maybe<MemorySetupLivePortraitsCollection>
  singingPortraitsCollection?: Maybe<MemorySetupSingingPortraitsCollection>
  storyPortraitTipsCollection?: Maybe<MemorySetupStoryPortraitTipsCollection>
  storyPortraitCategoriesCollection?: Maybe<MemorySetupStoryPortraitCategoriesCollection>
  storyPortraitPhrasesCollection?: Maybe<MemorySetupStoryPortraitPhrasesCollection>
  storyPortraitCarouselCollection?: Maybe<AssetCollection>
}

/** Structure used to order live portraits, singing portraits and singing portraits songs. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/memorySetup) */
export type MemorySetupLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** Structure used to order live portraits, singing portraits and singing portraits songs. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/memorySetup) */
export type MemorySetupTitleArgs = {
  locale?: Maybe<Scalars['String']>
}

/** Structure used to order live portraits, singing portraits and singing portraits songs. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/memorySetup) */
export type MemorySetupSingingPortraitSongsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<SingingPortraitSongFilter>
  order?: Maybe<Array<Maybe<MemorySetupSingingPortraitSongsCollectionOrder>>>
}

/** Structure used to order live portraits, singing portraits and singing portraits songs. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/memorySetup) */
export type MemorySetupLivePortraitsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<AnimatedPhotoFilter>
  order?: Maybe<Array<Maybe<MemorySetupLivePortraitsCollectionOrder>>>
}

/** Structure used to order live portraits, singing portraits and singing portraits songs. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/memorySetup) */
export type MemorySetupSingingPortraitsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<SingingPortraitFilter>
  order?: Maybe<Array<Maybe<MemorySetupSingingPortraitsCollectionOrder>>>
}

/** Structure used to order live portraits, singing portraits and singing portraits songs. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/memorySetup) */
export type MemorySetupStoryPortraitTipsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<TipFilter>
  order?: Maybe<Array<Maybe<MemorySetupStoryPortraitTipsCollectionOrder>>>
}

/** Structure used to order live portraits, singing portraits and singing portraits songs. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/memorySetup) */
export type MemorySetupStoryPortraitCategoriesCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<StoryPortraitCategoryFilter>
  order?: Maybe<Array<Maybe<MemorySetupStoryPortraitCategoriesCollectionOrder>>>
}

/** Structure used to order live portraits, singing portraits and singing portraits songs. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/memorySetup) */
export type MemorySetupStoryPortraitPhrasesCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<StoryPortraitPhraseFilter>
  order?: Maybe<Array<Maybe<MemorySetupStoryPortraitPhrasesCollectionOrder>>>
}

/** Structure used to order live portraits, singing portraits and singing portraits songs. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/memorySetup) */
export type MemorySetupStoryPortraitCarouselCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type MemorySetupCollection = {
  __typename?: 'MemorySetupCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<MemorySetup>>
}

export type MemorySetupFilter = {
  singingPortraitSongs?: Maybe<CfSingingPortraitSongNestedFilter>
  livePortraits?: Maybe<CfAnimatedPhotoNestedFilter>
  singingPortraits?: Maybe<CfSingingPortraitNestedFilter>
  storyPortraitTips?: Maybe<CfTipNestedFilter>
  storyPortraitCategories?: Maybe<CfStoryPortraitCategoryNestedFilter>
  storyPortraitPhrases?: Maybe<CfStoryPortraitPhraseNestedFilter>
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  title_exists?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  title_not?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_contains?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  singingPortraitSongsCollection_exists?: Maybe<Scalars['Boolean']>
  livePortraitsCollection_exists?: Maybe<Scalars['Boolean']>
  singingPortraitsCollection_exists?: Maybe<Scalars['Boolean']>
  storyPortraitTipsCollection_exists?: Maybe<Scalars['Boolean']>
  storyPortraitCategoriesCollection_exists?: Maybe<Scalars['Boolean']>
  storyPortraitPhrasesCollection_exists?: Maybe<Scalars['Boolean']>
  storyPortraitCarouselCollection_exists?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<MemorySetupFilter>>>
  AND?: Maybe<Array<Maybe<MemorySetupFilter>>>
}

export type MemorySetupLinkingCollections = {
  __typename?: 'MemorySetupLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type MemorySetupLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type MemorySetupLivePortraitsCollection = {
  __typename?: 'MemorySetupLivePortraitsCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<AnimatedPhoto>>
}

export enum MemorySetupLivePortraitsCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum MemorySetupOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type MemorySetupSingingPortraitSongsCollection = {
  __typename?: 'MemorySetupSingingPortraitSongsCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<SingingPortraitSong>>
}

export enum MemorySetupSingingPortraitSongsCollectionOrder {
  SongNameAsc = 'songName_ASC',
  SongNameDesc = 'songName_DESC',
  ArtistAsc = 'artist_ASC',
  ArtistDesc = 'artist_DESC',
  DriverIdAsc = 'driverId_ASC',
  DriverIdDesc = 'driverId_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type MemorySetupSingingPortraitsCollection = {
  __typename?: 'MemorySetupSingingPortraitsCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<SingingPortrait>>
}

export enum MemorySetupSingingPortraitsCollectionOrder {
  SongNameAsc = 'songName_ASC',
  SongNameDesc = 'songName_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type MemorySetupStoryPortraitCategoriesCollection = {
  __typename?: 'MemorySetupStoryPortraitCategoriesCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<StoryPortraitCategory>>
}

export enum MemorySetupStoryPortraitCategoriesCollectionOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type MemorySetupStoryPortraitPhrasesCollection = {
  __typename?: 'MemorySetupStoryPortraitPhrasesCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<StoryPortraitPhrase>>
}

export enum MemorySetupStoryPortraitPhrasesCollectionOrder {
  PhraseAsc = 'phrase_ASC',
  PhraseDesc = 'phrase_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type MemorySetupStoryPortraitTipsCollection = {
  __typename?: 'MemorySetupStoryPortraitTipsCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<Tip>>
}

export enum MemorySetupStoryPortraitTipsCollectionOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/mentionItem) */
export type MentionItem = Entry & {
  __typename?: 'MentionItem'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<MentionItemLinkingCollections>
  name?: Maybe<Scalars['String']>
  logo?: Maybe<Asset>
  citation?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  brief?: Maybe<Scalars['String']>
  personPhoto?: Maybe<Asset>
  stars?: Maybe<Scalars['Float']>
  personName?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/mentionItem) */
export type MentionItemLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/mentionItem) */
export type MentionItemNameArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/mentionItem) */
export type MentionItemLogoArgs = {
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/mentionItem) */
export type MentionItemCitationArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/mentionItem) */
export type MentionItemUrlArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/mentionItem) */
export type MentionItemBriefArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/mentionItem) */
export type MentionItemPersonPhotoArgs = {
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/mentionItem) */
export type MentionItemStarsArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/mentionItem) */
export type MentionItemPersonNameArgs = {
  locale?: Maybe<Scalars['String']>
}

export type MentionItemCollection = {
  __typename?: 'MentionItemCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<MentionItem>>
}

export type MentionItemFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  name_exists?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  logo_exists?: Maybe<Scalars['Boolean']>
  citation_exists?: Maybe<Scalars['Boolean']>
  citation?: Maybe<Scalars['String']>
  citation_not?: Maybe<Scalars['String']>
  citation_in?: Maybe<Array<Maybe<Scalars['String']>>>
  citation_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  citation_contains?: Maybe<Scalars['String']>
  citation_not_contains?: Maybe<Scalars['String']>
  url_exists?: Maybe<Scalars['Boolean']>
  url?: Maybe<Scalars['String']>
  url_not?: Maybe<Scalars['String']>
  url_in?: Maybe<Array<Maybe<Scalars['String']>>>
  url_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  url_contains?: Maybe<Scalars['String']>
  url_not_contains?: Maybe<Scalars['String']>
  brief_exists?: Maybe<Scalars['Boolean']>
  brief?: Maybe<Scalars['String']>
  brief_not?: Maybe<Scalars['String']>
  brief_in?: Maybe<Array<Maybe<Scalars['String']>>>
  brief_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  brief_contains?: Maybe<Scalars['String']>
  brief_not_contains?: Maybe<Scalars['String']>
  personPhoto_exists?: Maybe<Scalars['Boolean']>
  stars_exists?: Maybe<Scalars['Boolean']>
  stars?: Maybe<Scalars['Float']>
  stars_not?: Maybe<Scalars['Float']>
  stars_in?: Maybe<Array<Maybe<Scalars['Float']>>>
  stars_not_in?: Maybe<Array<Maybe<Scalars['Float']>>>
  stars_gt?: Maybe<Scalars['Float']>
  stars_gte?: Maybe<Scalars['Float']>
  stars_lt?: Maybe<Scalars['Float']>
  stars_lte?: Maybe<Scalars['Float']>
  personName_exists?: Maybe<Scalars['Boolean']>
  personName?: Maybe<Scalars['String']>
  personName_not?: Maybe<Scalars['String']>
  personName_in?: Maybe<Array<Maybe<Scalars['String']>>>
  personName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  personName_contains?: Maybe<Scalars['String']>
  personName_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<MentionItemFilter>>>
  AND?: Maybe<Array<Maybe<MentionItemFilter>>>
}

export type MentionItemLinkingCollections = {
  __typename?: 'MentionItemLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  homePageSetupCollection?: Maybe<HomePageSetupCollection>
  mediaMentionListCollection?: Maybe<MediaMentionListCollection>
  mediaMentionsCollection?: Maybe<MediaMentionsCollection>
}

export type MentionItemLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type MentionItemLinkingCollectionsHomePageSetupCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  order?: Maybe<Array<Maybe<MentionItemLinkingCollectionsHomePageSetupCollectionOrder>>>
}

export type MentionItemLinkingCollectionsMediaMentionListCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  order?: Maybe<Array<Maybe<MentionItemLinkingCollectionsMediaMentionListCollectionOrder>>>
}

export type MentionItemLinkingCollectionsMediaMentionsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  order?: Maybe<Array<Maybe<MentionItemLinkingCollectionsMediaMentionsCollectionOrder>>>
}

export enum MentionItemLinkingCollectionsHomePageSetupCollectionOrder {
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum MentionItemLinkingCollectionsMediaMentionListCollectionOrder {
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum MentionItemLinkingCollectionsMediaMentionsCollectionOrder {
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum MentionItemOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CitationAsc = 'citation_ASC',
  CitationDesc = 'citation_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  BriefAsc = 'brief_ASC',
  BriefDesc = 'brief_DESC',
  StarsAsc = 'stars_ASC',
  StarsDesc = 'stars_DESC',
  PersonNameAsc = 'personName_ASC',
  PersonNameDesc = 'personName_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/offerPerk) */
export type OfferPerk = Entry & {
  __typename?: 'OfferPerk'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<OfferPerkLinkingCollections>
  title?: Maybe<Scalars['String']>
  description?: Maybe<OfferPerkDescription>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/offerPerk) */
export type OfferPerkLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/offerPerk) */
export type OfferPerkTitleArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/offerPerk) */
export type OfferPerkDescriptionArgs = {
  locale?: Maybe<Scalars['String']>
}

export type OfferPerkCollection = {
  __typename?: 'OfferPerkCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<OfferPerk>>
}

export type OfferPerkDescription = {
  __typename?: 'OfferPerkDescription'
  json: Scalars['JSON']
  links: OfferPerkDescriptionLinks
}

export type OfferPerkDescriptionAssets = {
  __typename?: 'OfferPerkDescriptionAssets'
  hyperlink: Array<Maybe<Asset>>
  block: Array<Maybe<Asset>>
}

export type OfferPerkDescriptionEntries = {
  __typename?: 'OfferPerkDescriptionEntries'
  inline: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  block: Array<Maybe<Entry>>
}

export type OfferPerkDescriptionLinks = {
  __typename?: 'OfferPerkDescriptionLinks'
  entries: OfferPerkDescriptionEntries
  assets: OfferPerkDescriptionAssets
  resources: OfferPerkDescriptionResources
}

export type OfferPerkDescriptionResources = {
  __typename?: 'OfferPerkDescriptionResources'
  block: Array<OfferPerkDescriptionResourcesBlock>
  inline: Array<OfferPerkDescriptionResourcesInline>
  hyperlink: Array<OfferPerkDescriptionResourcesHyperlink>
}

export type OfferPerkDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'OfferPerkDescriptionResourcesBlock'
  sys: ResourceSys
}

export type OfferPerkDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'OfferPerkDescriptionResourcesHyperlink'
  sys: ResourceSys
}

export type OfferPerkDescriptionResourcesInline = ResourceLink & {
  __typename?: 'OfferPerkDescriptionResourcesInline'
  sys: ResourceSys
}

export type OfferPerkFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  title_exists?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  title_not?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_contains?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  description_exists?: Maybe<Scalars['Boolean']>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<OfferPerkFilter>>>
  AND?: Maybe<Array<Maybe<OfferPerkFilter>>>
}

export type OfferPerkLinkingCollections = {
  __typename?: 'OfferPerkLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  landingPageSectionCollection?: Maybe<LandingPageSectionCollection>
}

export type OfferPerkLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type OfferPerkLinkingCollectionsLandingPageSectionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  order?: Maybe<Array<Maybe<OfferPerkLinkingCollectionsLandingPageSectionCollectionOrder>>>
}

export enum OfferPerkLinkingCollectionsLandingPageSectionCollectionOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  PrimaryButtonLabelAsc = 'primaryButtonLabel_ASC',
  PrimaryButtonLabelDesc = 'primaryButtonLabel_DESC',
  SecondaryButtonLabelAsc = 'secondaryButtonLabel_ASC',
  SecondaryButtonLabelDesc = 'secondaryButtonLabel_DESC',
  TagAsc = 'tag_ASC',
  TagDesc = 'tag_DESC',
  ImagePlacementAsc = 'imagePlacement_ASC',
  ImagePlacementDesc = 'imagePlacement_DESC',
  HideButtonAsc = 'hideButton_ASC',
  HideButtonDesc = 'hideButton_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum OfferPerkOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/pageArticle) */
export type PageArticle = Entry & {
  __typename?: 'PageArticle'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<PageArticleLinkingCollections>
  slug?: Maybe<Scalars['String']>
  article?: Maybe<Article>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/pageArticle) */
export type PageArticleLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/pageArticle) */
export type PageArticleSlugArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/pageArticle) */
export type PageArticleArticleArgs = {
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<ArticleFilter>
}

export type PageArticleCollection = {
  __typename?: 'PageArticleCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<PageArticle>>
}

export type PageArticleFilter = {
  article?: Maybe<CfArticleNestedFilter>
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  slug_exists?: Maybe<Scalars['Boolean']>
  slug?: Maybe<Scalars['String']>
  slug_not?: Maybe<Scalars['String']>
  slug_in?: Maybe<Array<Maybe<Scalars['String']>>>
  slug_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  slug_contains?: Maybe<Scalars['String']>
  slug_not_contains?: Maybe<Scalars['String']>
  article_exists?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<PageArticleFilter>>>
  AND?: Maybe<Array<Maybe<PageArticleFilter>>>
}

export type PageArticleLinkingCollections = {
  __typename?: 'PageArticleLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type PageArticleLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export enum PageArticleOrder {
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/pageArticles) */
export type PageArticles = Entry & {
  __typename?: 'PageArticles'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<PageArticlesLinkingCollections>
  page?: Maybe<Scalars['String']>
  articlesCollection?: Maybe<PageArticlesArticlesCollection>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/pageArticles) */
export type PageArticlesLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/pageArticles) */
export type PageArticlesPageArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/pageArticles) */
export type PageArticlesArticlesCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<ArticleFilter>
  order?: Maybe<Array<Maybe<PageArticlesArticlesCollectionOrder>>>
}

export type PageArticlesArticlesCollection = {
  __typename?: 'PageArticlesArticlesCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<Article>>
}

export enum PageArticlesArticlesCollectionOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  FeaturedAsc = 'featured_ASC',
  FeaturedDesc = 'featured_DESC',
  SiteIdAsc = 'siteId_ASC',
  SiteIdDesc = 'siteId_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type PageArticlesCollection = {
  __typename?: 'PageArticlesCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<PageArticles>>
}

export type PageArticlesFilter = {
  articles?: Maybe<CfArticleNestedFilter>
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  page_exists?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['String']>
  page_not?: Maybe<Scalars['String']>
  page_in?: Maybe<Array<Maybe<Scalars['String']>>>
  page_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  page_contains?: Maybe<Scalars['String']>
  page_not_contains?: Maybe<Scalars['String']>
  articlesCollection_exists?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<PageArticlesFilter>>>
  AND?: Maybe<Array<Maybe<PageArticlesFilter>>>
}

export type PageArticlesLinkingCollections = {
  __typename?: 'PageArticlesLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type PageArticlesLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export enum PageArticlesOrder {
  PageAsc = 'page_ASC',
  PageDesc = 'page_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/pageDocument) */
export type PageDocument = Entry & {
  __typename?: 'PageDocument'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<PageDocumentLinkingCollections>
  slug?: Maybe<Scalars['String']>
  content?: Maybe<PageDocumentContent>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/pageDocument) */
export type PageDocumentLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/pageDocument) */
export type PageDocumentSlugArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/pageDocument) */
export type PageDocumentContentArgs = {
  locale?: Maybe<Scalars['String']>
}

export type PageDocumentCollection = {
  __typename?: 'PageDocumentCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<PageDocument>>
}

export type PageDocumentContent = {
  __typename?: 'PageDocumentContent'
  json: Scalars['JSON']
  links: PageDocumentContentLinks
}

export type PageDocumentContentAssets = {
  __typename?: 'PageDocumentContentAssets'
  hyperlink: Array<Maybe<Asset>>
  block: Array<Maybe<Asset>>
}

export type PageDocumentContentEntries = {
  __typename?: 'PageDocumentContentEntries'
  inline: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  block: Array<Maybe<Entry>>
}

export type PageDocumentContentLinks = {
  __typename?: 'PageDocumentContentLinks'
  entries: PageDocumentContentEntries
  assets: PageDocumentContentAssets
  resources: PageDocumentContentResources
}

export type PageDocumentContentResources = {
  __typename?: 'PageDocumentContentResources'
  block: Array<PageDocumentContentResourcesBlock>
  inline: Array<PageDocumentContentResourcesInline>
  hyperlink: Array<PageDocumentContentResourcesHyperlink>
}

export type PageDocumentContentResourcesBlock = ResourceLink & {
  __typename?: 'PageDocumentContentResourcesBlock'
  sys: ResourceSys
}

export type PageDocumentContentResourcesHyperlink = ResourceLink & {
  __typename?: 'PageDocumentContentResourcesHyperlink'
  sys: ResourceSys
}

export type PageDocumentContentResourcesInline = ResourceLink & {
  __typename?: 'PageDocumentContentResourcesInline'
  sys: ResourceSys
}

export type PageDocumentFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  slug_exists?: Maybe<Scalars['Boolean']>
  slug?: Maybe<Scalars['String']>
  slug_not?: Maybe<Scalars['String']>
  slug_in?: Maybe<Array<Maybe<Scalars['String']>>>
  slug_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  slug_contains?: Maybe<Scalars['String']>
  slug_not_contains?: Maybe<Scalars['String']>
  content_exists?: Maybe<Scalars['Boolean']>
  content_contains?: Maybe<Scalars['String']>
  content_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<PageDocumentFilter>>>
  AND?: Maybe<Array<Maybe<PageDocumentFilter>>>
}

export type PageDocumentLinkingCollections = {
  __typename?: 'PageDocumentLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type PageDocumentLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export enum PageDocumentOrder {
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/pageFaq) */
export type PageFaq = Entry & {
  __typename?: 'PageFaq'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<PageFaqLinkingCollections>
  slug?: Maybe<Scalars['String']>
  faqListCollection?: Maybe<PageFaqFaqListCollection>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/pageFaq) */
export type PageFaqLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/pageFaq) */
export type PageFaqSlugArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/pageFaq) */
export type PageFaqFaqListCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<AnsweredQuestionFilter>
  order?: Maybe<Array<Maybe<PageFaqFaqListCollectionOrder>>>
}

export type PageFaqCollection = {
  __typename?: 'PageFaqCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<PageFaq>>
}

export type PageFaqFaqListCollection = {
  __typename?: 'PageFaqFaqListCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<AnsweredQuestion>>
}

export enum PageFaqFaqListCollectionOrder {
  QuestionAsc = 'question_ASC',
  QuestionDesc = 'question_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type PageFaqFilter = {
  faqList?: Maybe<CfAnsweredQuestionNestedFilter>
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  slug_exists?: Maybe<Scalars['Boolean']>
  slug?: Maybe<Scalars['String']>
  slug_not?: Maybe<Scalars['String']>
  slug_in?: Maybe<Array<Maybe<Scalars['String']>>>
  slug_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  slug_contains?: Maybe<Scalars['String']>
  slug_not_contains?: Maybe<Scalars['String']>
  faqListCollection_exists?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<PageFaqFilter>>>
  AND?: Maybe<Array<Maybe<PageFaqFilter>>>
}

export type PageFaqLinkingCollections = {
  __typename?: 'PageFaqLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type PageFaqLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export enum PageFaqOrder {
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/pageFile) */
export type PageFile = Entry & {
  __typename?: 'PageFile'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<PageFileLinkingCollections>
  slug?: Maybe<Scalars['String']>
  file?: Maybe<Asset>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/pageFile) */
export type PageFileLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/pageFile) */
export type PageFileSlugArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/pageFile) */
export type PageFileFileArgs = {
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type PageFileCollection = {
  __typename?: 'PageFileCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<PageFile>>
}

export type PageFileFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  slug_exists?: Maybe<Scalars['Boolean']>
  slug?: Maybe<Scalars['String']>
  slug_not?: Maybe<Scalars['String']>
  slug_in?: Maybe<Array<Maybe<Scalars['String']>>>
  slug_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  slug_contains?: Maybe<Scalars['String']>
  slug_not_contains?: Maybe<Scalars['String']>
  file_exists?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<PageFileFilter>>>
  AND?: Maybe<Array<Maybe<PageFileFilter>>>
}

export type PageFileLinkingCollections = {
  __typename?: 'PageFileLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type PageFileLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export enum PageFileOrder {
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/pageImage) */
export type PageImage = Entry & {
  __typename?: 'PageImage'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<PageImageLinkingCollections>
  slug?: Maybe<Scalars['String']>
  image?: Maybe<Asset>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/pageImage) */
export type PageImageLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/pageImage) */
export type PageImageSlugArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/pageImage) */
export type PageImageImageArgs = {
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type PageImageCollection = {
  __typename?: 'PageImageCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<PageImage>>
}

export type PageImageFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  slug_exists?: Maybe<Scalars['Boolean']>
  slug?: Maybe<Scalars['String']>
  slug_not?: Maybe<Scalars['String']>
  slug_in?: Maybe<Array<Maybe<Scalars['String']>>>
  slug_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  slug_contains?: Maybe<Scalars['String']>
  slug_not_contains?: Maybe<Scalars['String']>
  image_exists?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<PageImageFilter>>>
  AND?: Maybe<Array<Maybe<PageImageFilter>>>
}

export type PageImageLinkingCollections = {
  __typename?: 'PageImageLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type PageImageLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export enum PageImageOrder {
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/pageVideo) */
export type PageVideo = Entry & {
  __typename?: 'PageVideo'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<PageVideoLinkingCollections>
  slug?: Maybe<Scalars['String']>
  introVideoId?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/pageVideo) */
export type PageVideoLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/pageVideo) */
export type PageVideoSlugArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/pageVideo) */
export type PageVideoIntroVideoIdArgs = {
  locale?: Maybe<Scalars['String']>
}

export type PageVideoCollection = {
  __typename?: 'PageVideoCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<PageVideo>>
}

export type PageVideoFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  slug_exists?: Maybe<Scalars['Boolean']>
  slug?: Maybe<Scalars['String']>
  slug_not?: Maybe<Scalars['String']>
  slug_in?: Maybe<Array<Maybe<Scalars['String']>>>
  slug_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  slug_contains?: Maybe<Scalars['String']>
  slug_not_contains?: Maybe<Scalars['String']>
  introVideoId_exists?: Maybe<Scalars['Boolean']>
  introVideoId?: Maybe<Scalars['String']>
  introVideoId_not?: Maybe<Scalars['String']>
  introVideoId_in?: Maybe<Array<Maybe<Scalars['String']>>>
  introVideoId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  introVideoId_contains?: Maybe<Scalars['String']>
  introVideoId_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<PageVideoFilter>>>
  AND?: Maybe<Array<Maybe<PageVideoFilter>>>
}

export type PageVideoLinkingCollections = {
  __typename?: 'PageVideoLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type PageVideoLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export enum PageVideoOrder {
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  IntroVideoIdAsc = 'introVideoId_ASC',
  IntroVideoIdDesc = 'introVideoId_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/paragraphWithCaption) */
export type ParagraphWithCaption = Entry & {
  __typename?: 'ParagraphWithCaption'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<ParagraphWithCaptionLinkingCollections>
  caption?: Maybe<Scalars['String']>
  paragraph?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/paragraphWithCaption) */
export type ParagraphWithCaptionLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/paragraphWithCaption) */
export type ParagraphWithCaptionCaptionArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/paragraphWithCaption) */
export type ParagraphWithCaptionParagraphArgs = {
  locale?: Maybe<Scalars['String']>
}

export type ParagraphWithCaptionCollection = {
  __typename?: 'ParagraphWithCaptionCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<ParagraphWithCaption>>
}

export type ParagraphWithCaptionFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  caption_exists?: Maybe<Scalars['Boolean']>
  caption?: Maybe<Scalars['String']>
  caption_not?: Maybe<Scalars['String']>
  caption_in?: Maybe<Array<Maybe<Scalars['String']>>>
  caption_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  caption_contains?: Maybe<Scalars['String']>
  caption_not_contains?: Maybe<Scalars['String']>
  paragraph_exists?: Maybe<Scalars['Boolean']>
  paragraph?: Maybe<Scalars['String']>
  paragraph_not?: Maybe<Scalars['String']>
  paragraph_in?: Maybe<Array<Maybe<Scalars['String']>>>
  paragraph_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  paragraph_contains?: Maybe<Scalars['String']>
  paragraph_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<ParagraphWithCaptionFilter>>>
  AND?: Maybe<Array<Maybe<ParagraphWithCaptionFilter>>>
}

export type ParagraphWithCaptionLinkingCollections = {
  __typename?: 'ParagraphWithCaptionLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  imageWithBulletsSectionCollection?: Maybe<ImageWithBulletsSectionCollection>
}

export type ParagraphWithCaptionLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type ParagraphWithCaptionLinkingCollectionsImageWithBulletsSectionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  order?: Maybe<
    Array<Maybe<ParagraphWithCaptionLinkingCollectionsImageWithBulletsSectionCollectionOrder>>
  >
}

export enum ParagraphWithCaptionLinkingCollectionsImageWithBulletsSectionCollectionOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ParagraphWithCaptionOrder {
  CaptionAsc = 'caption_ASC',
  CaptionDesc = 'caption_DESC',
  ParagraphAsc = 'paragraph_ASC',
  ParagraphDesc = 'paragraph_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/partnerBenefit) */
export type PartnerBenefit = Entry & {
  __typename?: 'PartnerBenefit'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<PartnerBenefitLinkingCollections>
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/partnerBenefit) */
export type PartnerBenefitLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/partnerBenefit) */
export type PartnerBenefitTitleArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/partnerBenefit) */
export type PartnerBenefitDescriptionArgs = {
  locale?: Maybe<Scalars['String']>
}

export type PartnerBenefitCollection = {
  __typename?: 'PartnerBenefitCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<PartnerBenefit>>
}

export type PartnerBenefitFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  title_exists?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  title_not?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_contains?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  description_exists?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  description_not?: Maybe<Scalars['String']>
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<PartnerBenefitFilter>>>
  AND?: Maybe<Array<Maybe<PartnerBenefitFilter>>>
}

export type PartnerBenefitLinkingCollections = {
  __typename?: 'PartnerBenefitLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  partnerLandingCollection?: Maybe<PartnerLandingCollection>
}

export type PartnerBenefitLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type PartnerBenefitLinkingCollectionsPartnerLandingCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  order?: Maybe<Array<Maybe<PartnerBenefitLinkingCollectionsPartnerLandingCollectionOrder>>>
}

export enum PartnerBenefitLinkingCollectionsPartnerLandingCollectionOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  BenefitsIntroAsc = 'benefitsIntro_ASC',
  BenefitsIntroDesc = 'benefitsIntro_DESC',
  BenefitsTitleAsc = 'benefitsTitle_ASC',
  BenefitsTitleDesc = 'benefitsTitle_DESC',
  ActionTitleAsc = 'actionTitle_ASC',
  ActionTitleDesc = 'actionTitle_DESC',
  LandingButtonTextAsc = 'landingButtonText_ASC',
  LandingButtonTextDesc = 'landingButtonText_DESC',
  PromocodeAsc = 'promocode_ASC',
  PromocodeDesc = 'promocode_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PartnerBenefitOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/partnerLanding) */
export type PartnerLanding = Entry & {
  __typename?: 'PartnerLanding'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<PartnerLandingLinkingCollections>
  title?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  cover?: Maybe<Asset>
  benefitsIntro?: Maybe<Scalars['String']>
  benefitsTitle?: Maybe<Scalars['String']>
  benefitsCollection?: Maybe<PartnerLandingBenefitsCollection>
  actionTitle?: Maybe<Scalars['String']>
  actionDescription?: Maybe<Scalars['String']>
  landingButtonText?: Maybe<Scalars['String']>
  promocode?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/partnerLanding) */
export type PartnerLandingLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/partnerLanding) */
export type PartnerLandingTitleArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/partnerLanding) */
export type PartnerLandingDescriptionArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/partnerLanding) */
export type PartnerLandingCoverArgs = {
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/partnerLanding) */
export type PartnerLandingBenefitsIntroArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/partnerLanding) */
export type PartnerLandingBenefitsTitleArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/partnerLanding) */
export type PartnerLandingBenefitsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<PartnerBenefitFilter>
  order?: Maybe<Array<Maybe<PartnerLandingBenefitsCollectionOrder>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/partnerLanding) */
export type PartnerLandingActionTitleArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/partnerLanding) */
export type PartnerLandingActionDescriptionArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/partnerLanding) */
export type PartnerLandingLandingButtonTextArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/partnerLanding) */
export type PartnerLandingPromocodeArgs = {
  locale?: Maybe<Scalars['String']>
}

export type PartnerLandingBenefitsCollection = {
  __typename?: 'PartnerLandingBenefitsCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<PartnerBenefit>>
}

export enum PartnerLandingBenefitsCollectionOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type PartnerLandingCollection = {
  __typename?: 'PartnerLandingCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<PartnerLanding>>
}

export type PartnerLandingFilter = {
  benefits?: Maybe<CfPartnerBenefitNestedFilter>
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  title_exists?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  title_not?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_contains?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  description_exists?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  description_not?: Maybe<Scalars['String']>
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  cover_exists?: Maybe<Scalars['Boolean']>
  benefitsIntro_exists?: Maybe<Scalars['Boolean']>
  benefitsIntro?: Maybe<Scalars['String']>
  benefitsIntro_not?: Maybe<Scalars['String']>
  benefitsIntro_in?: Maybe<Array<Maybe<Scalars['String']>>>
  benefitsIntro_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  benefitsIntro_contains?: Maybe<Scalars['String']>
  benefitsIntro_not_contains?: Maybe<Scalars['String']>
  benefitsTitle_exists?: Maybe<Scalars['Boolean']>
  benefitsTitle?: Maybe<Scalars['String']>
  benefitsTitle_not?: Maybe<Scalars['String']>
  benefitsTitle_in?: Maybe<Array<Maybe<Scalars['String']>>>
  benefitsTitle_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  benefitsTitle_contains?: Maybe<Scalars['String']>
  benefitsTitle_not_contains?: Maybe<Scalars['String']>
  benefitsCollection_exists?: Maybe<Scalars['Boolean']>
  actionTitle_exists?: Maybe<Scalars['Boolean']>
  actionTitle?: Maybe<Scalars['String']>
  actionTitle_not?: Maybe<Scalars['String']>
  actionTitle_in?: Maybe<Array<Maybe<Scalars['String']>>>
  actionTitle_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  actionTitle_contains?: Maybe<Scalars['String']>
  actionTitle_not_contains?: Maybe<Scalars['String']>
  actionDescription_exists?: Maybe<Scalars['Boolean']>
  actionDescription?: Maybe<Scalars['String']>
  actionDescription_not?: Maybe<Scalars['String']>
  actionDescription_in?: Maybe<Array<Maybe<Scalars['String']>>>
  actionDescription_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  actionDescription_contains?: Maybe<Scalars['String']>
  actionDescription_not_contains?: Maybe<Scalars['String']>
  landingButtonText_exists?: Maybe<Scalars['Boolean']>
  landingButtonText?: Maybe<Scalars['String']>
  landingButtonText_not?: Maybe<Scalars['String']>
  landingButtonText_in?: Maybe<Array<Maybe<Scalars['String']>>>
  landingButtonText_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  landingButtonText_contains?: Maybe<Scalars['String']>
  landingButtonText_not_contains?: Maybe<Scalars['String']>
  promocode_exists?: Maybe<Scalars['Boolean']>
  promocode?: Maybe<Scalars['String']>
  promocode_not?: Maybe<Scalars['String']>
  promocode_in?: Maybe<Array<Maybe<Scalars['String']>>>
  promocode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  promocode_contains?: Maybe<Scalars['String']>
  promocode_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<PartnerLandingFilter>>>
  AND?: Maybe<Array<Maybe<PartnerLandingFilter>>>
}

export type PartnerLandingLinkingCollections = {
  __typename?: 'PartnerLandingLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type PartnerLandingLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export enum PartnerLandingOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  BenefitsIntroAsc = 'benefitsIntro_ASC',
  BenefitsIntroDesc = 'benefitsIntro_DESC',
  BenefitsTitleAsc = 'benefitsTitle_ASC',
  BenefitsTitleDesc = 'benefitsTitle_DESC',
  ActionTitleAsc = 'actionTitle_ASC',
  ActionTitleDesc = 'actionTitle_DESC',
  LandingButtonTextAsc = 'landingButtonText_ASC',
  LandingButtonTextDesc = 'landingButtonText_DESC',
  PromocodeAsc = 'promocode_ASC',
  PromocodeDesc = 'promocode_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/person) */
export type Person = Entry & {
  __typename?: 'Person'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<PersonLinkingCollections>
  name?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  photo?: Maybe<Asset>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/person) */
export type PersonLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/person) */
export type PersonNameArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/person) */
export type PersonTitleArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/person) */
export type PersonPhotoArgs = {
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type PersonCollection = {
  __typename?: 'PersonCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<Person>>
}

export type PersonFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  name_exists?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  title_exists?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  title_not?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_contains?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  photo_exists?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<PersonFilter>>>
  AND?: Maybe<Array<Maybe<PersonFilter>>>
}

export type PersonLinkingCollections = {
  __typename?: 'PersonLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  aboutUsPageCollection?: Maybe<AboutUsPageCollection>
}

export type PersonLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type PersonLinkingCollectionsAboutUsPageCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  order?: Maybe<Array<Maybe<PersonLinkingCollectionsAboutUsPageCollectionOrder>>>
}

export enum PersonLinkingCollectionsAboutUsPageCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PersonOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type Query = {
  __typename?: 'Query'
  asset?: Maybe<Asset>
  assetCollection?: Maybe<AssetCollection>
  pageArticles?: Maybe<PageArticles>
  pageArticlesCollection?: Maybe<PageArticlesCollection>
  pageArticle?: Maybe<PageArticle>
  pageArticleCollection?: Maybe<PageArticleCollection>
  pageFile?: Maybe<PageFile>
  pageFileCollection?: Maybe<PageFileCollection>
  homePageSetup?: Maybe<HomePageSetup>
  homePageSetupCollection?: Maybe<HomePageSetupCollection>
  mediaMentionList?: Maybe<MediaMentionList>
  mediaMentionListCollection?: Maybe<MediaMentionListCollection>
  aboutUsPage?: Maybe<AboutUsPage>
  aboutUsPageCollection?: Maybe<AboutUsPageCollection>
  memorySetup?: Maybe<MemorySetup>
  memorySetupCollection?: Maybe<MemorySetupCollection>
  tip?: Maybe<Tip>
  tipCollection?: Maybe<TipCollection>
  storyPortraitCategory?: Maybe<StoryPortraitCategory>
  storyPortraitCategoryCollection?: Maybe<StoryPortraitCategoryCollection>
  storyPortraitPhrase?: Maybe<StoryPortraitPhrase>
  storyPortraitPhraseCollection?: Maybe<StoryPortraitPhraseCollection>
  pageVideo?: Maybe<PageVideo>
  pageVideoCollection?: Maybe<PageVideoCollection>
  pageDocument?: Maybe<PageDocument>
  pageDocumentCollection?: Maybe<PageDocumentCollection>
  documents?: Maybe<Documents>
  documentsCollection?: Maybe<DocumentsCollection>
  pageFaq?: Maybe<PageFaq>
  pageFaqCollection?: Maybe<PageFaqCollection>
  pageImage?: Maybe<PageImage>
  pageImageCollection?: Maybe<PageImageCollection>
  helpCarouselConfig?: Maybe<HelpCarouselConfig>
  helpCarouselConfigCollection?: Maybe<HelpCarouselConfigCollection>
  imageWithBulletsSection?: Maybe<ImageWithBulletsSection>
  imageWithBulletsSectionCollection?: Maybe<ImageWithBulletsSectionCollection>
  paragraphWithCaption?: Maybe<ParagraphWithCaption>
  paragraphWithCaptionCollection?: Maybe<ParagraphWithCaptionCollection>
  mentionItem?: Maybe<MentionItem>
  mentionItemCollection?: Maybe<MentionItemCollection>
  actionCard?: Maybe<ActionCard>
  actionCardCollection?: Maybe<ActionCardCollection>
  landingPageSection?: Maybe<LandingPageSection>
  landingPageSectionCollection?: Maybe<LandingPageSectionCollection>
  offerPerk?: Maybe<OfferPerk>
  offerPerkCollection?: Maybe<OfferPerkCollection>
  apiDocumentation?: Maybe<ApiDocumentation>
  apiDocumentationCollection?: Maybe<ApiDocumentationCollection>
  landingPageSectionExtraParagraph?: Maybe<LandingPageSectionExtraParagraph>
  landingPageSectionExtraParagraphCollection?: Maybe<LandingPageSectionExtraParagraphCollection>
  steppedTutorial?: Maybe<SteppedTutorial>
  steppedTutorialCollection?: Maybe<SteppedTutorialCollection>
  answeredQuestion?: Maybe<AnsweredQuestion>
  answeredQuestionCollection?: Maybe<AnsweredQuestionCollection>
  mediaMentions?: Maybe<MediaMentions>
  mediaMentionsCollection?: Maybe<MediaMentionsCollection>
  category?: Maybe<Category>
  categoryCollection?: Maybe<CategoryCollection>
  article?: Maybe<Article>
  articleCollection?: Maybe<ArticleCollection>
  partnerLanding?: Maybe<PartnerLanding>
  partnerLandingCollection?: Maybe<PartnerLandingCollection>
  partnerBenefit?: Maybe<PartnerBenefit>
  partnerBenefitCollection?: Maybe<PartnerBenefitCollection>
  digitalLegacy?: Maybe<DigitalLegacy>
  digitalLegacyCollection?: Maybe<DigitalLegacyCollection>
  digitalLegacyAuthor?: Maybe<DigitalLegacyAuthor>
  digitalLegacyAuthorCollection?: Maybe<DigitalLegacyAuthorCollection>
  digitalLegacyTopic?: Maybe<DigitalLegacyTopic>
  digitalLegacyTopicCollection?: Maybe<DigitalLegacyTopicCollection>
  todo?: Maybe<Todo>
  todoCollection?: Maybe<TodoCollection>
  animatedPhoto?: Maybe<AnimatedPhoto>
  animatedPhotoCollection?: Maybe<AnimatedPhotoCollection>
  futureMessage?: Maybe<FutureMessage>
  futureMessageCollection?: Maybe<FutureMessageCollection>
  articlesSetup?: Maybe<ArticlesSetup>
  articlesSetupCollection?: Maybe<ArticlesSetupCollection>
  articleMenuNode?: Maybe<ArticleMenuNode>
  articleMenuNodeCollection?: Maybe<ArticleMenuNodeCollection>
  articleMenuItem?: Maybe<ArticleMenuItem>
  articleMenuItemCollection?: Maybe<ArticleMenuItemCollection>
  singingPortrait?: Maybe<SingingPortrait>
  singingPortraitCollection?: Maybe<SingingPortraitCollection>
  singingPortraitSong?: Maybe<SingingPortraitSong>
  singingPortraitSongCollection?: Maybe<SingingPortraitSongCollection>
  person?: Maybe<Person>
  personCollection?: Maybe<PersonCollection>
  entryCollection?: Maybe<EntryCollection>
  _node?: Maybe<_Node>
}

export type QueryAssetArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryAssetCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<AssetFilter>
  order?: Maybe<Array<Maybe<AssetOrder>>>
}

export type QueryPageArticlesArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryPageArticlesCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<PageArticlesFilter>
  order?: Maybe<Array<Maybe<PageArticlesOrder>>>
}

export type QueryPageArticleArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryPageArticleCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<PageArticleFilter>
  order?: Maybe<Array<Maybe<PageArticleOrder>>>
}

export type QueryPageFileArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryPageFileCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<PageFileFilter>
  order?: Maybe<Array<Maybe<PageFileOrder>>>
}

export type QueryHomePageSetupArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryHomePageSetupCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<HomePageSetupFilter>
  order?: Maybe<Array<Maybe<HomePageSetupOrder>>>
}

export type QueryMediaMentionListArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryMediaMentionListCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<MediaMentionListFilter>
  order?: Maybe<Array<Maybe<MediaMentionListOrder>>>
}

export type QueryAboutUsPageArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryAboutUsPageCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<AboutUsPageFilter>
  order?: Maybe<Array<Maybe<AboutUsPageOrder>>>
}

export type QueryMemorySetupArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryMemorySetupCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<MemorySetupFilter>
  order?: Maybe<Array<Maybe<MemorySetupOrder>>>
}

export type QueryTipArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryTipCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<TipFilter>
  order?: Maybe<Array<Maybe<TipOrder>>>
}

export type QueryStoryPortraitCategoryArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryStoryPortraitCategoryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<StoryPortraitCategoryFilter>
  order?: Maybe<Array<Maybe<StoryPortraitCategoryOrder>>>
}

export type QueryStoryPortraitPhraseArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryStoryPortraitPhraseCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<StoryPortraitPhraseFilter>
  order?: Maybe<Array<Maybe<StoryPortraitPhraseOrder>>>
}

export type QueryPageVideoArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryPageVideoCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<PageVideoFilter>
  order?: Maybe<Array<Maybe<PageVideoOrder>>>
}

export type QueryPageDocumentArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryPageDocumentCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<PageDocumentFilter>
  order?: Maybe<Array<Maybe<PageDocumentOrder>>>
}

export type QueryDocumentsArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryDocumentsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<DocumentsFilter>
  order?: Maybe<Array<Maybe<DocumentsOrder>>>
}

export type QueryPageFaqArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryPageFaqCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<PageFaqFilter>
  order?: Maybe<Array<Maybe<PageFaqOrder>>>
}

export type QueryPageImageArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryPageImageCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<PageImageFilter>
  order?: Maybe<Array<Maybe<PageImageOrder>>>
}

export type QueryHelpCarouselConfigArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryHelpCarouselConfigCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<HelpCarouselConfigFilter>
  order?: Maybe<Array<Maybe<HelpCarouselConfigOrder>>>
}

export type QueryImageWithBulletsSectionArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryImageWithBulletsSectionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<ImageWithBulletsSectionFilter>
  order?: Maybe<Array<Maybe<ImageWithBulletsSectionOrder>>>
}

export type QueryParagraphWithCaptionArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryParagraphWithCaptionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<ParagraphWithCaptionFilter>
  order?: Maybe<Array<Maybe<ParagraphWithCaptionOrder>>>
}

export type QueryMentionItemArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryMentionItemCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<MentionItemFilter>
  order?: Maybe<Array<Maybe<MentionItemOrder>>>
}

export type QueryActionCardArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryActionCardCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<ActionCardFilter>
  order?: Maybe<Array<Maybe<ActionCardOrder>>>
}

export type QueryLandingPageSectionArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryLandingPageSectionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<LandingPageSectionFilter>
  order?: Maybe<Array<Maybe<LandingPageSectionOrder>>>
}

export type QueryOfferPerkArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryOfferPerkCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<OfferPerkFilter>
  order?: Maybe<Array<Maybe<OfferPerkOrder>>>
}

export type QueryApiDocumentationArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryApiDocumentationCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<ApiDocumentationFilter>
  order?: Maybe<Array<Maybe<ApiDocumentationOrder>>>
}

export type QueryLandingPageSectionExtraParagraphArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryLandingPageSectionExtraParagraphCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<LandingPageSectionExtraParagraphFilter>
  order?: Maybe<Array<Maybe<LandingPageSectionExtraParagraphOrder>>>
}

export type QuerySteppedTutorialArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QuerySteppedTutorialCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<SteppedTutorialFilter>
  order?: Maybe<Array<Maybe<SteppedTutorialOrder>>>
}

export type QueryAnsweredQuestionArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryAnsweredQuestionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<AnsweredQuestionFilter>
  order?: Maybe<Array<Maybe<AnsweredQuestionOrder>>>
}

export type QueryMediaMentionsArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryMediaMentionsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<MediaMentionsFilter>
  order?: Maybe<Array<Maybe<MediaMentionsOrder>>>
}

export type QueryCategoryArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryCategoryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<CategoryFilter>
  order?: Maybe<Array<Maybe<CategoryOrder>>>
}

export type QueryArticleArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryArticleCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<ArticleFilter>
  order?: Maybe<Array<Maybe<ArticleOrder>>>
}

export type QueryPartnerLandingArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryPartnerLandingCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<PartnerLandingFilter>
  order?: Maybe<Array<Maybe<PartnerLandingOrder>>>
}

export type QueryPartnerBenefitArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryPartnerBenefitCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<PartnerBenefitFilter>
  order?: Maybe<Array<Maybe<PartnerBenefitOrder>>>
}

export type QueryDigitalLegacyArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryDigitalLegacyCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<DigitalLegacyFilter>
  order?: Maybe<Array<Maybe<DigitalLegacyOrder>>>
}

export type QueryDigitalLegacyAuthorArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryDigitalLegacyAuthorCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<DigitalLegacyAuthorFilter>
  order?: Maybe<Array<Maybe<DigitalLegacyAuthorOrder>>>
}

export type QueryDigitalLegacyTopicArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryDigitalLegacyTopicCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<DigitalLegacyTopicFilter>
  order?: Maybe<Array<Maybe<DigitalLegacyTopicOrder>>>
}

export type QueryTodoArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryTodoCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<TodoFilter>
  order?: Maybe<Array<Maybe<TodoOrder>>>
}

export type QueryAnimatedPhotoArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryAnimatedPhotoCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<AnimatedPhotoFilter>
  order?: Maybe<Array<Maybe<AnimatedPhotoOrder>>>
}

export type QueryFutureMessageArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryFutureMessageCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<FutureMessageFilter>
  order?: Maybe<Array<Maybe<FutureMessageOrder>>>
}

export type QueryArticlesSetupArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryArticlesSetupCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<ArticlesSetupFilter>
  order?: Maybe<Array<Maybe<ArticlesSetupOrder>>>
}

export type QueryArticleMenuNodeArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryArticleMenuNodeCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<ArticleMenuNodeFilter>
  order?: Maybe<Array<Maybe<ArticleMenuNodeOrder>>>
}

export type QueryArticleMenuItemArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryArticleMenuItemCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<ArticleMenuItemFilter>
  order?: Maybe<Array<Maybe<ArticleMenuItemOrder>>>
}

export type QuerySingingPortraitArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QuerySingingPortraitCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<SingingPortraitFilter>
  order?: Maybe<Array<Maybe<SingingPortraitOrder>>>
}

export type QuerySingingPortraitSongArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QuerySingingPortraitSongCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<SingingPortraitSongFilter>
  order?: Maybe<Array<Maybe<SingingPortraitSongOrder>>>
}

export type QueryPersonArgs = {
  id: Scalars['String']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type QueryPersonCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<PersonFilter>
  order?: Maybe<Array<Maybe<PersonOrder>>>
}

export type QueryEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  where?: Maybe<EntryFilter>
  order?: Maybe<Array<Maybe<EntryOrder>>>
}

export type Query_NodeArgs = {
  id: Scalars['ID']
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type ResourceLink = {
  sys: ResourceSys
}

export type ResourceSys = {
  __typename?: 'ResourceSys'
  urn: Scalars['String']
  linkType: Scalars['String']
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/singingPortrait) */
export type SingingPortrait = Entry & {
  __typename?: 'SingingPortrait'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<SingingPortraitLinkingCollections>
  video?: Maybe<Asset>
  songName?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/singingPortrait) */
export type SingingPortraitLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/singingPortrait) */
export type SingingPortraitVideoArgs = {
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/singingPortrait) */
export type SingingPortraitSongNameArgs = {
  locale?: Maybe<Scalars['String']>
}

export type SingingPortraitCollection = {
  __typename?: 'SingingPortraitCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<SingingPortrait>>
}

export type SingingPortraitFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  video_exists?: Maybe<Scalars['Boolean']>
  songName_exists?: Maybe<Scalars['Boolean']>
  songName?: Maybe<Scalars['String']>
  songName_not?: Maybe<Scalars['String']>
  songName_in?: Maybe<Array<Maybe<Scalars['String']>>>
  songName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  songName_contains?: Maybe<Scalars['String']>
  songName_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<SingingPortraitFilter>>>
  AND?: Maybe<Array<Maybe<SingingPortraitFilter>>>
}

export type SingingPortraitLinkingCollections = {
  __typename?: 'SingingPortraitLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  memorySetupCollection?: Maybe<MemorySetupCollection>
}

export type SingingPortraitLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type SingingPortraitLinkingCollectionsMemorySetupCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  order?: Maybe<Array<Maybe<SingingPortraitLinkingCollectionsMemorySetupCollectionOrder>>>
}

export enum SingingPortraitLinkingCollectionsMemorySetupCollectionOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SingingPortraitOrder {
  SongNameAsc = 'songName_ASC',
  SongNameDesc = 'songName_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/singingPortraitSong) */
export type SingingPortraitSong = Entry & {
  __typename?: 'SingingPortraitSong'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<SingingPortraitSongLinkingCollections>
  song?: Maybe<Asset>
  image?: Maybe<Asset>
  songName?: Maybe<Scalars['String']>
  artist?: Maybe<Scalars['String']>
  driverId?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/singingPortraitSong) */
export type SingingPortraitSongLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/singingPortraitSong) */
export type SingingPortraitSongSongArgs = {
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/singingPortraitSong) */
export type SingingPortraitSongImageArgs = {
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/singingPortraitSong) */
export type SingingPortraitSongSongNameArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/singingPortraitSong) */
export type SingingPortraitSongArtistArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/singingPortraitSong) */
export type SingingPortraitSongDriverIdArgs = {
  locale?: Maybe<Scalars['String']>
}

export type SingingPortraitSongCollection = {
  __typename?: 'SingingPortraitSongCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<SingingPortraitSong>>
}

export type SingingPortraitSongFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  song_exists?: Maybe<Scalars['Boolean']>
  image_exists?: Maybe<Scalars['Boolean']>
  songName_exists?: Maybe<Scalars['Boolean']>
  songName?: Maybe<Scalars['String']>
  songName_not?: Maybe<Scalars['String']>
  songName_in?: Maybe<Array<Maybe<Scalars['String']>>>
  songName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  songName_contains?: Maybe<Scalars['String']>
  songName_not_contains?: Maybe<Scalars['String']>
  artist_exists?: Maybe<Scalars['Boolean']>
  artist?: Maybe<Scalars['String']>
  artist_not?: Maybe<Scalars['String']>
  artist_in?: Maybe<Array<Maybe<Scalars['String']>>>
  artist_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  artist_contains?: Maybe<Scalars['String']>
  artist_not_contains?: Maybe<Scalars['String']>
  driverId_exists?: Maybe<Scalars['Boolean']>
  driverId?: Maybe<Scalars['String']>
  driverId_not?: Maybe<Scalars['String']>
  driverId_in?: Maybe<Array<Maybe<Scalars['String']>>>
  driverId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  driverId_contains?: Maybe<Scalars['String']>
  driverId_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<SingingPortraitSongFilter>>>
  AND?: Maybe<Array<Maybe<SingingPortraitSongFilter>>>
}

export type SingingPortraitSongLinkingCollections = {
  __typename?: 'SingingPortraitSongLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  memorySetupCollection?: Maybe<MemorySetupCollection>
}

export type SingingPortraitSongLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type SingingPortraitSongLinkingCollectionsMemorySetupCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  order?: Maybe<Array<Maybe<SingingPortraitSongLinkingCollectionsMemorySetupCollectionOrder>>>
}

export enum SingingPortraitSongLinkingCollectionsMemorySetupCollectionOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SingingPortraitSongOrder {
  SongNameAsc = 'songName_ASC',
  SongNameDesc = 'songName_DESC',
  ArtistAsc = 'artist_ASC',
  ArtistDesc = 'artist_DESC',
  DriverIdAsc = 'driverId_ASC',
  DriverIdDesc = 'driverId_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** A 3-step tutorial for landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/steppedTutorial) */
export type SteppedTutorial = Entry & {
  __typename?: 'SteppedTutorial'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<SteppedTutorialLinkingCollections>
  id?: Maybe<Scalars['String']>
  tutorialTitle?: Maybe<Scalars['String']>
  buttonLabel?: Maybe<Scalars['String']>
  title1?: Maybe<Scalars['String']>
  description1?: Maybe<SteppedTutorialDescription1>
  image1?: Maybe<Asset>
  iconName1?: Maybe<Scalars['String']>
  title2?: Maybe<Scalars['String']>
  description2?: Maybe<SteppedTutorialDescription2>
  image2?: Maybe<Asset>
  iconName2?: Maybe<Scalars['String']>
  title3?: Maybe<Scalars['String']>
  description3?: Maybe<SteppedTutorialDescription3>
  image3?: Maybe<Asset>
  iconName3?: Maybe<Scalars['String']>
  isIcon?: Maybe<Scalars['Boolean']>
}

/** A 3-step tutorial for landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/steppedTutorial) */
export type SteppedTutorialLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** A 3-step tutorial for landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/steppedTutorial) */
export type SteppedTutorialIdArgs = {
  locale?: Maybe<Scalars['String']>
}

/** A 3-step tutorial for landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/steppedTutorial) */
export type SteppedTutorialTutorialTitleArgs = {
  locale?: Maybe<Scalars['String']>
}

/** A 3-step tutorial for landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/steppedTutorial) */
export type SteppedTutorialButtonLabelArgs = {
  locale?: Maybe<Scalars['String']>
}

/** A 3-step tutorial for landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/steppedTutorial) */
export type SteppedTutorialTitle1Args = {
  locale?: Maybe<Scalars['String']>
}

/** A 3-step tutorial for landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/steppedTutorial) */
export type SteppedTutorialDescription1Args = {
  locale?: Maybe<Scalars['String']>
}

/** A 3-step tutorial for landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/steppedTutorial) */
export type SteppedTutorialImage1Args = {
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

/** A 3-step tutorial for landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/steppedTutorial) */
export type SteppedTutorialIconName1Args = {
  locale?: Maybe<Scalars['String']>
}

/** A 3-step tutorial for landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/steppedTutorial) */
export type SteppedTutorialTitle2Args = {
  locale?: Maybe<Scalars['String']>
}

/** A 3-step tutorial for landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/steppedTutorial) */
export type SteppedTutorialDescription2Args = {
  locale?: Maybe<Scalars['String']>
}

/** A 3-step tutorial for landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/steppedTutorial) */
export type SteppedTutorialImage2Args = {
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

/** A 3-step tutorial for landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/steppedTutorial) */
export type SteppedTutorialIconName2Args = {
  locale?: Maybe<Scalars['String']>
}

/** A 3-step tutorial for landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/steppedTutorial) */
export type SteppedTutorialTitle3Args = {
  locale?: Maybe<Scalars['String']>
}

/** A 3-step tutorial for landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/steppedTutorial) */
export type SteppedTutorialDescription3Args = {
  locale?: Maybe<Scalars['String']>
}

/** A 3-step tutorial for landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/steppedTutorial) */
export type SteppedTutorialImage3Args = {
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

/** A 3-step tutorial for landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/steppedTutorial) */
export type SteppedTutorialIconName3Args = {
  locale?: Maybe<Scalars['String']>
}

/** A 3-step tutorial for landing pages. [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/steppedTutorial) */
export type SteppedTutorialIsIconArgs = {
  locale?: Maybe<Scalars['String']>
}

export type SteppedTutorialCollection = {
  __typename?: 'SteppedTutorialCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<SteppedTutorial>>
}

export type SteppedTutorialDescription1 = {
  __typename?: 'SteppedTutorialDescription1'
  json: Scalars['JSON']
  links: SteppedTutorialDescription1Links
}

export type SteppedTutorialDescription1Assets = {
  __typename?: 'SteppedTutorialDescription1Assets'
  hyperlink: Array<Maybe<Asset>>
  block: Array<Maybe<Asset>>
}

export type SteppedTutorialDescription1Entries = {
  __typename?: 'SteppedTutorialDescription1Entries'
  inline: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  block: Array<Maybe<Entry>>
}

export type SteppedTutorialDescription1Links = {
  __typename?: 'SteppedTutorialDescription1Links'
  entries: SteppedTutorialDescription1Entries
  assets: SteppedTutorialDescription1Assets
  resources: SteppedTutorialDescription1Resources
}

export type SteppedTutorialDescription1Resources = {
  __typename?: 'SteppedTutorialDescription1Resources'
  block: Array<SteppedTutorialDescription1ResourcesBlock>
  inline: Array<SteppedTutorialDescription1ResourcesInline>
  hyperlink: Array<SteppedTutorialDescription1ResourcesHyperlink>
}

export type SteppedTutorialDescription1ResourcesBlock = ResourceLink & {
  __typename?: 'SteppedTutorialDescription1ResourcesBlock'
  sys: ResourceSys
}

export type SteppedTutorialDescription1ResourcesHyperlink = ResourceLink & {
  __typename?: 'SteppedTutorialDescription1ResourcesHyperlink'
  sys: ResourceSys
}

export type SteppedTutorialDescription1ResourcesInline = ResourceLink & {
  __typename?: 'SteppedTutorialDescription1ResourcesInline'
  sys: ResourceSys
}

export type SteppedTutorialDescription2 = {
  __typename?: 'SteppedTutorialDescription2'
  json: Scalars['JSON']
  links: SteppedTutorialDescription2Links
}

export type SteppedTutorialDescription2Assets = {
  __typename?: 'SteppedTutorialDescription2Assets'
  hyperlink: Array<Maybe<Asset>>
  block: Array<Maybe<Asset>>
}

export type SteppedTutorialDescription2Entries = {
  __typename?: 'SteppedTutorialDescription2Entries'
  inline: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  block: Array<Maybe<Entry>>
}

export type SteppedTutorialDescription2Links = {
  __typename?: 'SteppedTutorialDescription2Links'
  entries: SteppedTutorialDescription2Entries
  assets: SteppedTutorialDescription2Assets
  resources: SteppedTutorialDescription2Resources
}

export type SteppedTutorialDescription2Resources = {
  __typename?: 'SteppedTutorialDescription2Resources'
  block: Array<SteppedTutorialDescription2ResourcesBlock>
  inline: Array<SteppedTutorialDescription2ResourcesInline>
  hyperlink: Array<SteppedTutorialDescription2ResourcesHyperlink>
}

export type SteppedTutorialDescription2ResourcesBlock = ResourceLink & {
  __typename?: 'SteppedTutorialDescription2ResourcesBlock'
  sys: ResourceSys
}

export type SteppedTutorialDescription2ResourcesHyperlink = ResourceLink & {
  __typename?: 'SteppedTutorialDescription2ResourcesHyperlink'
  sys: ResourceSys
}

export type SteppedTutorialDescription2ResourcesInline = ResourceLink & {
  __typename?: 'SteppedTutorialDescription2ResourcesInline'
  sys: ResourceSys
}

export type SteppedTutorialDescription3 = {
  __typename?: 'SteppedTutorialDescription3'
  json: Scalars['JSON']
  links: SteppedTutorialDescription3Links
}

export type SteppedTutorialDescription3Assets = {
  __typename?: 'SteppedTutorialDescription3Assets'
  hyperlink: Array<Maybe<Asset>>
  block: Array<Maybe<Asset>>
}

export type SteppedTutorialDescription3Entries = {
  __typename?: 'SteppedTutorialDescription3Entries'
  inline: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  block: Array<Maybe<Entry>>
}

export type SteppedTutorialDescription3Links = {
  __typename?: 'SteppedTutorialDescription3Links'
  entries: SteppedTutorialDescription3Entries
  assets: SteppedTutorialDescription3Assets
  resources: SteppedTutorialDescription3Resources
}

export type SteppedTutorialDescription3Resources = {
  __typename?: 'SteppedTutorialDescription3Resources'
  block: Array<SteppedTutorialDescription3ResourcesBlock>
  inline: Array<SteppedTutorialDescription3ResourcesInline>
  hyperlink: Array<SteppedTutorialDescription3ResourcesHyperlink>
}

export type SteppedTutorialDescription3ResourcesBlock = ResourceLink & {
  __typename?: 'SteppedTutorialDescription3ResourcesBlock'
  sys: ResourceSys
}

export type SteppedTutorialDescription3ResourcesHyperlink = ResourceLink & {
  __typename?: 'SteppedTutorialDescription3ResourcesHyperlink'
  sys: ResourceSys
}

export type SteppedTutorialDescription3ResourcesInline = ResourceLink & {
  __typename?: 'SteppedTutorialDescription3ResourcesInline'
  sys: ResourceSys
}

export type SteppedTutorialFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  id_exists?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['String']>
  id_not?: Maybe<Scalars['String']>
  id_in?: Maybe<Array<Maybe<Scalars['String']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  id_contains?: Maybe<Scalars['String']>
  id_not_contains?: Maybe<Scalars['String']>
  tutorialTitle_exists?: Maybe<Scalars['Boolean']>
  tutorialTitle?: Maybe<Scalars['String']>
  tutorialTitle_not?: Maybe<Scalars['String']>
  tutorialTitle_in?: Maybe<Array<Maybe<Scalars['String']>>>
  tutorialTitle_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  tutorialTitle_contains?: Maybe<Scalars['String']>
  tutorialTitle_not_contains?: Maybe<Scalars['String']>
  buttonLabel_exists?: Maybe<Scalars['Boolean']>
  buttonLabel?: Maybe<Scalars['String']>
  buttonLabel_not?: Maybe<Scalars['String']>
  buttonLabel_in?: Maybe<Array<Maybe<Scalars['String']>>>
  buttonLabel_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  buttonLabel_contains?: Maybe<Scalars['String']>
  buttonLabel_not_contains?: Maybe<Scalars['String']>
  title1_exists?: Maybe<Scalars['Boolean']>
  title1?: Maybe<Scalars['String']>
  title1_not?: Maybe<Scalars['String']>
  title1_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title1_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title1_contains?: Maybe<Scalars['String']>
  title1_not_contains?: Maybe<Scalars['String']>
  description1_exists?: Maybe<Scalars['Boolean']>
  description1_contains?: Maybe<Scalars['String']>
  description1_not_contains?: Maybe<Scalars['String']>
  image1_exists?: Maybe<Scalars['Boolean']>
  iconName1_exists?: Maybe<Scalars['Boolean']>
  iconName1?: Maybe<Scalars['String']>
  iconName1_not?: Maybe<Scalars['String']>
  iconName1_in?: Maybe<Array<Maybe<Scalars['String']>>>
  iconName1_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  iconName1_contains?: Maybe<Scalars['String']>
  iconName1_not_contains?: Maybe<Scalars['String']>
  title2_exists?: Maybe<Scalars['Boolean']>
  title2?: Maybe<Scalars['String']>
  title2_not?: Maybe<Scalars['String']>
  title2_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title2_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title2_contains?: Maybe<Scalars['String']>
  title2_not_contains?: Maybe<Scalars['String']>
  description2_exists?: Maybe<Scalars['Boolean']>
  description2_contains?: Maybe<Scalars['String']>
  description2_not_contains?: Maybe<Scalars['String']>
  image2_exists?: Maybe<Scalars['Boolean']>
  iconName2_exists?: Maybe<Scalars['Boolean']>
  iconName2?: Maybe<Scalars['String']>
  iconName2_not?: Maybe<Scalars['String']>
  iconName2_in?: Maybe<Array<Maybe<Scalars['String']>>>
  iconName2_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  iconName2_contains?: Maybe<Scalars['String']>
  iconName2_not_contains?: Maybe<Scalars['String']>
  title3_exists?: Maybe<Scalars['Boolean']>
  title3?: Maybe<Scalars['String']>
  title3_not?: Maybe<Scalars['String']>
  title3_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title3_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title3_contains?: Maybe<Scalars['String']>
  title3_not_contains?: Maybe<Scalars['String']>
  description3_exists?: Maybe<Scalars['Boolean']>
  description3_contains?: Maybe<Scalars['String']>
  description3_not_contains?: Maybe<Scalars['String']>
  image3_exists?: Maybe<Scalars['Boolean']>
  iconName3_exists?: Maybe<Scalars['Boolean']>
  iconName3?: Maybe<Scalars['String']>
  iconName3_not?: Maybe<Scalars['String']>
  iconName3_in?: Maybe<Array<Maybe<Scalars['String']>>>
  iconName3_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  iconName3_contains?: Maybe<Scalars['String']>
  iconName3_not_contains?: Maybe<Scalars['String']>
  isIcon_exists?: Maybe<Scalars['Boolean']>
  isIcon?: Maybe<Scalars['Boolean']>
  isIcon_not?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<SteppedTutorialFilter>>>
  AND?: Maybe<Array<Maybe<SteppedTutorialFilter>>>
}

export type SteppedTutorialLinkingCollections = {
  __typename?: 'SteppedTutorialLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type SteppedTutorialLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export enum SteppedTutorialOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TutorialTitleAsc = 'tutorialTitle_ASC',
  TutorialTitleDesc = 'tutorialTitle_DESC',
  ButtonLabelAsc = 'buttonLabel_ASC',
  ButtonLabelDesc = 'buttonLabel_DESC',
  Title1Asc = 'title1_ASC',
  Title1Desc = 'title1_DESC',
  IconName1Asc = 'iconName1_ASC',
  IconName1Desc = 'iconName1_DESC',
  Title2Asc = 'title2_ASC',
  Title2Desc = 'title2_DESC',
  IconName2Asc = 'iconName2_ASC',
  IconName2Desc = 'iconName2_DESC',
  Title3Asc = 'title3_ASC',
  Title3Desc = 'title3_DESC',
  IconName3Asc = 'iconName3_ASC',
  IconName3Desc = 'iconName3_DESC',
  IsIconAsc = 'isIcon_ASC',
  IsIconDesc = 'isIcon_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/storyPortraitCategory) */
export type StoryPortraitCategory = Entry & {
  __typename?: 'StoryPortraitCategory'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<StoryPortraitCategoryLinkingCollections>
  title?: Maybe<Scalars['String']>
  description?: Maybe<StoryPortraitCategoryDescription>
  picturesCollection?: Maybe<AssetCollection>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/storyPortraitCategory) */
export type StoryPortraitCategoryLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/storyPortraitCategory) */
export type StoryPortraitCategoryTitleArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/storyPortraitCategory) */
export type StoryPortraitCategoryDescriptionArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/storyPortraitCategory) */
export type StoryPortraitCategoryPicturesCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type StoryPortraitCategoryCollection = {
  __typename?: 'StoryPortraitCategoryCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<StoryPortraitCategory>>
}

export type StoryPortraitCategoryDescription = {
  __typename?: 'StoryPortraitCategoryDescription'
  json: Scalars['JSON']
  links: StoryPortraitCategoryDescriptionLinks
}

export type StoryPortraitCategoryDescriptionAssets = {
  __typename?: 'StoryPortraitCategoryDescriptionAssets'
  hyperlink: Array<Maybe<Asset>>
  block: Array<Maybe<Asset>>
}

export type StoryPortraitCategoryDescriptionEntries = {
  __typename?: 'StoryPortraitCategoryDescriptionEntries'
  inline: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  block: Array<Maybe<Entry>>
}

export type StoryPortraitCategoryDescriptionLinks = {
  __typename?: 'StoryPortraitCategoryDescriptionLinks'
  entries: StoryPortraitCategoryDescriptionEntries
  assets: StoryPortraitCategoryDescriptionAssets
  resources: StoryPortraitCategoryDescriptionResources
}

export type StoryPortraitCategoryDescriptionResources = {
  __typename?: 'StoryPortraitCategoryDescriptionResources'
  block: Array<StoryPortraitCategoryDescriptionResourcesBlock>
  inline: Array<StoryPortraitCategoryDescriptionResourcesInline>
  hyperlink: Array<StoryPortraitCategoryDescriptionResourcesHyperlink>
}

export type StoryPortraitCategoryDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'StoryPortraitCategoryDescriptionResourcesBlock'
  sys: ResourceSys
}

export type StoryPortraitCategoryDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'StoryPortraitCategoryDescriptionResourcesHyperlink'
  sys: ResourceSys
}

export type StoryPortraitCategoryDescriptionResourcesInline = ResourceLink & {
  __typename?: 'StoryPortraitCategoryDescriptionResourcesInline'
  sys: ResourceSys
}

export type StoryPortraitCategoryFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  title_exists?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  title_not?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_contains?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  description_exists?: Maybe<Scalars['Boolean']>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  picturesCollection_exists?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<StoryPortraitCategoryFilter>>>
  AND?: Maybe<Array<Maybe<StoryPortraitCategoryFilter>>>
}

export type StoryPortraitCategoryLinkingCollections = {
  __typename?: 'StoryPortraitCategoryLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  memorySetupCollection?: Maybe<MemorySetupCollection>
}

export type StoryPortraitCategoryLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type StoryPortraitCategoryLinkingCollectionsMemorySetupCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  order?: Maybe<Array<Maybe<StoryPortraitCategoryLinkingCollectionsMemorySetupCollectionOrder>>>
}

export enum StoryPortraitCategoryLinkingCollectionsMemorySetupCollectionOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum StoryPortraitCategoryOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/storyPortraitPhrase) */
export type StoryPortraitPhrase = Entry & {
  __typename?: 'StoryPortraitPhrase'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<StoryPortraitPhraseLinkingCollections>
  phrase?: Maybe<Scalars['String']>
  audio?: Maybe<Asset>
  picture?: Maybe<Asset>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/storyPortraitPhrase) */
export type StoryPortraitPhraseLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/storyPortraitPhrase) */
export type StoryPortraitPhrasePhraseArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/storyPortraitPhrase) */
export type StoryPortraitPhraseAudioArgs = {
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/storyPortraitPhrase) */
export type StoryPortraitPhrasePictureArgs = {
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type StoryPortraitPhraseCollection = {
  __typename?: 'StoryPortraitPhraseCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<StoryPortraitPhrase>>
}

export type StoryPortraitPhraseFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  phrase_exists?: Maybe<Scalars['Boolean']>
  phrase?: Maybe<Scalars['String']>
  phrase_not?: Maybe<Scalars['String']>
  phrase_in?: Maybe<Array<Maybe<Scalars['String']>>>
  phrase_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  phrase_contains?: Maybe<Scalars['String']>
  phrase_not_contains?: Maybe<Scalars['String']>
  audio_exists?: Maybe<Scalars['Boolean']>
  picture_exists?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<StoryPortraitPhraseFilter>>>
  AND?: Maybe<Array<Maybe<StoryPortraitPhraseFilter>>>
}

export type StoryPortraitPhraseLinkingCollections = {
  __typename?: 'StoryPortraitPhraseLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  memorySetupCollection?: Maybe<MemorySetupCollection>
}

export type StoryPortraitPhraseLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type StoryPortraitPhraseLinkingCollectionsMemorySetupCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  order?: Maybe<Array<Maybe<StoryPortraitPhraseLinkingCollectionsMemorySetupCollectionOrder>>>
}

export enum StoryPortraitPhraseLinkingCollectionsMemorySetupCollectionOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum StoryPortraitPhraseOrder {
  PhraseAsc = 'phrase_ASC',
  PhraseDesc = 'phrase_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type Sys = {
  __typename?: 'Sys'
  id: Scalars['String']
  spaceId: Scalars['String']
  environmentId: Scalars['String']
  publishedAt?: Maybe<Scalars['DateTime']>
  firstPublishedAt?: Maybe<Scalars['DateTime']>
  publishedVersion?: Maybe<Scalars['Int']>
}

export type SysFilter = {
  id_exists?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['String']>
  id_not?: Maybe<Scalars['String']>
  id_in?: Maybe<Array<Maybe<Scalars['String']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  id_contains?: Maybe<Scalars['String']>
  id_not_contains?: Maybe<Scalars['String']>
  publishedAt_exists?: Maybe<Scalars['Boolean']>
  publishedAt?: Maybe<Scalars['DateTime']>
  publishedAt_not?: Maybe<Scalars['DateTime']>
  publishedAt_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  publishedAt_gt?: Maybe<Scalars['DateTime']>
  publishedAt_gte?: Maybe<Scalars['DateTime']>
  publishedAt_lt?: Maybe<Scalars['DateTime']>
  publishedAt_lte?: Maybe<Scalars['DateTime']>
  firstPublishedAt_exists?: Maybe<Scalars['Boolean']>
  firstPublishedAt?: Maybe<Scalars['DateTime']>
  firstPublishedAt_not?: Maybe<Scalars['DateTime']>
  firstPublishedAt_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  firstPublishedAt_not_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  firstPublishedAt_gt?: Maybe<Scalars['DateTime']>
  firstPublishedAt_gte?: Maybe<Scalars['DateTime']>
  firstPublishedAt_lt?: Maybe<Scalars['DateTime']>
  firstPublishedAt_lte?: Maybe<Scalars['DateTime']>
  publishedVersion_exists?: Maybe<Scalars['Boolean']>
  publishedVersion?: Maybe<Scalars['Float']>
  publishedVersion_not?: Maybe<Scalars['Float']>
  publishedVersion_in?: Maybe<Array<Maybe<Scalars['Float']>>>
  publishedVersion_not_in?: Maybe<Array<Maybe<Scalars['Float']>>>
  publishedVersion_gt?: Maybe<Scalars['Float']>
  publishedVersion_gte?: Maybe<Scalars['Float']>
  publishedVersion_lt?: Maybe<Scalars['Float']>
  publishedVersion_lte?: Maybe<Scalars['Float']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/tip) */
export type Tip = Entry & {
  __typename?: 'Tip'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<TipLinkingCollections>
  title?: Maybe<Scalars['String']>
  description?: Maybe<TipDescription>
  icon?: Maybe<Asset>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/tip) */
export type TipLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/tip) */
export type TipTitleArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/tip) */
export type TipDescriptionArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/tip) */
export type TipIconArgs = {
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type TipCollection = {
  __typename?: 'TipCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<Tip>>
}

export type TipDescription = {
  __typename?: 'TipDescription'
  json: Scalars['JSON']
  links: TipDescriptionLinks
}

export type TipDescriptionAssets = {
  __typename?: 'TipDescriptionAssets'
  hyperlink: Array<Maybe<Asset>>
  block: Array<Maybe<Asset>>
}

export type TipDescriptionEntries = {
  __typename?: 'TipDescriptionEntries'
  inline: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  block: Array<Maybe<Entry>>
}

export type TipDescriptionLinks = {
  __typename?: 'TipDescriptionLinks'
  entries: TipDescriptionEntries
  assets: TipDescriptionAssets
  resources: TipDescriptionResources
}

export type TipDescriptionResources = {
  __typename?: 'TipDescriptionResources'
  block: Array<TipDescriptionResourcesBlock>
  inline: Array<TipDescriptionResourcesInline>
  hyperlink: Array<TipDescriptionResourcesHyperlink>
}

export type TipDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'TipDescriptionResourcesBlock'
  sys: ResourceSys
}

export type TipDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'TipDescriptionResourcesHyperlink'
  sys: ResourceSys
}

export type TipDescriptionResourcesInline = ResourceLink & {
  __typename?: 'TipDescriptionResourcesInline'
  sys: ResourceSys
}

export type TipFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  title_exists?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  title_not?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_contains?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  description_exists?: Maybe<Scalars['Boolean']>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  icon_exists?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<TipFilter>>>
  AND?: Maybe<Array<Maybe<TipFilter>>>
}

export type TipLinkingCollections = {
  __typename?: 'TipLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  memorySetupCollection?: Maybe<MemorySetupCollection>
}

export type TipLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export type TipLinkingCollectionsMemorySetupCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
  order?: Maybe<Array<Maybe<TipLinkingCollectionsMemorySetupCollectionOrder>>>
}

export enum TipLinkingCollectionsMemorySetupCollectionOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TipOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/todo) */
export type Todo = Entry & {
  __typename?: 'Todo'
  sys: Sys
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<TodoLinkingCollections>
  type?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  desc?: Maybe<TodoDesc>
  icon?: Maybe<Asset>
  actionName?: Maybe<Scalars['String']>
  actionLink?: Maybe<Scalars['String']>
  duration?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/todo) */
export type TodoLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/todo) */
export type TodoTypeArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/todo) */
export type TodoTitleArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/todo) */
export type TodoDescArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/todo) */
export type TodoIconArgs = {
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/todo) */
export type TodoActionNameArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/todo) */
export type TodoActionLinkArgs = {
  locale?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/svb8ms78y99f/content_types/todo) */
export type TodoDurationArgs = {
  locale?: Maybe<Scalars['String']>
}

export type TodoCollection = {
  __typename?: 'TodoCollection'
  total: Scalars['Int']
  skip: Scalars['Int']
  limit: Scalars['Int']
  items: Array<Maybe<Todo>>
}

export type TodoDesc = {
  __typename?: 'TodoDesc'
  json: Scalars['JSON']
  links: TodoDescLinks
}

export type TodoDescAssets = {
  __typename?: 'TodoDescAssets'
  hyperlink: Array<Maybe<Asset>>
  block: Array<Maybe<Asset>>
}

export type TodoDescEntries = {
  __typename?: 'TodoDescEntries'
  inline: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  block: Array<Maybe<Entry>>
}

export type TodoDescLinks = {
  __typename?: 'TodoDescLinks'
  entries: TodoDescEntries
  assets: TodoDescAssets
  resources: TodoDescResources
}

export type TodoDescResources = {
  __typename?: 'TodoDescResources'
  block: Array<TodoDescResourcesBlock>
  inline: Array<TodoDescResourcesInline>
  hyperlink: Array<TodoDescResourcesHyperlink>
}

export type TodoDescResourcesBlock = ResourceLink & {
  __typename?: 'TodoDescResourcesBlock'
  sys: ResourceSys
}

export type TodoDescResourcesHyperlink = ResourceLink & {
  __typename?: 'TodoDescResourcesHyperlink'
  sys: ResourceSys
}

export type TodoDescResourcesInline = ResourceLink & {
  __typename?: 'TodoDescResourcesInline'
  sys: ResourceSys
}

export type TodoFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  type_exists?: Maybe<Scalars['Boolean']>
  type?: Maybe<Scalars['String']>
  type_not?: Maybe<Scalars['String']>
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  type_contains?: Maybe<Scalars['String']>
  type_not_contains?: Maybe<Scalars['String']>
  title_exists?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  title_not?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_contains?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  desc_exists?: Maybe<Scalars['Boolean']>
  desc_contains?: Maybe<Scalars['String']>
  desc_not_contains?: Maybe<Scalars['String']>
  icon_exists?: Maybe<Scalars['Boolean']>
  actionName_exists?: Maybe<Scalars['Boolean']>
  actionName?: Maybe<Scalars['String']>
  actionName_not?: Maybe<Scalars['String']>
  actionName_in?: Maybe<Array<Maybe<Scalars['String']>>>
  actionName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  actionName_contains?: Maybe<Scalars['String']>
  actionName_not_contains?: Maybe<Scalars['String']>
  actionLink_exists?: Maybe<Scalars['Boolean']>
  actionLink?: Maybe<Scalars['String']>
  actionLink_not?: Maybe<Scalars['String']>
  actionLink_in?: Maybe<Array<Maybe<Scalars['String']>>>
  actionLink_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  actionLink_contains?: Maybe<Scalars['String']>
  actionLink_not_contains?: Maybe<Scalars['String']>
  duration_exists?: Maybe<Scalars['Boolean']>
  duration?: Maybe<Scalars['String']>
  duration_not?: Maybe<Scalars['String']>
  duration_in?: Maybe<Array<Maybe<Scalars['String']>>>
  duration_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  duration_contains?: Maybe<Scalars['String']>
  duration_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<TodoFilter>>>
  AND?: Maybe<Array<Maybe<TodoFilter>>>
}

export type TodoLinkingCollections = {
  __typename?: 'TodoLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TodoLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  preview?: Maybe<Scalars['Boolean']>
  locale?: Maybe<Scalars['String']>
}

export enum TodoOrder {
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ActionNameAsc = 'actionName_ASC',
  ActionNameDesc = 'actionName_DESC',
  ActionLinkAsc = 'actionLink_ASC',
  ActionLinkDesc = 'actionLink_DESC',
  DurationAsc = 'duration_ASC',
  DurationDesc = 'duration_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type _Node = {
  _id: Scalars['ID']
}

export type CfAnimatedPhotoNestedFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  photo_exists?: Maybe<Scalars['Boolean']>
  video_exists?: Maybe<Scalars['Boolean']>
  name_exists?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<CfAnimatedPhotoNestedFilter>>>
  AND?: Maybe<Array<Maybe<CfAnimatedPhotoNestedFilter>>>
}

export type CfAnsweredQuestionNestedFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  question_exists?: Maybe<Scalars['Boolean']>
  question?: Maybe<Scalars['String']>
  question_not?: Maybe<Scalars['String']>
  question_in?: Maybe<Array<Maybe<Scalars['String']>>>
  question_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  question_contains?: Maybe<Scalars['String']>
  question_not_contains?: Maybe<Scalars['String']>
  answer_exists?: Maybe<Scalars['Boolean']>
  answer_contains?: Maybe<Scalars['String']>
  answer_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<CfAnsweredQuestionNestedFilter>>>
  AND?: Maybe<Array<Maybe<CfAnsweredQuestionNestedFilter>>>
}

export type CfArticleMenuItemNestedFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  title_exists?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  title_not?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_contains?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  customLink_exists?: Maybe<Scalars['Boolean']>
  customLink?: Maybe<Scalars['String']>
  customLink_not?: Maybe<Scalars['String']>
  customLink_in?: Maybe<Array<Maybe<Scalars['String']>>>
  customLink_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  customLink_contains?: Maybe<Scalars['String']>
  customLink_not_contains?: Maybe<Scalars['String']>
  article_exists?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<CfArticleMenuItemNestedFilter>>>
  AND?: Maybe<Array<Maybe<CfArticleMenuItemNestedFilter>>>
}

export type CfArticleMenuNodeNestedFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  title_exists?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  title_not?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_contains?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  nodesCollection_exists?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<CfArticleMenuNodeNestedFilter>>>
  AND?: Maybe<Array<Maybe<CfArticleMenuNodeNestedFilter>>>
}

export type CfArticleNestedFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  title_exists?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  title_not?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_contains?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  slug_exists?: Maybe<Scalars['Boolean']>
  slug?: Maybe<Scalars['String']>
  slug_not?: Maybe<Scalars['String']>
  slug_in?: Maybe<Array<Maybe<Scalars['String']>>>
  slug_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  slug_contains?: Maybe<Scalars['String']>
  slug_not_contains?: Maybe<Scalars['String']>
  description_exists?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  description_not?: Maybe<Scalars['String']>
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  featured_exists?: Maybe<Scalars['Boolean']>
  featured?: Maybe<Scalars['Boolean']>
  featured_not?: Maybe<Scalars['Boolean']>
  coverImage_exists?: Maybe<Scalars['Boolean']>
  content_exists?: Maybe<Scalars['Boolean']>
  content_contains?: Maybe<Scalars['String']>
  content_not_contains?: Maybe<Scalars['String']>
  siteId_exists?: Maybe<Scalars['Boolean']>
  siteId?: Maybe<Scalars['Int']>
  siteId_not?: Maybe<Scalars['Int']>
  siteId_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  siteId_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  siteId_gt?: Maybe<Scalars['Int']>
  siteId_gte?: Maybe<Scalars['Int']>
  siteId_lt?: Maybe<Scalars['Int']>
  siteId_lte?: Maybe<Scalars['Int']>
  category_exists?: Maybe<Scalars['Boolean']>
  categoriesCollection_exists?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<CfArticleNestedFilter>>>
  AND?: Maybe<Array<Maybe<CfArticleNestedFilter>>>
}

export type CfCategoryNestedFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  name_exists?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<CfCategoryNestedFilter>>>
  AND?: Maybe<Array<Maybe<CfCategoryNestedFilter>>>
}

export type CfDigitalLegacyAuthorNestedFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  name_exists?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  jobTitle_exists?: Maybe<Scalars['Boolean']>
  jobTitle?: Maybe<Scalars['String']>
  jobTitle_not?: Maybe<Scalars['String']>
  jobTitle_in?: Maybe<Array<Maybe<Scalars['String']>>>
  jobTitle_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  jobTitle_contains?: Maybe<Scalars['String']>
  jobTitle_not_contains?: Maybe<Scalars['String']>
  photo_exists?: Maybe<Scalars['Boolean']>
  facebookUrl_exists?: Maybe<Scalars['Boolean']>
  facebookUrl?: Maybe<Scalars['String']>
  facebookUrl_not?: Maybe<Scalars['String']>
  facebookUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>
  facebookUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  facebookUrl_contains?: Maybe<Scalars['String']>
  facebookUrl_not_contains?: Maybe<Scalars['String']>
  linkedinUrl_exists?: Maybe<Scalars['Boolean']>
  linkedinUrl?: Maybe<Scalars['String']>
  linkedinUrl_not?: Maybe<Scalars['String']>
  linkedinUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>
  linkedinUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  linkedinUrl_contains?: Maybe<Scalars['String']>
  linkedinUrl_not_contains?: Maybe<Scalars['String']>
  twitterUrl_exists?: Maybe<Scalars['Boolean']>
  twitterUrl?: Maybe<Scalars['String']>
  twitterUrl_not?: Maybe<Scalars['String']>
  twitterUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>
  twitterUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  twitterUrl_contains?: Maybe<Scalars['String']>
  twitterUrl_not_contains?: Maybe<Scalars['String']>
  instagramUrl_exists?: Maybe<Scalars['Boolean']>
  instagramUrl?: Maybe<Scalars['String']>
  instagramUrl_not?: Maybe<Scalars['String']>
  instagramUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>
  instagramUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  instagramUrl_contains?: Maybe<Scalars['String']>
  instagramUrl_not_contains?: Maybe<Scalars['String']>
  description_exists?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  description_not?: Maybe<Scalars['String']>
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<CfDigitalLegacyAuthorNestedFilter>>>
  AND?: Maybe<Array<Maybe<CfDigitalLegacyAuthorNestedFilter>>>
}

export type CfDigitalLegacyTopicNestedFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  icon_exists?: Maybe<Scalars['Boolean']>
  icon?: Maybe<Scalars['String']>
  icon_not?: Maybe<Scalars['String']>
  icon_in?: Maybe<Array<Maybe<Scalars['String']>>>
  icon_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  icon_contains?: Maybe<Scalars['String']>
  icon_not_contains?: Maybe<Scalars['String']>
  content_exists?: Maybe<Scalars['Boolean']>
  content?: Maybe<Scalars['String']>
  content_not?: Maybe<Scalars['String']>
  content_in?: Maybe<Array<Maybe<Scalars['String']>>>
  content_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  content_contains?: Maybe<Scalars['String']>
  content_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<CfDigitalLegacyTopicNestedFilter>>>
  AND?: Maybe<Array<Maybe<CfDigitalLegacyTopicNestedFilter>>>
}

export type CfLandingPageSectionExtraParagraphNestedFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  title_exists?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  title_not?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_contains?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  description_exists?: Maybe<Scalars['Boolean']>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  iconName_exists?: Maybe<Scalars['Boolean']>
  iconName?: Maybe<Scalars['String']>
  iconName_not?: Maybe<Scalars['String']>
  iconName_in?: Maybe<Array<Maybe<Scalars['String']>>>
  iconName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  iconName_contains?: Maybe<Scalars['String']>
  iconName_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<CfLandingPageSectionExtraParagraphNestedFilter>>>
  AND?: Maybe<Array<Maybe<CfLandingPageSectionExtraParagraphNestedFilter>>>
}

export type CfMentionItemNestedFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  name_exists?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  logo_exists?: Maybe<Scalars['Boolean']>
  citation_exists?: Maybe<Scalars['Boolean']>
  citation?: Maybe<Scalars['String']>
  citation_not?: Maybe<Scalars['String']>
  citation_in?: Maybe<Array<Maybe<Scalars['String']>>>
  citation_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  citation_contains?: Maybe<Scalars['String']>
  citation_not_contains?: Maybe<Scalars['String']>
  url_exists?: Maybe<Scalars['Boolean']>
  url?: Maybe<Scalars['String']>
  url_not?: Maybe<Scalars['String']>
  url_in?: Maybe<Array<Maybe<Scalars['String']>>>
  url_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  url_contains?: Maybe<Scalars['String']>
  url_not_contains?: Maybe<Scalars['String']>
  brief_exists?: Maybe<Scalars['Boolean']>
  brief?: Maybe<Scalars['String']>
  brief_not?: Maybe<Scalars['String']>
  brief_in?: Maybe<Array<Maybe<Scalars['String']>>>
  brief_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  brief_contains?: Maybe<Scalars['String']>
  brief_not_contains?: Maybe<Scalars['String']>
  personPhoto_exists?: Maybe<Scalars['Boolean']>
  stars_exists?: Maybe<Scalars['Boolean']>
  stars?: Maybe<Scalars['Float']>
  stars_not?: Maybe<Scalars['Float']>
  stars_in?: Maybe<Array<Maybe<Scalars['Float']>>>
  stars_not_in?: Maybe<Array<Maybe<Scalars['Float']>>>
  stars_gt?: Maybe<Scalars['Float']>
  stars_gte?: Maybe<Scalars['Float']>
  stars_lt?: Maybe<Scalars['Float']>
  stars_lte?: Maybe<Scalars['Float']>
  personName_exists?: Maybe<Scalars['Boolean']>
  personName?: Maybe<Scalars['String']>
  personName_not?: Maybe<Scalars['String']>
  personName_in?: Maybe<Array<Maybe<Scalars['String']>>>
  personName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  personName_contains?: Maybe<Scalars['String']>
  personName_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<CfMentionItemNestedFilter>>>
  AND?: Maybe<Array<Maybe<CfMentionItemNestedFilter>>>
}

export type CfOfferPerkNestedFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  title_exists?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  title_not?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_contains?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  description_exists?: Maybe<Scalars['Boolean']>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<CfOfferPerkNestedFilter>>>
  AND?: Maybe<Array<Maybe<CfOfferPerkNestedFilter>>>
}

export type CfParagraphWithCaptionNestedFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  caption_exists?: Maybe<Scalars['Boolean']>
  caption?: Maybe<Scalars['String']>
  caption_not?: Maybe<Scalars['String']>
  caption_in?: Maybe<Array<Maybe<Scalars['String']>>>
  caption_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  caption_contains?: Maybe<Scalars['String']>
  caption_not_contains?: Maybe<Scalars['String']>
  paragraph_exists?: Maybe<Scalars['Boolean']>
  paragraph?: Maybe<Scalars['String']>
  paragraph_not?: Maybe<Scalars['String']>
  paragraph_in?: Maybe<Array<Maybe<Scalars['String']>>>
  paragraph_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  paragraph_contains?: Maybe<Scalars['String']>
  paragraph_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<CfParagraphWithCaptionNestedFilter>>>
  AND?: Maybe<Array<Maybe<CfParagraphWithCaptionNestedFilter>>>
}

export type CfPartnerBenefitNestedFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  title_exists?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  title_not?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_contains?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  description_exists?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  description_not?: Maybe<Scalars['String']>
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<CfPartnerBenefitNestedFilter>>>
  AND?: Maybe<Array<Maybe<CfPartnerBenefitNestedFilter>>>
}

export type CfPersonNestedFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  name_exists?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  title_exists?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  title_not?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_contains?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  photo_exists?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<CfPersonNestedFilter>>>
  AND?: Maybe<Array<Maybe<CfPersonNestedFilter>>>
}

export type CfSingingPortraitNestedFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  video_exists?: Maybe<Scalars['Boolean']>
  songName_exists?: Maybe<Scalars['Boolean']>
  songName?: Maybe<Scalars['String']>
  songName_not?: Maybe<Scalars['String']>
  songName_in?: Maybe<Array<Maybe<Scalars['String']>>>
  songName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  songName_contains?: Maybe<Scalars['String']>
  songName_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<CfSingingPortraitNestedFilter>>>
  AND?: Maybe<Array<Maybe<CfSingingPortraitNestedFilter>>>
}

export type CfSingingPortraitSongNestedFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  song_exists?: Maybe<Scalars['Boolean']>
  image_exists?: Maybe<Scalars['Boolean']>
  songName_exists?: Maybe<Scalars['Boolean']>
  songName?: Maybe<Scalars['String']>
  songName_not?: Maybe<Scalars['String']>
  songName_in?: Maybe<Array<Maybe<Scalars['String']>>>
  songName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  songName_contains?: Maybe<Scalars['String']>
  songName_not_contains?: Maybe<Scalars['String']>
  artist_exists?: Maybe<Scalars['Boolean']>
  artist?: Maybe<Scalars['String']>
  artist_not?: Maybe<Scalars['String']>
  artist_in?: Maybe<Array<Maybe<Scalars['String']>>>
  artist_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  artist_contains?: Maybe<Scalars['String']>
  artist_not_contains?: Maybe<Scalars['String']>
  driverId_exists?: Maybe<Scalars['Boolean']>
  driverId?: Maybe<Scalars['String']>
  driverId_not?: Maybe<Scalars['String']>
  driverId_in?: Maybe<Array<Maybe<Scalars['String']>>>
  driverId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  driverId_contains?: Maybe<Scalars['String']>
  driverId_not_contains?: Maybe<Scalars['String']>
  OR?: Maybe<Array<Maybe<CfSingingPortraitSongNestedFilter>>>
  AND?: Maybe<Array<Maybe<CfSingingPortraitSongNestedFilter>>>
}

export type CfStoryPortraitCategoryNestedFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  title_exists?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  title_not?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_contains?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  description_exists?: Maybe<Scalars['Boolean']>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  picturesCollection_exists?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<CfStoryPortraitCategoryNestedFilter>>>
  AND?: Maybe<Array<Maybe<CfStoryPortraitCategoryNestedFilter>>>
}

export type CfStoryPortraitPhraseNestedFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  phrase_exists?: Maybe<Scalars['Boolean']>
  phrase?: Maybe<Scalars['String']>
  phrase_not?: Maybe<Scalars['String']>
  phrase_in?: Maybe<Array<Maybe<Scalars['String']>>>
  phrase_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  phrase_contains?: Maybe<Scalars['String']>
  phrase_not_contains?: Maybe<Scalars['String']>
  audio_exists?: Maybe<Scalars['Boolean']>
  picture_exists?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<CfStoryPortraitPhraseNestedFilter>>>
  AND?: Maybe<Array<Maybe<CfStoryPortraitPhraseNestedFilter>>>
}

export type CfTipNestedFilter = {
  sys?: Maybe<SysFilter>
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>
  title_exists?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  title_not?: Maybe<Scalars['String']>
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  title_contains?: Maybe<Scalars['String']>
  title_not_contains?: Maybe<Scalars['String']>
  description_exists?: Maybe<Scalars['Boolean']>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  icon_exists?: Maybe<Scalars['Boolean']>
  OR?: Maybe<Array<Maybe<CfTipNestedFilter>>>
  AND?: Maybe<Array<Maybe<CfTipNestedFilter>>>
}

export type GetApiDocsQueryVariables = Exact<{ [key: string]: never }>

export type GetApiDocsQuery = { __typename?: 'Query' } & {
  apiDocumentationCollection?: Maybe<
    { __typename?: 'ApiDocumentationCollection' } & {
      items: Array<Maybe<{ __typename?: 'ApiDocumentation' } & Pick<ApiDocumentation, 'overview'>>>
    }
  >
}

export type ArticleFragment = { __typename?: 'Article' } & Pick<
  Article,
  'title' | 'slug' | 'siteId'
> & {
    sys: { __typename?: 'Sys' } & Pick<Sys, 'id' | 'firstPublishedAt'>
    content?: Maybe<{ __typename?: 'ArticleContent' } & Pick<ArticleContent, 'json'>>
    coverImage?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'title' | 'url'>>
    categoriesCollection?: Maybe<
      { __typename?: 'ArticleCategoriesCollection' } & {
        items: Array<
          Maybe<
            { __typename?: 'Category' } & Pick<Category, 'name'> & {
                sys: { __typename?: 'Sys' } & Pick<Sys, 'id'>
              }
          >
        >
      }
    >
  }

export type GetPageArticlesQueryVariables = Exact<{
  page: Scalars['String']
}>

export type GetPageArticlesQuery = { __typename?: 'Query' } & {
  pageArticlesCollection?: Maybe<
    { __typename?: 'PageArticlesCollection' } & {
      items: Array<
        Maybe<
          { __typename?: 'PageArticles' } & Pick<PageArticles, 'page'> & {
              articlesCollection?: Maybe<
                { __typename?: 'PageArticlesArticlesCollection' } & {
                  items: Array<Maybe<{ __typename?: 'Article' } & ArticleFragment>>
                }
              >
            }
        >
      >
    }
  >
}

export type GetArticleBySlugsQueryVariables = Exact<{
  slugs: Array<Scalars['String']> | Scalars['String']
}>

export type GetArticleBySlugsQuery = { __typename?: 'Query' } & {
  pageArticleCollection?: Maybe<
    { __typename?: 'PageArticleCollection' } & {
      items: Array<
        Maybe<
          { __typename?: 'PageArticle' } & Pick<PageArticle, 'slug'> & {
              article?: Maybe<{ __typename?: 'Article' } & ArticleFragment>
            }
        >
      >
    }
  >
}

export type GetArticleSlugsQueryVariables = Exact<{ [key: string]: never }>

export type GetArticleSlugsQuery = { __typename?: 'Query' } & {
  pageArticleCollection?: Maybe<
    { __typename?: 'PageArticleCollection' } & {
      items: Array<Maybe<{ __typename?: 'PageArticle' } & Pick<PageArticle, 'slug'>>>
    }
  >
}

export type GetArticlesSetupQueryVariables = Exact<{ [key: string]: never }>

export type GetArticlesSetupQuery = { __typename?: 'Query' } & {
  articlesSetupCollection?: Maybe<
    { __typename?: 'ArticlesSetupCollection' } & {
      items: Array<
        Maybe<
          { __typename?: 'ArticlesSetup' } & Pick<ArticlesSetup, 'title'> & {
              endOfTheLivePlanningMenuCollection?: Maybe<
                { __typename?: 'ArticlesSetupEndOfTheLivePlanningMenuCollection' } & {
                  items: Array<
                    Maybe<
                      { __typename?: 'ArticleMenuNode' } & Pick<ArticleMenuNode, 'title'> & {
                          nodesCollection?: Maybe<
                            { __typename?: 'ArticleMenuNodeNodesCollection' } & {
                              items: Array<
                                Maybe<
                                  { __typename?: 'ArticleMenuItem' } & Pick<
                                    ArticleMenuItem,
                                    'title' | 'customLink'
                                  > & {
                                      article?: Maybe<
                                        { __typename?: 'Article' } & Pick<
                                          Article,
                                          'title' | 'slug' | 'siteId'
                                        > & {
                                            sys: { __typename?: 'Sys' } & Pick<
                                              Sys,
                                              'id' | 'firstPublishedAt'
                                            >
                                            content?: Maybe<
                                              { __typename?: 'ArticleContent' } & Pick<
                                                ArticleContent,
                                                'json'
                                              >
                                            >
                                          }
                                      >
                                    }
                                >
                              >
                            }
                          >
                        }
                    >
                  >
                }
              >
            }
        >
      >
    }
  >
}

export type GetAssetByIdQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetAssetByIdQuery = { __typename?: 'Query' } & {
  asset?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'title' | 'url' | 'fileName'>>
}

export type GetPageImagesQueryVariables = Exact<{ [key: string]: never }>

export type GetPageImagesQuery = { __typename?: 'Query' } & {
  pageImageCollection?: Maybe<
    { __typename?: 'PageImageCollection' } & {
      items: Array<
        Maybe<
          { __typename?: 'PageImage' } & Pick<PageImage, 'slug'> & {
              image?: Maybe<{ __typename?: 'Asset' } & ImageFragment>
            }
        >
      >
    }
  >
}

export type GetMediaMentionsBySlugQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type GetMediaMentionsBySlugQuery = { __typename?: 'Query' } & {
  mediaMentionListCollection?: Maybe<
    { __typename?: 'MediaMentionListCollection' } & {
      items: Array<
        Maybe<
          { __typename?: 'MediaMentionList' } & Pick<MediaMentionList, 'slug'> & {
              mediaMentionsCollection?: Maybe<
                { __typename?: 'MediaMentionListMediaMentionsCollection' } & {
                  items: Array<
                    Maybe<
                      { __typename?: 'MentionItem' } & Pick<
                        MentionItem,
                        'name' | 'citation' | 'url' | 'brief' | 'stars' | 'personName'
                      > & {
                          logo?: Maybe<{ __typename?: 'Asset' } & ImageFragment>
                          personPhoto?: Maybe<{ __typename?: 'Asset' } & ImageFragment>
                        }
                    >
                  >
                }
              >
            }
        >
      >
    }
  >
}

export type GetMediaMentionsQueryVariables = Exact<{ [key: string]: never }>

export type GetMediaMentionsQuery = { __typename?: 'Query' } & {
  mediaMentionListCollection?: Maybe<
    { __typename?: 'MediaMentionListCollection' } & {
      items: Array<Maybe<{ __typename?: 'MediaMentionList' } & Pick<MediaMentionList, 'slug'>>>
    }
  >
}

export type GetPageFilesQueryVariables = Exact<{ [key: string]: never }>

export type GetPageFilesQuery = { __typename?: 'Query' } & {
  pageFileCollection?: Maybe<
    { __typename?: 'PageFileCollection' } & {
      items: Array<Maybe<{ __typename?: 'PageFile' } & Pick<PageFile, 'slug'>>>
    }
  >
}

export type GetPageFileBySlugQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type GetPageFileBySlugQuery = { __typename?: 'Query' } & {
  pageFileCollection?: Maybe<
    { __typename?: 'PageFileCollection' } & {
      items: Array<
        Maybe<
          { __typename?: 'PageFile' } & Pick<PageFile, 'slug'> & {
              file?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'url' | 'fileName' | 'title'>>
            }
        >
      >
    }
  >
}

export type ImageFragment = { __typename?: 'Asset' } & Pick<
  Asset,
  'url' | 'width' | 'height' | 'title' | 'description' | 'size' | 'fileName' | 'contentType'
> & { sys: { __typename?: 'Sys' } & Pick<Sys, 'id'> }

export type GetSingleDigitalLegacyQueryVariables = Exact<{ [key: string]: never }>

export type GetSingleDigitalLegacyQuery = { __typename?: 'Query' } & {
  digitalLegacyCollection?: Maybe<
    { __typename?: 'DigitalLegacyCollection' } & {
      items: Array<
        Maybe<
          { __typename?: 'DigitalLegacy' } & Pick<
            DigitalLegacy,
            | 'headingTitle'
            | 'headingDescription'
            | 'purchaseUrl'
            | 'viewReviewUrl'
            | 'aboutTitle'
            | 'aboutDescription'
            | 'authorTitle'
            | 'reviewTitle'
            | 'facebookUrl'
            | 'twitterUrl'
            | 'linkedinUrl'
            | 'instagramUrl'
          > & {
              authorListCollection?: Maybe<
                { __typename?: 'DigitalLegacyAuthorListCollection' } & {
                  items: Array<
                    Maybe<
                      { __typename?: 'DigitalLegacyAuthor' } & Pick<
                        DigitalLegacyAuthor,
                        | 'name'
                        | 'description'
                        | 'instagramUrl'
                        | 'linkedinUrl'
                        | 'facebookUrl'
                        | 'twitterUrl'
                      > & {
                          sys: { __typename?: 'Sys' } & Pick<Sys, 'id'>
                          photo?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'url'>>
                        }
                    >
                  >
                }
              >
              reviewListCollection?: Maybe<
                { __typename?: 'DigitalLegacyReviewListCollection' } & {
                  items: Array<
                    Maybe<
                      { __typename?: 'DigitalLegacyAuthor' } & Pick<
                        DigitalLegacyAuthor,
                        'name' | 'jobTitle' | 'description'
                      > & {
                          sys: { __typename?: 'Sys' } & Pick<Sys, 'id'>
                          photo?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'url'>>
                        }
                    >
                  >
                }
              >
              topicListCollection?: Maybe<
                { __typename?: 'DigitalLegacyTopicListCollection' } & {
                  items: Array<
                    Maybe<
                      { __typename?: 'DigitalLegacyTopic' } & Pick<
                        DigitalLegacyTopic,
                        'icon' | 'content'
                      > & { sys: { __typename?: 'Sys' } & Pick<Sys, 'id'> }
                    >
                  >
                }
              >
            }
        >
      >
    }
  >
}

export type GetTodoCollectionQueryVariables = Exact<{ [key: string]: never }>

export type GetTodoCollectionQuery = { __typename?: 'Query' } & {
  todoCollection?: Maybe<
    { __typename?: 'TodoCollection' } & {
      items: Array<
        Maybe<
          { __typename?: 'Todo' } & Pick<
            Todo,
            'type' | 'title' | 'actionName' | 'actionLink' | 'duration'
          > & {
              desc?: Maybe<{ __typename?: 'TodoDesc' } & Pick<TodoDesc, 'json'>>
              icon?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'url'>>
            }
        >
      >
    }
  >
}

export type SingingPortraitSongFragment = { __typename?: 'SingingPortraitSong' } & Pick<
  SingingPortraitSong,
  'songName' | 'artist' | 'driverId'
> & {
    sys: { __typename?: 'Sys' } & Pick<Sys, 'id'>
    song?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'url'>>
    image?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'url'>>
  }

export type SingingPortraitFragment = { __typename?: 'SingingPortrait' } & Pick<
  SingingPortrait,
  'songName'
> & {
    sys: { __typename?: 'Sys' } & Pick<Sys, 'id'>
    video?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'fileName' | 'url'>>
  }

export type LivePortraitFragment = { __typename?: 'AnimatedPhoto' } & Pick<
  AnimatedPhoto,
  'name'
> & {
    sys: { __typename?: 'Sys' } & Pick<Sys, 'id'>
    photo?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'fileName' | 'url'>>
    video?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'fileName' | 'url'>>
  }

export type ParagraphWithCaptionFragment = { __typename?: 'ParagraphWithCaption' } & Pick<
  ParagraphWithCaption,
  'caption' | 'paragraph'
> & { sys: { __typename?: 'Sys' } & Pick<Sys, 'id'> }

export type GetDefaultSingingPortraitsQueryVariables = Exact<{ [key: string]: never }>

export type GetDefaultSingingPortraitsQuery = { __typename?: 'Query' } & {
  memorySetupCollection?: Maybe<
    { __typename?: 'MemorySetupCollection' } & {
      items: Array<
        Maybe<
          { __typename?: 'MemorySetup' } & {
            singingPortraitsCollection?: Maybe<
              { __typename?: 'MemorySetupSingingPortraitsCollection' } & {
                items: Array<Maybe<{ __typename?: 'SingingPortrait' } & SingingPortraitFragment>>
              }
            >
          }
        >
      >
    }
  >
}

export type GetDefaultSingingPortraitSongsQueryVariables = Exact<{ [key: string]: never }>

export type GetDefaultSingingPortraitSongsQuery = { __typename?: 'Query' } & {
  memorySetupCollection?: Maybe<
    { __typename?: 'MemorySetupCollection' } & {
      items: Array<
        Maybe<
          { __typename?: 'MemorySetup' } & {
            singingPortraitSongsCollection?: Maybe<
              { __typename?: 'MemorySetupSingingPortraitSongsCollection' } & {
                items: Array<
                  Maybe<{ __typename?: 'SingingPortraitSong' } & SingingPortraitSongFragment>
                >
              }
            >
          }
        >
      >
    }
  >
}

export type GetDefaultLivePortraitsQueryVariables = Exact<{ [key: string]: never }>

export type GetDefaultLivePortraitsQuery = { __typename?: 'Query' } & {
  memorySetupCollection?: Maybe<
    { __typename?: 'MemorySetupCollection' } & {
      items: Array<
        Maybe<
          { __typename?: 'MemorySetup' } & {
            livePortraitsCollection?: Maybe<
              { __typename?: 'MemorySetupLivePortraitsCollection' } & {
                items: Array<Maybe<{ __typename?: 'AnimatedPhoto' } & LivePortraitFragment>>
              }
            >
          }
        >
      >
    }
  >
}

export type GetBothSingingAndLivePortraitsQueryVariables = Exact<{ [key: string]: never }>

export type GetBothSingingAndLivePortraitsQuery = { __typename?: 'Query' } & {
  memorySetupCollection?: Maybe<
    { __typename?: 'MemorySetupCollection' } & {
      items: Array<
        Maybe<
          { __typename?: 'MemorySetup' } & {
            livePortraitsCollection?: Maybe<
              { __typename?: 'MemorySetupLivePortraitsCollection' } & {
                items: Array<Maybe<{ __typename?: 'AnimatedPhoto' } & LivePortraitFragment>>
              }
            >
            singingPortraitsCollection?: Maybe<
              { __typename?: 'MemorySetupSingingPortraitsCollection' } & {
                items: Array<Maybe<{ __typename?: 'SingingPortrait' } & SingingPortraitFragment>>
              }
            >
          }
        >
      >
    }
  >
}

export type GetPartnerLandingQueryVariables = Exact<{
  key: Scalars['String']
}>

export type GetPartnerLandingQuery = { __typename?: 'Query' } & {
  partnerLandingCollection?: Maybe<
    { __typename?: 'PartnerLandingCollection' } & {
      items: Array<
        Maybe<
          { __typename?: 'PartnerLanding' } & Pick<
            PartnerLanding,
            | 'title'
            | 'promocode'
            | 'description'
            | 'benefitsIntro'
            | 'benefitsTitle'
            | 'actionTitle'
            | 'actionDescription'
            | 'landingButtonText'
          > & {
              cover?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'url'>>
              benefitsCollection?: Maybe<
                { __typename?: 'PartnerLandingBenefitsCollection' } & {
                  items: Array<
                    Maybe<
                      { __typename?: 'PartnerBenefit' } & Pick<
                        PartnerBenefit,
                        'title' | 'description'
                      > & { sys: { __typename?: 'Sys' } & Pick<Sys, 'id'> }
                    >
                  >
                }
              >
            }
        >
      >
    }
  >
}

export type GetFutureMessageSetupQueryVariables = Exact<{ [key: string]: never }>

export type GetFutureMessageSetupQuery = { __typename?: 'Query' } & {
  futureMessageCollection?: Maybe<
    { __typename?: 'FutureMessageCollection' } & {
      items: Array<
        Maybe<
          { __typename?: 'FutureMessage' } & Pick<FutureMessage, 'inspiredList'> & {
              landingVideosPreviewCollection?: Maybe<
                { __typename?: 'AssetCollection' } & {
                  items: Array<Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'fileName' | 'url'>>>
                }
              >
            }
        >
      >
    }
  >
}

export type MentionItemFragment = { __typename?: 'MentionItem' } & Pick<
  MentionItem,
  'name' | 'citation' | 'url'
> & { logo?: Maybe<{ __typename?: 'Asset' } & ImageFragment> }

export type GetSingleMediaMentionQueryVariables = Exact<{
  where?: Maybe<MediaMentionsFilter>
}>

export type GetSingleMediaMentionQuery = { __typename?: 'Query' } & {
  mediaMentionsCollection?: Maybe<
    { __typename?: 'MediaMentionsCollection' } & {
      items: Array<
        Maybe<
          { __typename?: 'MediaMentions' } & Pick<MediaMentions, 'title'> & {
              mentionsListCollection?: Maybe<
                { __typename?: 'MediaMentionsMentionsListCollection' } & {
                  items: Array<Maybe<{ __typename?: 'MentionItem' } & MentionItemFragment>>
                }
              >
            }
        >
      >
    }
  >
}

export type AboutUsPersonFragment = { __typename?: 'Person' } & Pick<Person, 'name' | 'title'> & {
    photo?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'url'>>
  }

export type AnsweredQuestionFragment = { __typename?: 'AnsweredQuestion' } & Pick<
  AnsweredQuestion,
  'question'
> & {
    sys: { __typename?: 'Sys' } & Pick<Sys, 'id'>
    answer?: Maybe<{ __typename?: 'AnsweredQuestionAnswer' } & Pick<AnsweredQuestionAnswer, 'json'>>
  }

export type GetAboutUsPageQueryVariables = Exact<{
  where?: Maybe<AboutUsPageFilter>
}>

export type GetAboutUsPageQuery = { __typename?: 'Query' } & {
  aboutUsPageCollection?: Maybe<
    { __typename?: 'AboutUsPageCollection' } & {
      items: Array<
        Maybe<
          { __typename?: 'AboutUsPage' } & {
            teamMembersCollection?: Maybe<
              { __typename?: 'AboutUsPageTeamMembersCollection' } & {
                items: Array<Maybe<{ __typename?: 'Person' } & AboutUsPersonFragment>>
              }
            >
            investorsPeopleCollection?: Maybe<
              { __typename?: 'AboutUsPageInvestorsPeopleCollection' } & {
                items: Array<Maybe<{ __typename?: 'Person' } & AboutUsPersonFragment>>
              }
            >
          }
        >
      >
    }
  >
}

export type ActionCardFragment = { __typename?: 'ActionCard' } & Pick<
  ActionCard,
  'title' | 'description' | 'buttonText' | 'duration' | 'cornerstone' | 'icon' | 'tag' | 'url'
> & {
    sys: { __typename?: 'Sys' } & Pick<Sys, 'id'>
    customIcon?: Maybe<{ __typename?: 'Asset' } & ImageFragment>
  }

export type SteppedTutorialFragment = { __typename?: 'SteppedTutorial' } & Pick<
  SteppedTutorial,
  | 'isIcon'
  | 'buttonLabel'
  | 'title1'
  | 'title2'
  | 'title3'
  | 'tutorialTitle'
  | 'iconName1'
  | 'iconName2'
  | 'iconName3'
> & {
    description1?: Maybe<
      { __typename?: 'SteppedTutorialDescription1' } & Pick<SteppedTutorialDescription1, 'json'>
    >
    description2?: Maybe<
      { __typename?: 'SteppedTutorialDescription2' } & Pick<SteppedTutorialDescription2, 'json'>
    >
    description3?: Maybe<
      { __typename?: 'SteppedTutorialDescription3' } & Pick<SteppedTutorialDescription3, 'json'>
    >
    image1?: Maybe<{ __typename?: 'Asset' } & ImageFragment>
    image2?: Maybe<{ __typename?: 'Asset' } & ImageFragment>
    image3?: Maybe<{ __typename?: 'Asset' } & ImageFragment>
  }

export type LandingPageSectionFragment = { __typename?: 'LandingPageSection' } & Pick<
  LandingPageSection,
  'imagePlacement' | 'hideButton' | 'title' | 'primaryButtonLabel' | 'secondaryButtonLabel' | 'tag'
> & {
    sys: { __typename?: 'Sys' } & Pick<Sys, 'id'>
    image?: Maybe<{ __typename?: 'Asset' } & ImageFragment>
    imageMobile?: Maybe<{ __typename?: 'Asset' } & ImageFragment>
    description?: Maybe<
      { __typename?: 'LandingPageSectionDescription' } & Pick<LandingPageSectionDescription, 'json'>
    >
    extraParagraphsCollection?: Maybe<
      { __typename?: 'LandingPageSectionExtraParagraphsCollection' } & {
        items: Array<
          Maybe<
            { __typename?: 'LandingPageSectionExtraParagraph' } & Pick<
              LandingPageSectionExtraParagraph,
              'title' | 'iconName'
            > & {
                description?: Maybe<
                  { __typename?: 'LandingPageSectionExtraParagraphDescription' } & Pick<
                    LandingPageSectionExtraParagraphDescription,
                    'json'
                  >
                >
              }
          >
        >
      }
    >
    descriptionBulletPointsCollection?: Maybe<
      { __typename?: 'LandingPageSectionDescriptionBulletPointsCollection' } & {
        items: Array<Maybe<{ __typename?: 'OfferPerk' } & OfferPerkFragment>>
      }
    >
  }

export type HomePageSetupFragment = { __typename?: 'HomePageSetup' } & {
  reviewsListCollection?: Maybe<
    { __typename?: 'HomePageSetupReviewsListCollection' } & {
      items: Array<
        Maybe<
          { __typename?: 'MentionItem' } & Pick<
            MentionItem,
            'name' | 'citation' | 'brief' | 'personName' | 'stars'
          > & {
              logo?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'url'>>
              personPhoto?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'url'>>
            }
        >
      >
    }
  >
}

export type GetHomepageSetupQueryVariables = Exact<{ [key: string]: never }>

export type GetHomepageSetupQuery = { __typename?: 'Query' } & {
  desktop?: Maybe<
    { __typename?: 'HomePageSetupCollection' } & {
      items: Array<Maybe<{ __typename?: 'HomePageSetup' } & HomePageSetupFragment>>
    }
  >
  mobile?: Maybe<
    { __typename?: 'HomePageSetupCollection' } & {
      items: Array<Maybe<{ __typename?: 'HomePageSetup' } & HomePageSetupFragment>>
    }
  >
}

export type GetDocumentBySlugQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type GetDocumentBySlugQuery = { __typename?: 'Query' } & {
  pageDocumentCollection?: Maybe<
    { __typename?: 'PageDocumentCollection' } & {
      items: Array<
        Maybe<
          { __typename?: 'PageDocument' } & Pick<PageDocument, 'slug'> & {
              content?: Maybe<
                { __typename?: 'PageDocumentContent' } & Pick<PageDocumentContent, 'json'>
              >
            }
        >
      >
    }
  >
}

export type GetDocumentSlugsQueryVariables = Exact<{ [key: string]: never }>

export type GetDocumentSlugsQuery = { __typename?: 'Query' } & {
  pageDocumentCollection?: Maybe<
    { __typename?: 'PageDocumentCollection' } & {
      items: Array<Maybe<{ __typename?: 'PageDocument' } & Pick<PageDocument, 'slug'>>>
    }
  >
}

export type GetFaqBySlugQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type GetFaqBySlugQuery = { __typename?: 'Query' } & {
  pageFaqCollection?: Maybe<
    { __typename?: 'PageFaqCollection' } & {
      items: Array<
        Maybe<
          { __typename?: 'PageFaq' } & Pick<PageFaq, 'slug'> & {
              faqListCollection?: Maybe<
                { __typename?: 'PageFaqFaqListCollection' } & {
                  items: Array<
                    Maybe<{ __typename?: 'AnsweredQuestion' } & AnsweredQuestionFragment>
                  >
                }
              >
            }
        >
      >
    }
  >
}

export type GetFaqSlugsQueryVariables = Exact<{ [key: string]: never }>

export type GetFaqSlugsQuery = { __typename?: 'Query' } & {
  pageFaqCollection?: Maybe<
    { __typename?: 'PageFaqCollection' } & {
      items: Array<Maybe<{ __typename?: 'PageFaq' } & Pick<PageFaq, 'slug'>>>
    }
  >
}

export type GetHelpCarouselQueryVariables = Exact<{ [key: string]: never }>

export type GetHelpCarouselQuery = { __typename?: 'Query' } & {
  helpCarouselConfigCollection?: Maybe<
    { __typename?: 'HelpCarouselConfigCollection' } & {
      items: Array<
        Maybe<
          { __typename?: 'HelpCarouselConfig' } & Pick<HelpCarouselConfig, 'title'> & {
              imagesCollection?: Maybe<
                { __typename?: 'AssetCollection' } & {
                  items: Array<Maybe<{ __typename?: 'Asset' } & ImageFragment>>
                }
              >
            }
        >
      >
    }
  >
}

export type OfferPerkFragment = { __typename?: 'OfferPerk' } & Pick<OfferPerk, 'title'> & {
    sys: { __typename?: 'Sys' } & Pick<Sys, 'id'>
    description?: Maybe<
      { __typename?: 'OfferPerkDescription' } & Pick<OfferPerkDescription, 'json'>
    >
  }

export type StoryPortraitPhraseFragment = { __typename?: 'StoryPortraitPhrase' } & Pick<
  StoryPortraitPhrase,
  'phrase'
> & {
    sys: { __typename?: 'Sys' } & Pick<Sys, 'id'>
    audio?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'url'>>
    picture?: Maybe<{ __typename?: 'Asset' } & Pick<Asset, 'url'>>
  }

export type StoryPortraitCategoryFragment = { __typename?: 'StoryPortraitCategory' } & Pick<
  StoryPortraitCategory,
  'title'
> & {
    sys: { __typename?: 'Sys' } & Pick<Sys, 'id'>
    description?: Maybe<
      { __typename?: 'StoryPortraitCategoryDescription' } & Pick<
        StoryPortraitCategoryDescription,
        'json'
      >
    >
    picturesCollection?: Maybe<
      { __typename?: 'AssetCollection' } & {
        items: Array<
          Maybe<
            { __typename?: 'Asset' } & Pick<Asset, 'title' | 'url'> & {
                sys: { __typename?: 'Sys' } & Pick<Sys, 'id'>
              }
          >
        >
      }
    >
  }

export type StoryPortraitCategoriesFragment = { __typename?: 'MemorySetup' } & {
  storyPortraitCategoriesCollection?: Maybe<
    { __typename?: 'MemorySetupStoryPortraitCategoriesCollection' } & {
      items: Array<Maybe<{ __typename?: 'StoryPortraitCategory' } & StoryPortraitCategoryFragment>>
    }
  >
}

export type StoryPortraitPhrasesFragment = { __typename?: 'MemorySetup' } & {
  storyPortraitPhrasesCollection?: Maybe<
    { __typename?: 'MemorySetupStoryPortraitPhrasesCollection' } & {
      items: Array<Maybe<{ __typename?: 'StoryPortraitPhrase' } & StoryPortraitPhraseFragment>>
    }
  >
}

export type StoryPortraitCarouselFragment = { __typename?: 'MemorySetup' } & {
  storyPortraitCarouselCollection?: Maybe<
    { __typename?: 'AssetCollection' } & {
      items: Array<
        Maybe<
          { __typename?: 'Asset' } & Pick<Asset, 'url'> & {
              sys: { __typename?: 'Sys' } & Pick<Sys, 'id'>
            }
        >
      >
    }
  >
}

export type GetStoryPortraitCategoriesQueryVariables = Exact<{ [key: string]: never }>

export type GetStoryPortraitCategoriesQuery = { __typename?: 'Query' } & {
  memorySetupCollection?: Maybe<
    { __typename?: 'MemorySetupCollection' } & {
      items: Array<Maybe<{ __typename?: 'MemorySetup' } & StoryPortraitCategoriesFragment>>
    }
  >
}

export type GetStoryPortraitPhrasesQueryVariables = Exact<{ [key: string]: never }>

export type GetStoryPortraitPhrasesQuery = { __typename?: 'Query' } & {
  memorySetupCollection?: Maybe<
    { __typename?: 'MemorySetupCollection' } & {
      items: Array<Maybe<{ __typename?: 'MemorySetup' } & StoryPortraitPhrasesFragment>>
    }
  >
}

export type GetStoryPortraitCarouselQueryVariables = Exact<{ [key: string]: never }>

export type GetStoryPortraitCarouselQuery = { __typename?: 'Query' } & {
  memorySetupCollection?: Maybe<
    { __typename?: 'MemorySetupCollection' } & {
      items: Array<Maybe<{ __typename?: 'MemorySetup' } & StoryPortraitCarouselFragment>>
    }
  >
}

export type GetStoryPortraitTipsQueryVariables = Exact<{ [key: string]: never }>

export type GetStoryPortraitTipsQuery = { __typename?: 'Query' } & {
  memorySetupCollection?: Maybe<
    { __typename?: 'MemorySetupCollection' } & {
      items: Array<
        Maybe<
          { __typename?: 'MemorySetup' } & {
            storyPortraitTipsCollection?: Maybe<
              { __typename?: 'MemorySetupStoryPortraitTipsCollection' } & {
                items: Array<
                  Maybe<
                    { __typename?: 'Tip' } & Pick<Tip, 'title'> & {
                        sys: { __typename?: 'Sys' } & Pick<Sys, 'id'>
                        description?: Maybe<
                          { __typename?: 'TipDescription' } & Pick<TipDescription, 'json'>
                        >
                      }
                  >
                >
              }
            >
          }
        >
      >
    }
  >
}

export type GetPageVideosQueryVariables = Exact<{ [key: string]: never }>

export type GetPageVideosQuery = { __typename?: 'Query' } & {
  pageVideoCollection?: Maybe<
    { __typename?: 'PageVideoCollection' } & {
      items: Array<Maybe<{ __typename?: 'PageVideo' } & Pick<PageVideo, 'slug' | 'introVideoId'>>>
    }
  >
}

export const ArticleFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Article' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstPublishedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'content' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coverImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoriesCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sys' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const SingingPortraitSongFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SingingPortraitSong' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SingingPortraitSong' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'songName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'artist' } },
          { kind: 'Field', name: { kind: 'Name', value: 'driverId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'song' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const SingingPortraitFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SingingPortrait' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SingingPortrait' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'songName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'video' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const LivePortraitFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LivePortrait' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AnimatedPhoto' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'photo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'video' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const ParagraphWithCaptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ParagraphWithCaption' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ParagraphWithCaption' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paragraph' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const ImageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const MentionItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentionItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MentionItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Image' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'citation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const AboutUsPersonFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AboutUsPerson' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Person' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'photo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const AnsweredQuestionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AnsweredQuestion' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AnsweredQuestion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'answer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const ActionCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ActionCard' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'buttonText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cornerstone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customIcon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Image' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'tag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const SteppedTutorialFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SteppedTutorial' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SteppedTutorial' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'isIcon' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'description1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'description2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'description3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'buttonLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tutorialTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image1' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Image' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image2' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Image' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image3' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Image' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'iconName1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconName2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconName3' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const OfferPerkFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferPerk' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferPerk' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'description' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const LandingPageSectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LandingPageSection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LandingPageSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'imagePlacement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hideButton' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Image' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imageMobile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Image' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'description' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryButtonLabel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondaryButtonLabel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extraParagraphsCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'iconName' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'tag' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'descriptionBulletPointsCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'OfferPerk' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OfferPerk' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OfferPerk' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'description' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const HomePageSetupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomePageSetup' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomePageSetup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reviewsListCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'citation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'brief' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'personPhoto' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'personName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stars' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const StoryPortraitCategoryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoryPortraitCategory' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StoryPortraitCategory' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'description' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'picturesCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sys' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const StoryPortraitCategoriesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoryPortraitCategories' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MemorySetup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storyPortraitCategoriesCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'StoryPortraitCategory' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoryPortraitCategory' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StoryPortraitCategory' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'description' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'picturesCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sys' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const StoryPortraitPhraseFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoryPortraitPhrase' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StoryPortraitPhrase' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'audio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'picture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'phrase' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const StoryPortraitPhrasesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoryPortraitPhrases' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MemorySetup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storyPortraitPhrasesCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'StoryPortraitPhrase' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoryPortraitPhrase' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StoryPortraitPhrase' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'audio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'picture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'phrase' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const StoryPortraitCarouselFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoryPortraitCarousel' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MemorySetup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storyPortraitCarouselCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sys' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetApiDocsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getApiDocs' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'apiDocumentationCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'overview' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetPageArticlesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPageArticles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageArticlesCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'page' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '10' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'articlesCollection' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'limit' },
                            value: { kind: 'IntValue', value: '10' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'Article' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Article' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstPublishedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'content' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coverImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoriesCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sys' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetArticleBySlugsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getArticleBySlugs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slugs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageArticleCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug_in' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'slugs' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'article' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Article' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Article' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Article' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstPublishedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'content' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coverImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoriesCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sys' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetArticleSlugsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getArticleSlugs' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageArticleCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetArticlesSetupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getArticlesSetup' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'articlesSetupCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endOfTheLivePlanningMenuCollection' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'limit' },
                            value: { kind: 'IntValue', value: '10' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodesCollection' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'items' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'customLink' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'article' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'sys' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'firstPublishedAt',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'content' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'json' },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'title' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'slug' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'siteId' },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetAssetByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAssetById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetPageImagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPageImages' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageImageCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1000' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Image' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetMediaMentionsBySlugDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMediaMentionsBySlug' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaMentionListCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mediaMentionsCollection' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'limit' },
                            value: { kind: 'IntValue', value: '10' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'logo' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'Image' },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'citation' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'brief' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'stars' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'personName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personPhoto' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'Image' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetMediaMentionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMediaMentions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaMentionListCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetPageFilesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPageFiles' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageFileCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetPageFileBySlugDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPageFileBySlug' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageFileCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetSingleDigitalLegacyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSingleDigitalLegacy' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'digitalLegacyCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'headingTitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'headingDescription' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'purchaseUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'viewReviewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'aboutTitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'aboutDescription' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'authorTitle' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'authorListCollection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sys' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photo' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'instagramUrl' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'linkedinUrl' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'facebookUrl' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'twitterUrl' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'reviewTitle' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reviewListCollection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sys' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photo' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topicListCollection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sys' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'facebookUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'twitterUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkedinUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'instagramUrl' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetTodoCollectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTodoCollection' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'todoCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'desc' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'icon' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'actionName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'actionLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetDefaultSingingPortraitsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDefaultSingingPortraits' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'memorySetupCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'StringValue', value: 'default', block: false },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'singingPortraitsCollection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SingingPortrait' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SingingPortrait' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SingingPortrait' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'songName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'video' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetDefaultSingingPortraitSongsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDefaultSingingPortraitSongs' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'memorySetupCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'StringValue', value: 'default', block: false },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'singingPortraitSongsCollection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SingingPortraitSong' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SingingPortraitSong' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SingingPortraitSong' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'songName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'artist' } },
          { kind: 'Field', name: { kind: 'Name', value: 'driverId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'song' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetDefaultLivePortraitsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDefaultLivePortraits' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'memorySetupCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'StringValue', value: 'default', block: false },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'livePortraitsCollection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'LivePortrait' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LivePortrait' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AnimatedPhoto' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'photo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'video' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetBothSingingAndLivePortraitsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBothSingingAndLivePortraits' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'memorySetupCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: { kind: 'StringValue', value: 'default', block: false },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'livePortraitsCollection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'LivePortrait' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'singingPortraitsCollection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SingingPortrait' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SingingPortrait' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SingingPortrait' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'songName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'video' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LivePortrait' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AnimatedPhoto' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'photo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'video' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetPartnerLandingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPartnerLanding' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partnerLandingCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'promocode' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'promocode' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cover' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'benefitsIntro' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'benefitsTitle' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'benefitsCollection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sys' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'actionTitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'actionDescription' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'landingButtonText' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetFutureMessageSetupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFutureMessageSetup' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'futureMessageCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'inspiredList' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landingVideosPreviewCollection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetSingleMediaMentionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSingleMediaMention' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaMentionsFilter' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaMentionsCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentionsListCollection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'MentionItem' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MentionItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MentionItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Image' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'citation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetAboutUsPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAboutUsPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'AboutUsPageFilter' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'aboutUsPageCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'teamMembersCollection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'AboutUsPerson' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'investorsPeopleCollection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'AboutUsPerson' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AboutUsPerson' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Person' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'photo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetHomepageSetupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getHomepageSetup' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'desktop' },
            name: { kind: 'Name', value: 'homePageSetupCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: { kind: 'StringValue', value: 'desktop', block: false },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomePageSetup' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'mobile' },
            name: { kind: 'Name', value: 'homePageSetupCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: { kind: 'StringValue', value: 'mobile', block: false },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HomePageSetup' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HomePageSetup' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HomePageSetup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reviewsListCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'citation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'brief' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'personPhoto' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'personName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stars' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetDocumentBySlugDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDocumentBySlug' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageDocumentCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'content' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetDocumentSlugsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDocumentSlugs' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageDocumentCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetFaqBySlugDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFaqBySlug' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageFaqCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'faqListCollection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'AnsweredQuestion' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AnsweredQuestion' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AnsweredQuestion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'question' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'answer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetFaqSlugsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFaqSlugs' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageFaqCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetHelpCarouselDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getHelpCarousel' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'helpCarouselConfigCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imagesCollection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'Image' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Image' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetStoryPortraitCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getStoryPortraitCategories' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'memorySetupCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'StoryPortraitCategories' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoryPortraitCategory' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StoryPortraitCategory' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'description' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'json' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'picturesCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sys' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoryPortraitCategories' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MemorySetup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storyPortraitCategoriesCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'StoryPortraitCategory' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetStoryPortraitPhrasesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getStoryPortraitPhrases' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'memorySetupCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'StoryPortraitPhrases' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoryPortraitPhrase' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StoryPortraitPhrase' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'audio' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'picture' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'phrase' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoryPortraitPhrases' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MemorySetup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storyPortraitPhrasesCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'StoryPortraitPhrase' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetStoryPortraitCarouselDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getStoryPortraitCarousel' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'memorySetupCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'StoryPortraitCarousel' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoryPortraitCarousel' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MemorySetup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storyPortraitCarouselCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sys' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetStoryPortraitTipsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getStoryPortraitTips' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'memorySetupCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'storyPortraitTipsCollection' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sys' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'description' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'json' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const GetPageVideosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPageVideos' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageVideoCollection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'introVideoId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>

const defaultWrapper: SdkFunctionWrapper = (sdkFunction) => sdkFunction()
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    getApiDocs(
      variables?: GetApiDocsQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetApiDocsQuery> {
      return withWrapper(() =>
        client.request<GetApiDocsQuery>(print(GetApiDocsDocument), variables, requestHeaders)
      )
    },
    getPageArticles(
      variables: GetPageArticlesQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetPageArticlesQuery> {
      return withWrapper(() =>
        client.request<GetPageArticlesQuery>(
          print(GetPageArticlesDocument),
          variables,
          requestHeaders
        )
      )
    },
    getArticleBySlugs(
      variables: GetArticleBySlugsQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetArticleBySlugsQuery> {
      return withWrapper(() =>
        client.request<GetArticleBySlugsQuery>(
          print(GetArticleBySlugsDocument),
          variables,
          requestHeaders
        )
      )
    },
    getArticleSlugs(
      variables?: GetArticleSlugsQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetArticleSlugsQuery> {
      return withWrapper(() =>
        client.request<GetArticleSlugsQuery>(
          print(GetArticleSlugsDocument),
          variables,
          requestHeaders
        )
      )
    },
    getArticlesSetup(
      variables?: GetArticlesSetupQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetArticlesSetupQuery> {
      return withWrapper(() =>
        client.request<GetArticlesSetupQuery>(
          print(GetArticlesSetupDocument),
          variables,
          requestHeaders
        )
      )
    },
    getAssetById(
      variables: GetAssetByIdQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetAssetByIdQuery> {
      return withWrapper(() =>
        client.request<GetAssetByIdQuery>(print(GetAssetByIdDocument), variables, requestHeaders)
      )
    },
    getPageImages(
      variables?: GetPageImagesQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetPageImagesQuery> {
      return withWrapper(() =>
        client.request<GetPageImagesQuery>(print(GetPageImagesDocument), variables, requestHeaders)
      )
    },
    getMediaMentionsBySlug(
      variables: GetMediaMentionsBySlugQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetMediaMentionsBySlugQuery> {
      return withWrapper(() =>
        client.request<GetMediaMentionsBySlugQuery>(
          print(GetMediaMentionsBySlugDocument),
          variables,
          requestHeaders
        )
      )
    },
    getMediaMentions(
      variables?: GetMediaMentionsQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetMediaMentionsQuery> {
      return withWrapper(() =>
        client.request<GetMediaMentionsQuery>(
          print(GetMediaMentionsDocument),
          variables,
          requestHeaders
        )
      )
    },
    getPageFiles(
      variables?: GetPageFilesQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetPageFilesQuery> {
      return withWrapper(() =>
        client.request<GetPageFilesQuery>(print(GetPageFilesDocument), variables, requestHeaders)
      )
    },
    getPageFileBySlug(
      variables: GetPageFileBySlugQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetPageFileBySlugQuery> {
      return withWrapper(() =>
        client.request<GetPageFileBySlugQuery>(
          print(GetPageFileBySlugDocument),
          variables,
          requestHeaders
        )
      )
    },
    getSingleDigitalLegacy(
      variables?: GetSingleDigitalLegacyQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetSingleDigitalLegacyQuery> {
      return withWrapper(() =>
        client.request<GetSingleDigitalLegacyQuery>(
          print(GetSingleDigitalLegacyDocument),
          variables,
          requestHeaders
        )
      )
    },
    getTodoCollection(
      variables?: GetTodoCollectionQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetTodoCollectionQuery> {
      return withWrapper(() =>
        client.request<GetTodoCollectionQuery>(
          print(GetTodoCollectionDocument),
          variables,
          requestHeaders
        )
      )
    },
    getDefaultSingingPortraits(
      variables?: GetDefaultSingingPortraitsQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetDefaultSingingPortraitsQuery> {
      return withWrapper(() =>
        client.request<GetDefaultSingingPortraitsQuery>(
          print(GetDefaultSingingPortraitsDocument),
          variables,
          requestHeaders
        )
      )
    },
    getDefaultSingingPortraitSongs(
      variables?: GetDefaultSingingPortraitSongsQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetDefaultSingingPortraitSongsQuery> {
      return withWrapper(() =>
        client.request<GetDefaultSingingPortraitSongsQuery>(
          print(GetDefaultSingingPortraitSongsDocument),
          variables,
          requestHeaders
        )
      )
    },
    getDefaultLivePortraits(
      variables?: GetDefaultLivePortraitsQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetDefaultLivePortraitsQuery> {
      return withWrapper(() =>
        client.request<GetDefaultLivePortraitsQuery>(
          print(GetDefaultLivePortraitsDocument),
          variables,
          requestHeaders
        )
      )
    },
    getBothSingingAndLivePortraits(
      variables?: GetBothSingingAndLivePortraitsQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetBothSingingAndLivePortraitsQuery> {
      return withWrapper(() =>
        client.request<GetBothSingingAndLivePortraitsQuery>(
          print(GetBothSingingAndLivePortraitsDocument),
          variables,
          requestHeaders
        )
      )
    },
    getPartnerLanding(
      variables: GetPartnerLandingQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetPartnerLandingQuery> {
      return withWrapper(() =>
        client.request<GetPartnerLandingQuery>(
          print(GetPartnerLandingDocument),
          variables,
          requestHeaders
        )
      )
    },
    getFutureMessageSetup(
      variables?: GetFutureMessageSetupQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetFutureMessageSetupQuery> {
      return withWrapper(() =>
        client.request<GetFutureMessageSetupQuery>(
          print(GetFutureMessageSetupDocument),
          variables,
          requestHeaders
        )
      )
    },
    getSingleMediaMention(
      variables?: GetSingleMediaMentionQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetSingleMediaMentionQuery> {
      return withWrapper(() =>
        client.request<GetSingleMediaMentionQuery>(
          print(GetSingleMediaMentionDocument),
          variables,
          requestHeaders
        )
      )
    },
    getAboutUsPage(
      variables?: GetAboutUsPageQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetAboutUsPageQuery> {
      return withWrapper(() =>
        client.request<GetAboutUsPageQuery>(
          print(GetAboutUsPageDocument),
          variables,
          requestHeaders
        )
      )
    },
    getHomepageSetup(
      variables?: GetHomepageSetupQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetHomepageSetupQuery> {
      return withWrapper(() =>
        client.request<GetHomepageSetupQuery>(
          print(GetHomepageSetupDocument),
          variables,
          requestHeaders
        )
      )
    },
    getDocumentBySlug(
      variables: GetDocumentBySlugQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetDocumentBySlugQuery> {
      return withWrapper(() =>
        client.request<GetDocumentBySlugQuery>(
          print(GetDocumentBySlugDocument),
          variables,
          requestHeaders
        )
      )
    },
    getDocumentSlugs(
      variables?: GetDocumentSlugsQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetDocumentSlugsQuery> {
      return withWrapper(() =>
        client.request<GetDocumentSlugsQuery>(
          print(GetDocumentSlugsDocument),
          variables,
          requestHeaders
        )
      )
    },
    getFaqBySlug(
      variables: GetFaqBySlugQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetFaqBySlugQuery> {
      return withWrapper(() =>
        client.request<GetFaqBySlugQuery>(print(GetFaqBySlugDocument), variables, requestHeaders)
      )
    },
    getFaqSlugs(
      variables?: GetFaqSlugsQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetFaqSlugsQuery> {
      return withWrapper(() =>
        client.request<GetFaqSlugsQuery>(print(GetFaqSlugsDocument), variables, requestHeaders)
      )
    },
    getHelpCarousel(
      variables?: GetHelpCarouselQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetHelpCarouselQuery> {
      return withWrapper(() =>
        client.request<GetHelpCarouselQuery>(
          print(GetHelpCarouselDocument),
          variables,
          requestHeaders
        )
      )
    },
    getStoryPortraitCategories(
      variables?: GetStoryPortraitCategoriesQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetStoryPortraitCategoriesQuery> {
      return withWrapper(() =>
        client.request<GetStoryPortraitCategoriesQuery>(
          print(GetStoryPortraitCategoriesDocument),
          variables,
          requestHeaders
        )
      )
    },
    getStoryPortraitPhrases(
      variables?: GetStoryPortraitPhrasesQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetStoryPortraitPhrasesQuery> {
      return withWrapper(() =>
        client.request<GetStoryPortraitPhrasesQuery>(
          print(GetStoryPortraitPhrasesDocument),
          variables,
          requestHeaders
        )
      )
    },
    getStoryPortraitCarousel(
      variables?: GetStoryPortraitCarouselQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetStoryPortraitCarouselQuery> {
      return withWrapper(() =>
        client.request<GetStoryPortraitCarouselQuery>(
          print(GetStoryPortraitCarouselDocument),
          variables,
          requestHeaders
        )
      )
    },
    getStoryPortraitTips(
      variables?: GetStoryPortraitTipsQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetStoryPortraitTipsQuery> {
      return withWrapper(() =>
        client.request<GetStoryPortraitTipsQuery>(
          print(GetStoryPortraitTipsDocument),
          variables,
          requestHeaders
        )
      )
    },
    getPageVideos(
      variables?: GetPageVideosQueryVariables,
      requestHeaders?: Headers
    ): Promise<GetPageVideosQuery> {
      return withWrapper(() =>
        client.request<GetPageVideosQuery>(print(GetPageVideosDocument), variables, requestHeaders)
      )
    },
  }
}
export type Sdk = ReturnType<typeof getSdk>
