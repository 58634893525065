import { getSdk, Sdk } from 'api/contentful/contentful.graphql.types'
import { createClient } from 'contentful'
import { GraphQLClient } from 'graphql-request'

const SPACE_ID = process?.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID as string
const CDA_TOKEN = process?.env.NEXT_PUBLIC_CONTENTFUL_CDA_TOKEN as string
const ENVIRONMENT = process?.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT as string

export const CONTENTFUL_API_URL = `https://graphql.contentful.com/content/v1/spaces/${SPACE_ID}/environments/${ENVIRONMENT}?access_token=${CDA_TOKEN}`

export const contentfulClient = createClient({
  space: SPACE_ID,
  accessToken: CDA_TOKEN,
  environment: ENVIRONMENT,
  removeUnresolved: true,
})

const client = new GraphQLClient(CONTENTFUL_API_URL)
export const contentful = getSdk(client)
export type ContentfulSdk = Sdk
export type Contentful<K extends keyof Sdk> = ReturnType<Sdk[K]>
